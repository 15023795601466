import React, { useState, useEffect } from "react";
import logo from './admin-logo.png';
import './Admin.css';
import AdminHeader from "./admins/adminheader";
import { Route, Routes } from "react-router-dom";
import Projects from "./admins/projects";
import Generals from "./admins/generals";
import Clients from "./admins/clients";
import Certificates from "./admins/awards";
import CVS from "./admins/cvs";
import { auth } from "./firebase/firebase";
import Login from "./admins/login";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import CallRequests from "./admins/callrequests";
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import UploadProjects from "./admins/uploadProjects";

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress color="inherit" sx={{width: '200px !important'}} variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 150,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          sx={{ color: 'white', fontSize: '40pt' }}
        >
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};

export default function Admin() {
    const [currentUser, setCurrentUser] = useState(null);
    const [isLogged, setIsLogged] = useState(localStorage.isLogged === 'true' ? true : false)
    const [open, setOpen] = useState(true);
    const [getProgress, setGetProgress] = useState(null)
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                setCurrentUser(user);
            } else {
                setCurrentUser(null);
            }
            setOpen(false)
        });
        return unsubscribe;
    }, []);
    return (
        <div>
            
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                {getProgress ? <CircularProgressWithLabel value={getProgress} /> : <CircularProgress color="inherit" />}
            </Backdrop>
            {!isLogged ? <Login setOpen={setOpen} setIsLogged={setIsLogged} /> : null}
            {isLogged ?
                <div className="admin-container">
                    <div className="logo" style={{ backgroundImage: `url(${logo})` }}></div>
                    <AdminHeader setIsLogged={setIsLogged} />
                    <div className="admin-body">
                        <Routes>
                            <Route path="products/*" element={<Projects setOpen={setOpen} />} />
                            <Route path="certificates/*" element={<Certificates setOpen={setOpen} />} />
                            <Route path="/*" element={<Generals setOpen={setOpen} setGetProgress={setGetProgress} />} />
                            <Route path="clients/*" element={<Clients setOpen={setOpen} />} />
                            <Route path="call-requests/*" element={<CallRequests setOpen={setOpen} />} />
                            <Route path="job-requests/*" element={<CVS setOpen={setOpen} />} />
                            <Route path="upload-projects" element={<UploadProjects setOpen={setOpen} />} />
                        </Routes>
                    </div>
                </div> :
                null}
        </div>
    )
}