import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import bg from '../../../logo.png'

const logoStyle = {
  width: '150px',
  height: 'auto',
  margin: 'auto',
  marginTop: '20px',
  display: 'block',
};

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" mt={1}>
      {'Copyright © '}
      <Link href="https://egybuild.ae/" sx={{ color: '#F26522' }}>Egybuild Construction L.L.C&nbsp;</Link>
      {new Date().getFullYear()}
    </Typography>
  );
}

export default function Footer() {

  return (
    <div className='footer-main' style={{ backgroundColor: 'white', width: '100%', height: '80px', backgroundColor: '#DFDFDF' }}>
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 2, sm: 2 },
          py: { xs: 0, sm: 0 },
          textAlign: { sm: 'center', md: 'left' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 4,
              minWidth: { xs: '100%', sm: '60%' },
            }}
          >
            <Box sx={{ width: { xs: '100%', sm: '60%' } }}>
              <Box sx={{ width: '100%' }}>
                <img
                  src={bg}
                  style={logoStyle}
                  alt="Egybuild Construction L.L.C"
                />
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              gap: 1,
              width: '350px',
              justifyContent: 'space-around',
              padding: '10px 0px 10px 0px',
            }}
          >
            <Typography variant="body2" fontWeight={600}>
              Company
            </Typography>
            <Link color="text.secondary" href="/contact">
              Contact
            </Link>
            <Link color="text.secondary" href="/careers">
              Careers
            </Link>
            <div style={{ margin: 'auto' }} alignItems='center'>
              <Copyright />
            </div>
          </Box>
        </Box>
      </Container>
    </div>
  );
}
