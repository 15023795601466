import React, { useState } from "react";
import firebase from "firebase/compat/app";
import { database, storage } from "../firebase/firebase";

export default function UploadProjects() {

    const uploadData = ()=>{
        data.map((d)=>{
            database.ref('projects').push(d).then(() => {
                console.log(d)
            }).catch((error) => {
                console.error("Error adding project:", error);
            });
            // console.log(d)
        })
    }

    const deleteAll = ()=>{
        database.ref('projects').remove()
    }


    const [selectedImages, setSelectedImages] = useState(null);
    const [progress, setProgress] = useState([]);
    const [imageUrls, setImageUrls] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [number, setNumber] = useState(0)

    // Handle file selection
    const handleFileChange = (e) => {
        setSelectedImages(e.target.files);
    };

    // Upload images to Firebase Storage
    const handleUpload = async () => {
        if (!selectedImages) {
            setError("Please select images to upload");
            return;
        }

        setLoading(true);
        setError("");
        setProgress([]);
        setImageUrls([]);

        const totalFiles = selectedImages.length;
        const uploadedUrls = [];
        const progressArray = [];

        try {
            for (let i = 0; i < totalFiles; i++) {
                const file = selectedImages[i];
                const fileName = file.name;
                const uploadTask = storage.ref(`images/${fileName}`).put(file);

                // Monitor the upload progress
                uploadTask.on(
                    'state_changed',
                    (snapshot) => {
                        const percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        progressArray[i] = Math.round(percent);
                        setProgress([...progressArray]);
                    },
                    (err) => {
                        console.error("Error uploading file:", err);
                        setError("Error uploading file: " + err.message);
                    },
                    async () => {
                        const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
                        uploadedUrls.push(downloadURL);
                        console.log(downloadURL)
                        setNumber(uploadedUrls.length)
                        // Update the state when all uploads are complete
                        if (uploadedUrls.length === totalFiles) {
                            setImageUrls(uploadedUrls);
                            setLoading(false);
                        }
                    }
                );
            }
        } catch (err) {
            setLoading(false);
            setError("Error uploading images: " + err.message);
        }
    };

    return (
        <div>
            <h1>{number}</h1>
            <button onClick={uploadData}>Upload Data</button>
            {/* <button onClick={deleteAll}>Delete Data</button> */}
            <h2>Upload Multiple Images</h2>

            {error && <p style={{ color: 'red' }}>{error}</p>}

            {/* File input */}
            <input type="file" accept="image/*" multiple onChange={handleFileChange} />

            {/* Upload button */}
            <button onClick={handleUpload} disabled={loading}>
                {loading ? "Uploading..." : "Upload Images"}
            </button>

            {/* Progress */}
            {progress.length > 0 && (
                <div style={{
                    overflow: 'scroll',
                    height: '500px',
                    padding: '30px'
                }}>
                    {progress.map((percent, index) => (
                        <div key={index}>
                            <p>Uploading Image {index + 1}: {percent}%</p>
                            <progress value={percent} max="100"></progress>
                        </div>
                    ))}
                </div>
            )}

            {/* Display uploaded image URLs */}
            {imageUrls.length > 0 && (
                <div>
                    <h3>Uploaded Images</h3>
                    {imageUrls.map((url, index) => (
                        <div key={index}>
                            <img src={url} alt={`Uploaded ${index + 1}`} width="200" />
                            <p>Image {index + 1}: {url}</p>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

const data = []