import React, { useState, useEffect } from 'react';
import { Box, CircularProgress } from '@mui/material';
import './contact.css'
import GoogleMap from './inner-components/Locator'
import { database } from '../../firebase/firebase';
import { Backdrop } from '@mui/material';

export default function Contact() {
  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])
  const [tel, setTel] = useState(null);
  const [fax, setFax] = useState(null);
  const [pobox, setPobox] = useState(null);
  const [email, setEmail] = useState(null);
  const [address, setAddress] = useState(null);
  useEffect(() => {
    database.ref('generals/contactDetails').once('value').then((snapshot) => {
      if (snapshot.val()) {
        const gotKey = Object.keys(snapshot.val())[0]
        setTel(snapshot.val()[gotKey].tel)
        setFax(snapshot.val()[gotKey].fax)
        setPobox(snapshot.val()[gotKey].pobox)
        setEmail(snapshot.val()[gotKey].email)
        setAddress(snapshot.val()[gotKey].address)
      }
    })
  }, [])

  const [isSent, setIsSent] = useState(null)
  const [bdOpen, setBDOpen] = useState(false)
  const [personName, setPersonName] = useState(null)
  const [personEmail, setPersonEmail] = useState(null)
  const [personPhone, setPersonPhone] = useState(null)
  const [personMessage, setPersonMessage] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)

  const handleAdd = (e) => {
    e.preventDefault()
    const form = e.target.form
    if (!personName || !personEmail || !personPhone || !personMessage) {
      setErrorMessage('Please fill the all fields.')
      setTimeout(() => { setErrorMessage(null) }, 3000)
    } else {
      setBDOpen(true)
      database.ref('generals/contactmessages').push({
        personName,
        personEmail,
        personPhone,
        personMessage
      }).then(() => {
        setIsSent('Your message has been sent successfully. We will get back to you soon.')
        form.reset()
        setTimeout(() => {
          setBDOpen(false)
          setIsSent(null)
        }, 5000)
      }).catch((err) => {
        setErrorMessage('Sorry! Something went wrong while sending the message. Please try again.')
        setTimeout(() => { setErrorMessage(null) }, 3000)
        setBDOpen(false)
      })
    }
  }

  return (
    <Box sx={{ bgcolor: 'transparent', mt: 8, pt: 2, pb: 10 }}>
      {!errorMessage ? null : <div className='message-error'>
        <p style={{fontSize: window.innerWidth < 768 ? '12pt' : '20pt', width: window.innerWidth < 768 ? `${window.innerWidth}px` : '100%', textWrap: 'wrap'}}>{errorMessage}</p>
      </div>}
      <Backdrop sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={bdOpen}>
        {!isSent ? <CircularProgress color='inherit' /> : <div className='message-sent'>
          <p style={{fontSize: window.innerWidth < 768 ? '12pt' : '20pt', width: window.innerWidth < 768 ? `calc(${window.innerWidth}px - 20px)` : '100%', textWrap: 'wrap', padding: '0px 20px'}}>{isSent}</p>
        </div>}
      </Backdrop>

      <h2 style={{ width: '100%', fontSize: (window.innerWidth < 768 ? '25pt' : '30pt'), textAlign: 'center', color: '#093F88' }}>Contact Us</h2>
      <div className='contact-container' style={{ backgroundColor: 'white' }}>

        <form className='contact-inputs'>
          <h4 style={{ fontSize: '16pt', padding: '10px 15px' }}>REQUEST A CALL</h4>
          <input type='text' id="name" placeholder='Enter your Full Name' style={{ display: 'block', margin: '10px', width: '100%', fontSize: '14pt', padding: '15px 15px', outline: 'none', border: '1px solid #093F88', borderRadius: '4px', color: '#093F88' }} onChange={(e) => { setPersonName(e.target.value) }} required />

          <input type='email' id="email" placeholder='Enter your Email Address' style={{ display: 'block', margin: '10px', width: '100%', fontSize: '14pt', padding: '15px 15px', outline: 'none', border: '1px solid #093F88', borderRadius: '4px', color: '#093F88' }} onChange={(e) => { setPersonEmail(e.target.value) }} required />

          <input type='phone' id="phone" placeholder='Enter your Phone Number' style={{ display: 'block', margin: '10px', width: '100%', fontSize: '14pt', padding: '15px 15px', outline: 'none', border: '1px solid #093F88', borderRadius: '4px', color: '#093F88' }} onChange={(e) => { setPersonPhone(e.target.value) }} required />

          <textarea id="message" placeholder='Enter your Message' style={{ display: 'block', margin: '10px', width: '100%', fontSize: '14pt', padding: '15px 15px', outline: 'none', border: '1px solid #093F88', borderRadius: '4px', color: '#093F88' }} rows={8} required onChange={(e) => { setPersonMessage(e.target.value) }}></textarea>

          <button className='send-button' style={{ display: 'block', fontSize: '16pt', margin: 'auto', padding: '12px 20px', cursor: 'pointer', borderRadius: '6px' }} onClick={(e) => { handleAdd(e) }}>Send Now</button>

        </form>

        <div className='contact-address'>
          <h2 style={{ marginBottom: '25px' }}>Address and Contact Details</h2>
          <div>
            <p style={{ marginBottom: '15px' }}>
              <b>Tel: </b>{tel}
            </p>
            <p style={{ marginBottom: '15px' }}>
              <b>Fax: </b>{fax}
            </p>
            <p style={{ marginBottom: '15px' }}>
              <b>P.O.Box: </b>{pobox}
            </p>
            <p style={{ marginBottom: '15px' }}>
              <b>Email: </b>{email}
            </p>
            <p style={{ marginBottom: '15px' }}>
              <b>Address: </b>{address}
            </p>
          </div>
          <div style={{
            width: window.innerWidth < 768 ? '100%' : '90%',
            borderRadius: '10px',
            overflow: 'hidden',
            boxShadow: '0px 0px 20px -10px black'
          }}>
            <p>
              <iframe style={{ border: '0px' }} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3608.9848973973862!2d55.32530841442128!3d25.262256783866015!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5cdad2811a61%3A0xb2bd0caf4007f697!2sEGYBUILD!5e0!3m2!1sen!2sus!4v1551931309438" width="100%" height="270" frameborder="0" allowfullscreen="allowfullscreen"></iframe>
            </p>
          </div>
          {/* <GoogleMap /> */}
        </div>
      </div>

    </Box>
  );
}
