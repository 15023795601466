import React, {useState} from "react";
import { Backdrop } from "@mui/material";

export default function Cert({ title, remarks, imageUrl }) {
    const [open, setOpen] = useState(false);
    return (
        <>
            <Backdrop open={open} onClick={()=>setOpen(false)} sx={{zIndex: 1000, height: 'calc(100vh - 80px)', top: '80px'}}>
                <img style={{
                    maxWidth: 'auto',
                    height: '90vh'
                }} src={imageUrl} alt="" />
            </Backdrop>
            <div className="ac-item" onClick={()=>setOpen(true)}>
                <h1>{title}</h1>
                <p>{remarks}</p>
                <img src={imageUrl} alt="" />
            </div>
        </>
    )
}