import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

export default function Counter({ start, end, txt, ct }){
    const [count, setCount] = useState(start);
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.5  // Triggers when 50% of the element is in view
    });

    useEffect(() => {
        if (inView) {
            const timer = setInterval(() => {
                setCount((prevCount) => {
                    if (prevCount < end) {
                        return prevCount + 1; // Adjust this to control speed/counting steps
                    } else {
                        clearInterval(timer);
                        return prevCount;
                    }
                });
            }, ct); // Speed of counter
        }
    }, [inView, end]);

    return (
        <div ref={ref}>
            <h1>{count} <span>{txt}</span></h1>
        </div>
    );
}