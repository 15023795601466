import * as React from 'react';
import { database, storage } from '../../firebase/firebase';

export default function Careers() {
  const [formData, setFormData] = React.useState({
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    fileName: '',
    file: null
  });
  const [isSubmitted, setIsSubmitted] = React.useState(localStorage.getItem('isSubmitted') === 'true');

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'cv') {
      setFormData({
        ...formData,
        fileName: files[0].name,
        file: files[0]
      });
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };

  const uploadFileAndGetURL = (file) => {
    const uploadTask = storage.ref(`resumes/${file.name}`).put(file);
    return new Promise((resolve, reject) => {
      uploadTask.on('state_changed',
        (snapshot) => {
          // Handle file upload progress here if you want
        },
        (error) => {
          reject(error);
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            resolve(downloadURL);
          });
        }
      );
    });
  };

  const submitForm = async (e) => {
    e.preventDefault();
    if (formData.firstname && formData.lastname && formData.email && formData.phone && formData.file) {
      try {
        const fileURL = await uploadFileAndGetURL(formData.file);
        await database.ref('generals/cvs').push({
          ...formData,
          file: fileURL,
        });
        setIsSubmitted(true);
        localStorage.setItem('isSubmitted', 'true');
      } catch (error) {
        window.alert('Error: ' + error.message);
      }
    } else {
      window.alert('All fields are required.');
    }
  };

  return (
    <div style={{
      position: 'relative',
      minHeight: window.innerHeight - 80
    }}>
      <div style={{
        position: 'absolute',
        top: '55%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        maxWidth: '1250px',
        height: '500px',
        backgroundColor: 'rgba(18, 86, 164, .1)',
        borderRadius: '20px',
        boxShadow: '0px 0px 10px -5px black'
      }}>
        {isSubmitted ?
          <div style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center'
          }}>
            <h1 style={{
              flex: 1,
              textAlign: 'center',
              color: 'darkgreen',
              userSelect: 'none',
              fontSize: '30pt'
            }}>Your application has been submitted successfully.</h1>
          </div> :
          <>
            <h3 style={{
              textAlign: 'center',
              margin: '50px',
              fontSize: '25pt',
              userSelect: 'none'
            }}>Join Our Team</h3>
            <form onSubmit={submitForm} style={{
              display: 'flex',
              flexDirection: 'column',
              margin: '50px auto',
              gap: '20px',
              maxWidth: '900px'
            }}>
              <div style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '50px'
              }}>
                {['firstname', 'lastname'].map(field => (
                  <input key={field} name={field} style={{
                    flex: 1,
                    outline: 'none',
                    border: '2px solid gray',
                    borderRadius: '8px',
                    padding: '10px 20px',
                    fontSize: '14pt'
                  }} type={field === 'email' ? 'email' : field === 'phone' ? 'tel' : 'text'}
                    placeholder={field.charAt(0).toUpperCase() + field.slice(1)}
                    value={formData[field]}
                    onChange={handleChange}
                  />
                ))}
              </div>
              <div style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '50px'
              }}>
                {['email', 'phone'].map(field => (
                  <input key={field} name={field} style={{
                    flex: 1,
                    outline: 'none',
                    border: '2px solid gray',
                    borderRadius: '8px',
                    padding: '10px 20px',
                    fontSize: '14pt'
                  }} type={field === 'email' ? 'email' : field === 'phone' ? 'tel' : 'text'}
                    placeholder={field.charAt(0).toUpperCase() + field.slice(1)}
                    value={formData[field]}
                    onChange={handleChange}
                  />
                ))}
              </div>
              <label style={{
                outline: 'none',
                border: '2px solid gray',
                borderRadius: '8px',
                padding: '10px 20px',
                fontSize: '14pt',
                cursor: 'pointer',
                backgroundColor: '#fff'
              }} htmlFor="cv"><b>Upload your Resume:</b> {formData.fileName || 'No file selected'}</label>
              <input style={{ display: 'none' }} accept="application/pdf"
                onChange={handleChange} id='cv' type="file" name="cv" />
              <input style={{
                width: 'fit-content',
                outline: 'none',
                border: 'none',
                cursor: 'pointer',
                boxShadow: '0px 0px 5px -3px black',
                borderRadius: '8px',
                padding: '10px 20px',
                fontSize: '14pt',
                color: '#fff',
                backgroundColor: 'rgb(18, 86, 164)',
                marginLeft: 'auto'
              }} type="submit" value='Submit' />
            </form>
          </>
        }
      </div>
    </div>
  );
}
