import React, { useEffect, useState } from "react";
import { NavLink, Route, Routes } from "react-router-dom";
import { database, storage } from "../firebase/firebase";
import CancelIcon from '@mui/icons-material/Cancel';
import firebase from "firebase/compat/app";

export default function Clients({ setOpen }) {

    return (
        <div className="admin-client">
            <ul style={{ width: 'max-content', borderRadius: '50px', margin: 'auto', overflow: 'hidden', boxShadow: '0px 0px 6px 3px darkblue inset' }}>
                <li style={{ display: 'inline-block' }}><NavLink to='' end style={{
                    display: 'block',
                    textAlign: 'center',
                    textDecoration: 'none',
                    padding: '10px 20px'
                }}>Add Section</NavLink></li>
                <li style={{ display: 'inline-block' }}><NavLink to='edit-section' style={{
                    display: 'block',
                    textAlign: 'center',
                    textDecoration: 'none',
                    padding: '10px 20px'
                }}>Edit Section</NavLink></li>
                <li style={{ display: 'inline-block' }}><NavLink to='add-item' style={{
                    display: 'block',
                    textDecoration: 'none',
                    padding: '10px 20px',
                    textAlign: 'center',
                }}>Add Item</NavLink></li>
                <li style={{ display: 'inline-block' }}><NavLink to='edit-item' style={{
                    display: 'block',
                    textDecoration: 'none',
                    padding: '10px 20px',
                    textAlign: 'center',
                }}>Edit Item</NavLink></li>
            </ul>
            <div className="hideScroll" style={{ margin: '20px auto', width: '80%', height: 'calc(100vh - 250px)', overflow: 'auto', padding: '20px' }}>
                <Routes>
                    <Route path="/" exact element={<AddSection setOpen={setOpen} />} />
                    <Route path="edit-section" exact element={<EditSection setOpen={setOpen} />} />
                    <Route path="add-item" exact element={<AddSectionItem setOpen={setOpen} />} />
                    <Route path="edit-item" exact element={<EditSectionItem setOpen={setOpen} />} />
                </Routes>
            </div>
        </div>
    );
}


function AddSection({ setOpen }) {
    const [errorMessage, setErrorMessage] = useState('');

    const handleAdd = (e) => {
        e.preventDefault();
        const form = e.target.form;
        const sectionName = form.elements['section-name'].value;

        if (!sectionName) {
            setErrorMessage('Please fill the section name.');
            setTimeout(() => { setErrorMessage('') }, 2000)
            return;
        }
        setOpen(true)
        database.ref('clientssections').orderByChild('name').equalTo(sectionName.toLowerCase()).once('value', snapshot => {
            if (snapshot.exists()) {
                alert('This Section exists before. Kindly check the section name again')
                setOpen(false)
            } else {
                const sectionsData = {
                    name: sectionName.toLowerCase(),
                    title: sectionName
                }
                database.ref('clientssections').push(sectionsData).then(() => {
                    alert('The Section has been added successfully')
                    form.reset();
                    setOpen(false);
                }).catch((error) => {
                    console.error("Error adding project:", error);
                });
            }
        })
    }

    return (
        <div >
            {errorMessage !== '' ? <p style={{ marginTop: '70px' }} className="errorMessage">{errorMessage} <CancelIcon sx={{ cursor: 'pointer' }} onClick={() => setErrorMessage('')} /></p> : null}
            <form onSubmit={(e) => handleAdd(e)} style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '30px',
                justifyContent: 'space-evenly',
                marginTop: '100px'
            }} >
                <input style={{
                    display: 'block',
                    fontSize: '16pt',
                    border: '1px solid darkblue',
                    outline: 'none',
                    padding: '10px 20px',
                    borderRadius: '10px',
                    minWidth: '800px',
                    margin: 'auto',
                    textAlign: 'center'
                }} type="text" name="section-name" placeholder="Section Name" required className="section-name" />
                <input style={{
                    display: 'block',
                    fontSize: '16pt',
                    border: '1px solid darkblue',
                    outline: 'none',
                    padding: '10px 20px',
                    borderRadius: '10px',
                    width: '200px',
                    cursor: 'pointer',
                    margin: 'auto'
                }} type="submit" onClick={(e) => handleAdd(e)} value="Add Section" className="add-section-button" />
            </form>
        </div>
    );
}

function EditSection({ setOpen }) {
    const [section, setSection] = useState('');
    const [sectionTxt, setSectionTxt] = useState(null);
    const [searchTxt, setSearchTxt] = useState(null);
    const [sectionKey, setSectionKey] = useState(null);

    const handleSearch = () => {
        if (searchTxt) {
            setSection(null)
            setOpen(true)
            database.ref('clientssections').orderByChild('name').equalTo(searchTxt.toLowerCase()).once('value', snapshot => {
                if (snapshot.exists()) {
                    const data = snapshot.val()
                    const key = Object.keys(data)[0]
                    setSection(data[key])
                    setSectionKey(key)
                    setOpen(false)
                } else {
                    alert('This Section Name Not Found')
                    setOpen(false)
                }
            })
        }
    }

    const handleEdit = () => {
        if (sectionTxt) {
            setOpen(true)
            database.ref(`clientssections/${sectionKey}`).set({
                name: sectionTxt.toLowerCase(),
                title: sectionTxt
            }).then(() => {
                alert('The Section Updated.')
                setOpen(false)
            }).catch((err) => {
                alert(`Error: ${err}`)
                setOpen(false)
            })
        }
    }

    const handleDelete = () => {
        const uResp = window.confirm('Are you sure you want to delete the section?')
        if (uResp) {
            database.ref(`clientssections/${sectionKey}`).remove().then(() => {
                alert('The section deleted.')
                setSection(null)
            }).catch((err) => {
                alert(`Error: ${err}`)
            })
        }
    }

    return (
        <div>
            <div style={{ width: '800px', margin: 'auto', position: 'relative', top: '50px' }}>
                <input type="text" placeholder="Search for section" style={{
                    position: 'absolute',
                    left: '0px',
                    width: '600px',
                    fontSize: '16pt',
                    outline: 'none',
                    padding: '5px 20px',
                    border: '1px solid darkblue',
                    borderRadius: '50px',
                    textAlign: 'center'
                }} onChange={(e) => { setSearchTxt(e.target.value) }} />
                <button style={{
                    width: '150px',
                    border: '1px solid darkblue',
                    fontSize: '16pt',
                    padding: '5px 20px',
                    position: 'absolute',
                    right: '0px',
                    borderRadius: '50px',
                    backgroundColor: 'white',
                    cursor: 'pointer',
                    color: 'darkblue'
                }} onClick={handleSearch}>Find</button>
            </div>
            {!section ? null :
                <div style={{
                    position: 'relative',
                    top: '150px',
                    width: '1000px',
                    margin: 'auto'
                }}>
                    <input defaultValue={section.title} type="text" style={{
                        position: 'absolute',
                        left: '0px',
                        width: '500px',
                        fontSize: '16pt',
                        outline: 'none',
                        padding: '5px 20px',
                        border: '1px solid darkblue',
                        borderRadius: '50px',
                        textAlign: 'center'
                    }} onChange={(e) => setSectionTxt(e.target.value)} />
                    <button style={{
                        width: '200px',
                        border: '1px solid darkblue',
                        fontSize: '16pt',
                        padding: '5px 20px',
                        position: 'absolute',
                        right: '230px',
                        borderRadius: '50px',
                        backgroundColor: 'white',
                        cursor: 'pointer',
                        color: 'darkblue'
                    }} onClick={handleEdit}>Submit Changes</button>
                    <button className="delete-section" style={{
                        width: '200px',
                        border: '1px solid darkblue',
                        fontSize: '16pt',
                        padding: '5px 20px',
                        position: 'absolute',
                        right: '0px',
                        borderRadius: '50px',
                        backgroundColor: 'white',
                        cursor: 'pointer',
                        color: 'darkblue'
                    }} onClick={handleDelete}>Delete Section</button>
                </div>
            }
        </div>
    )
}

function AddSectionItem({ setOpen }) {
    const [sections, setSections] = useState(null)
    const [section, setSection] = useState(null)
    const [imagePrev, setImagePrev] = useState(null)
    const [imageUpload, setImageUpload] = useState(null)

    useEffect(() => {
        setOpen(true)
        database.ref('clientssections').once('value', snapshot => {
            setSections(Object.values(snapshot.val()))
            setOpen(false)
        })
    }, [])

    const handleAdd = (e) => {
        e.preventDefault()
        const form = e.target.form;
        if (!section || section === 'Choose Section') {
            alert('Please choose section.')
        } else if (!imageUpload) {
            alert('Please choose an image.')
        } else {
            setOpen(true)
            const fileRef = storage.ref(`clientsimages/${imageUpload.name}`);
            fileRef.put(imageUpload).then(() => {
                fileRef.getDownloadURL().then((url) => {
                    const dataU = {
                        imageUrl: url,
                        section: section
                    }
                    database.ref('clientsitems').push(dataU).then(() => {
                        alert('Item added successfully.')
                        setOpen(false)
                        form.reset()
                        setImagePrev(null)
                    }).catch((err) => {
                        alert('Faild to add the item.')
                        setOpen(false)
                    })
                }).catch((err) => {
                    alert('Faild to upload image.')
                    setOpen(false)
                })
            })
        }
    }

    return (
        <form style={{
            width: '600px',
            margin: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            flexWrap: 'wrap',
            gap: '20px'
        }}>
            <img src={imagePrev} alt="Preview" style={{
                visibility: !imagePrev ? 'hidden' : 'visible',
                width: '250px',
                height: '150px',
                display: 'block',
                margin: 'auto',
                borderRadius: '10px'
            }} />
            <input type="file" onChange={(e) => {
                if (e.target.files[0]) {
                    const imageUrl = URL.createObjectURL(e.target.files[0]);
                    setImagePrev(imageUrl);
                    setImageUpload(e.target.files[0])
                }
            }} accept="image/*" style={{
                fontSize: '16pt',
                padding: '5px 15px',
                borderRadius: '10px',
                outline: 'none',
                border: '1px solid darkblue',
                cursor: 'pointer'
            }} />
            {!sections ? null :
                <select style={{
                    fontSize: '16pt',
                    padding: '5px 15px',
                    borderRadius: '10px',
                    outline: 'none',
                    border: '1px solid darkblue',
                    cursor: 'pointer'
                }} onChange={(e) => setSection(e.target.value)}>
                    <option value={null}>Choose Section</option>
                    {sections.map((s) => {
                        return (
                            <option key={s.name} value={s.name}>{s.title}</option>
                        )
                    })}
                </select>
            }

            <button style={{
                fontSize: '16pt',
                padding: '5px 15px',
                borderRadius: '10px',
                outline: 'none',
                border: '1px solid darkblue',
                cursor: 'pointer',
                transition: '.2s',
                display: 'block',
                width: '150px',
                margin: 'auto',
                backgroundColor: 'white'
            }} onClick={handleAdd}>Add</button>
        </form>
    )
}

function EditSectionItem({ setOpen }) {

    const [sections, setSections] = useState(null)
    const [items, setItems] = useState([])
    const [newImg, setNewImg] = useState({})
    const [newImgV, setNewImgV] = useState({})
    const [newSec, setNewSec] = useState({})

    useEffect(() => {
        setOpen(true)
        database.ref('clientssections').once('value', snapshot => {
            setSections(Object.values(snapshot.val()))
            setOpen(false)
        })
    }, [])

    const startGet = () => {
        setOpen(true)
        database.ref('clientsitems').once('value', snapshot => {
            Object.keys(snapshot.val()).map((ov) => {
                setItems(prev => [...prev, {
                    key: ov,
                    imageUrl: snapshot.val()[ov].imageUrl,
                    section: snapshot.val()[ov].section
                }])
            })
            setOpen(false)
        })
    }

    useEffect(() => {
        startGet()
    }, [])

    const handleEdit = (e, key, section, imageUrl) => {
        e.preventDefault()
        if (newImg[key] && newSec[key]) {
            setOpen(true)
            const oimgRef = storage.refFromURL(imageUrl)
            oimgRef.delete().then(() => {
                const nimgRef = storage.ref(`clientsimages/${newImg[key].name}`)
                nimgRef.put(newImg[key]).then(() => {
                    nimgRef.getDownloadURL().then((url) => {
                        database.ref(`clientsitems/${key}`).set({
                            section: newSec[key],
                            imageUrl: url
                        }).then(() => {
                            alert('The Item Updated.')
                            setNewImg({})
                            setNewImgV({})
                            setNewSec({})
                            setItems([])
                            startGet()
                            setOpen(false)
                        }).catch((err) => {
                            alert(`Error: ${err}`)
                            setOpen(false)
                        })
                    }).catch((err) => {
                        alert(`Error: ${err}`)
                    })
                }).catch((err) => {
                    alert(`Error: ${err}`)
                })
            }).catch((err) => {
                alert(`Error: ${err}`)
            })
        } else if (newImg[key] && !newSec[key]) {
            setOpen(true)
            const oimgRef = storage.refFromURL(imageUrl)
            oimgRef.delete().then(() => {
                const nimgRef = storage.ref(`clientsimages/${newImg[key].name}`)
                nimgRef.put(newImg[key]).then(() => {
                    nimgRef.getDownloadURL().then((url) => {
                        database.ref(`clientsitems/${key}`).set({
                            section: section,
                            imageUrl: url
                        }).then(() => {
                            alert('The Item Updated.')
                            setNewImg({})
                            setNewImgV({})
                            setNewSec({})
                            setItems([])
                            startGet()
                            setOpen(false)
                        }).catch((err) => {
                            alert(`Error: ${err}`)
                            setOpen(false)
                        })
                    }).catch((err) => {
                        alert(`Error: ${err}`)
                    })
                }).catch((err) => {
                    alert(`Error: ${err}`)
                })
            }).catch((err) => {
                alert(`Error: ${err}`)
            })
        } else if (!newImg[key] && newSec[key]) {
            setOpen(true)
            database.ref(`clientsitems/${key}`).set({
                section: newSec[key],
                imageUrl: imageUrl
            }).then(() => {
                alert('The Item Updated.')
                setNewImg({})
                setNewImgV({})
                setNewSec({})
                setItems([])
                startGet()
                setOpen(false)
            }).catch((err) => {
                alert(`Error: ${err}`)
                setOpen(false)
            })
        } else {
            alert('No changes to update')
        }
    }

    const handleDelete = (e, key) => {
        e.preventDefault()
        const uResp = window.confirm('Are you sure you want to delete the item?')
        if (uResp) {
            database.ref(`clientsitems/${key}`).remove().then(() => {
                alert('The Item deleted.')
                setItems([])
                startGet()
            }).catch((err) => {
                alert(`Error: ${err}`)
            })
        }
    }

    return (
        <div style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row-reverse',
            flexWrap: 'wrap',
            gap: '20px',
            justifyContent: 'space-evenly'
        }}>
            {!items ? null :
                items.map((i) => {
                    console.log(newImgV)
                    return (
                        <form key={i.key} style={{
                            display: 'flex',
                            flexDirection: 'column',
                            flexWrap: 'wrap',
                            width: '220px',
                            gap: '5px',
                            alignItems: 'center',
                            backgroundColor: 'skyblue',
                            padding: '10px',
                            borderRadius: '10px'
                        }}>
                            <img src={newImgV[i.key] ? newImgV[i.key] : i.imageUrl} style={{
                                width: '200px',
                                height: '120px'
                            }} alt="" />
                            <input type="file" onChange={(e) => {
                                if (e.target.files[0]) {
                                    const imageUrl = URL.createObjectURL(e.target.files[0]);
                                    setNewImgV((prev) => ({ ...prev, [i.key]: imageUrl }));
                                    setNewImg((prev) => ({ ...prev, [i.key]: e.target.files[0] }))
                                }
                            }} accept="image/*" style={{
                                width: '200px',
                                outline: 'none',
                                padding: '5px 10px',
                                border: '1px solid darkblue',
                                borderRadius: '5px',
                                cursor: 'pointer',
                            }} />
                            {!sections ? null :
                                <select onChange={(e) => {
                                    if (e.target.value === i.section) {
                                        setNewSec({})
                                    } else {
                                        setNewSec((prev) => ({ ...prev, [i.key]: e.target.value }))
                                    }
                                }} defaultValue={i.section} style={{
                                    width: '200px',
                                    outline: 'none',
                                    padding: '5px 10px',
                                    border: '1px solid darkblue',
                                    borderRadius: '5px',
                                    cursor: 'pointer',
                                }}>
                                    {sections.map((s) => {
                                        return (
                                            <option key={s.name} value={s.name}>{s.title}</option>
                                        )
                                    })}
                                </select>
                            }
                            <input onClick={(e) => handleEdit(e, i.key, i.section, i.imageUrl)} type="submit" value="Submit Changes" style={{
                                outline: 'none',
                                border: '1px solid darkblue',
                                backgroundColor: 'white',
                                padding: '5px 10px',
                                borderRadius: '5px',
                                cursor: 'pointer'
                            }} />
                            <input onClick={(e) => handleDelete(e, i.key)} style={{
                                outline: 'none',
                                border: '1px solid darkblue',
                                backgroundColor: 'darkred',
                                color: 'white',
                                padding: '5px 10px',
                                borderRadius: '5px',
                                cursor: 'pointer',
                                fontWeight: 'bold'
                            }} type="submit" value="Delete Item" />
                        </form>
                    )
                })
            }
        </div>
    )
}