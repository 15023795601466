import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import TopProjects from './inner-components/TopProjects';
import OurServices from './inner-components/ourservices';
import Counter from './inner-components/counters';
import ClientSlider from './inner-components/ourclients';
import clnt1 from '../../images/clnt1.jpg';
import clnt2 from '../../images/clnt2.jpg';
import clnt3 from '../../images/clnt3.jpg';
import clnt4 from '../../images/clnt4.jpg';
import clnt5 from '../../images/clnt5.jpg';
import HomeAbout from './inner-components/homeAbout';
import { database } from '../../firebase/firebase';
import ReactPlayer from 'react-player';


export default function Home() {
  React.useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])
  const clientLogos = [
    { logo: clnt1, name: 'NBQ' },
    { logo: clnt2, name: 'NBQ' },
    { logo: clnt3, name: 'NBQ' },
    { logo: clnt4, name: 'NBQ' },
    { logo: clnt5, name: 'NBQ' },
  ];

  const [homeGIF, setHomeGIF] = useState(null);
  const [name1, setName1] = useState(null)
  const [name2, setName2] = useState(null)
  const [name3, setName3] = useState(null)
  const [name4, setName4] = useState(null)
  const [number1, setNumber1] = useState(null)
  const [number2, setNumber2] = useState(null)
  const [number3, setNumber3] = useState(null)
  const [number4, setNumber4] = useState(null)
  const [number, setNumber] = useState(null)
  const [cdata, setCData] = useState([])
  const [clength, setCLength] = useState(null)
  const [videoHeight, setVideoHeight] = useState(null)

  useEffect(() => {
    database.ref('generals/clientssection').once('value', snapshot => {
      if (snapshot.val()) {
        setCData(Object.values(snapshot.val()))
        setCLength(Object.values(snapshot.val()).length)
      }
    })
  }, [])

  useEffect(() => {
    database.ref('generals/visitorscount').once('value').then((snapshot) => {
      if (snapshot.val()) {
        setNumber(snapshot.val()['count'].number)
      }
    }).catch((err) => {
      alert(`Error: ${err}`)
    })
  }, [])

  useEffect(() => {
    database.ref('generals/homeimage').once('value', snapshot => {
      if (snapshot.val()) {
        setHomeGIF(Object.values(snapshot.val())[0].imageUrl)
        setVideoHeight(Object.values(snapshot.val())[0].videoHeight)
      }
    })
  }, [])

  useEffect(() => {
    database.ref('generals/counts').once('value').then((snapshot) => {
      if (snapshot.val()) {
        const gotKey = Object.keys(snapshot.val())[0];
        setName1(snapshot.val()[gotKey].name1)
        setNumber1(snapshot.val()[gotKey].number1)
        setName2(snapshot.val()[gotKey].name2)
        setNumber2(snapshot.val()[gotKey].number2)
        setName3(snapshot.val()[gotKey].name3)
        setNumber3(snapshot.val()[gotKey].number3)
        setName4(snapshot.val()[gotKey].name4)
        setNumber4(snapshot.val()[gotKey].number4)
      }
    }).catch((err) => {
      alert(`Error: ${err}`)
    })
  }, [])

  useEffect(() => {
    document.addEventListener('contextmenu', (e) => {
      if (e.target.nodeName === 'VIDEO') {
        e.preventDefault();
      }
    });
  }, [])


  // ///////////////////////


  const [isBuffering, setIsBuffering] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    // Set a timeout to simulate waiting for the first few seconds of the video
    const bufferTimeout = setTimeout(() => {
      if (isBuffering) {
        setIsPlaying(true); // Start playing after a few seconds even if it's still buffering
      }
    }, 5000); // Delay for 5 seconds to simulate early loading

    return () => clearTimeout(bufferTimeout);
  }, [isBuffering]);

  const handleBuffer = () => {
    setIsBuffering(true); // Video is buffering, show the loading state
  };

  const handlePlay = () => {
    setIsPlaying(true); // Video has started playing, remove loading state
    setIsBuffering(false);
  };

  const handleReady = () => {
    setIsPlaying(true); // Video is ready to play, start it
    setIsBuffering(false);
  };

  return (
    <Box
      className="vidBox"
      sx={{
        pt: { xs: 8, sm: 10 },
        pb: { xs: 8, sm: 8 },
        width: '100%',
        overflow: 'hidden'
      }}
    >
      {/* <img className="gif" src={homeGIF} alt='' /> */}
      {/* <ReactPlayer
        url={homeGIF}
        className='react-player gif'
        playing={true}
        playsinline
        muted={true}
        loop={true}
        height={videoHeight ? `${parseInt(videoHeight)}px` : '650px'}
        config={{
          file: {
            attributes: {
              preload: 'auto', // This will start loading the video immediately
            },
          },
        }}
      /> */}

      <ReactPlayer
        url={homeGIF}
        className="react-player gif"
        playing={isPlaying}
        playsinline
        muted={true}
        loop={true}
        height={videoHeight ? `${parseInt(videoHeight)}px` : '650px'}
        onBuffer={handleBuffer} // Called when the video is buffering
        onPlay={handlePlay} // Called when the video starts playing
        onReady={handleReady} // Called when the video is ready to play
        config={{
          file: {
            attributes: {
              preload: 'auto', // Tell the browser to preload the video
            },
          },
        }}
      />

      <HomeAbout />
      <TopProjects />
      <OurServices />
      <div className="stats-container" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', background: 'transparent', boxShadow: '0px 0px 15px 6px gray inset', padding: '40px' }}>
        <div style={{ marginBottom: (window.innerWidth < 768 ? '50px' : 'none') }}>
          <Counter start={0} end={number1 ? number1 : 17} ct={200} />
          <p style={{ fontSize: (window.innerWidth < 768 ? '16pt' : '20pt') }}>{name1 ? name1 : 'YEARS EXPERIENCE'}</p>
        </div>
        <div style={{ marginBottom: (window.innerWidth < 768 ? '50px' : 'none') }}>
          <Counter start={0} end={number2 ? number2 : 300} ct={10} />
          <p style={{ fontSize: (window.innerWidth < 768 ? '16pt' : '20pt') }}>{name2 ? name2 : 'COMPLETED PROJECTS'}</p>
        </div>
        <div style={{ marginBottom: (window.innerWidth < 768 ? '50px' : 'none') }}>
          <Counter start={0} end={number3 ? number3 : 5} ct={50} txt="+" />
          <p style={{ fontSize: (window.innerWidth < 768 ? '16pt' : '20pt') }}>{name3 ? name3 : 'ONGOING PROJECTS'}</p>
        </div>
        <div style={{ marginBottom: (window.innerWidth < 768 ? '50px' : 'none') }}>
          <Counter start={0} end={number4 ? number4 : 200} ct={20} txt="+" />
          <p style={{ fontSize: (window.innerWidth < 768 ? '16pt' : '20pt') }}>{name4 ? name4 : 'WORK FORCE'}</p>
        </div>
      </div>
      <ClientSlider clients={clientLogos} dbdata={cdata} len={clength} />
      <div style={{ paddingBottom: '0px', marginBottom: '0px' }}>
        <p style={{ textAlign: 'center', fontSize: '20pt', paddingBottom: '0px', marginBottom: '0px' }}>
          Visitors Count <b style={{ backgroundImage: 'linear-gradient(red, orange)', backgroundClip: 'text', color: 'transparent' }}>{number}+</b></p>
      </div>
    </Box>
  );
}
