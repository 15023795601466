import React, {useState, useEffect} from 'react'
import { database } from '../../../firebase/firebase'

export default function HomeAbout() {
    const [topText, setTopText] = useState(null)
    const [blText, setBLText] = useState(null)
    const [brText, setBRText] = useState(null)
    useEffect(()=>{
        database.ref('generals/hometext').once('value', (snapshot) => {
            if(snapshot.val()){
                setTopText(Object.values(snapshot.val())[0].headertxt)
                setBLText(Object.values(snapshot.val())[0].firstp)
                setBRText(Object.values(snapshot.val())[0].secondp)
            }
        })
    },[])
    return (
        <div className='about-container' style={{ borderRadius: '10px', backgroundColor: 'white' }}>
            <section className="mission-section" style={{backgroundImage: 'linear-gradient(to right, #F26522, #093F88, black)',backgroundClip: 'text', color: 'transparent'}}>
                <h2 style={{textAlign: window.innerWidth < 768 ? 'left' : 'justify', color: 'transparent'}}>{topText ? topText : 'Egy Build Construction LLC, a Dubai based company, was established in 2007 by Eng. Ahmed. Since its inception, they have been providing high quality building construction services and fit out works to various clients across the UAE.'}</h2>
                {/* <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between'
                }}>
                    <p style={{
                        display: 'inline-block',
                        maxWidth: window.innerWidth < 768 ? '100%' : '560px',
                        textAlign: 'justify'
                    }}>
                        {blText ? blText : 'our main office is located in Al Rigga - Dubai -UAE, which has qualified engineers and management staff, whom working hard on the most worthwhile programs and management techniques to support our well trained and well experienced workforce along with more than 160 Construction sites all around'}
                    </p>
                    <p style={{
                        display: 'inline-block',
                        maxWidth: window.innerWidth < 768 ? '100%' : '560px',
                        textAlign: 'justify'
                    }}>
                        {brText ? brText : 'Dubai, U.A.E. our completed projects and satisfied clients are a testament to the quality of our output work that we deliver on planned integrated baselines. The fact that our clients are repetitive is evidence for the trust our clients have put on us.'}
                    </p>
                </div> */}
            </section>
        </div>
    )
}