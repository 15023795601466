import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import AppAppBar from './components/inner-components/AppAppBar';
import News from './components/News';
import Careers from './components/Careers';
import Home from './components/Home';
import Projects from './components/Projects';
import About from './components/About';
import Contact from './components/Contact';
import Clients from './components/clients';
import Footer from './components/inner-components/Footer';
import getLPTheme from './getLPTheme';
import { Routes, Route } from 'react-router-dom';

export default function LandingPage() {
  
  const LPtheme = createTheme(getLPTheme('light'));

  return (
    <ThemeProvider theme={LPtheme}>
      <CssBaseline />
      <AppAppBar />
        <Box>
          <Routes>
            <Route path='/' exact element= {<Home />} />
            <Route path='projects/*' exact element= {<Projects />} />
            <Route path='news' exact element= {<News />} />
            <Route path='careers' exact element= {<Careers />} />
            <Route path='about' exact element= {<About />} />
            <Route path='contact' exact element= {<Contact />} />
            {/* <Route path='gallery' exact element= {<Gallery />} /> */}
            <Route path='clients' exact element= {<Clients />} />
          </Routes>
          <Footer />
        </Box>
    </ThemeProvider>
  );
}
