import React, { useState } from "react";
import CancelIcon from '@mui/icons-material/Cancel';

export default function TopProjectsCard({ img, data }) {
    const [showPopup, setShowPopup] = useState(false);
    const togglePopup = () => setShowPopup(!showPopup);

    return (
        <>
            <div style={{width:'100%', height:'100vh', position:'fixed', background:'rgba(0,0,0,.8)', left:'0px', top:'0px', display:(showPopup ? 'block' : 'none'), zIndex:10000}} onClick={()=>{setShowPopup(false)}}></div>
            <div className={`top-projects-popup ${showPopup ? 'active' : ''}`}>
                <CancelIcon sx={{width:'20px', color:'red', marginLeft:'calc(100% - 30px)', cursor:'pointer'}} onClick={()=>{setShowPopup(false)}} />
                <div style={{ width: '100%', height: '100%'}}>
                    <img src={img} style={{ width: '100%', height: "calc(100% - 180px)" }} alt="Card" />
                    <h4 style={{margin:'3px 10px'}}><b>Project Details</b></h4>
                    <p style={{margin:'3px 10px'}}><b>Code: </b>{data.code}</p>
                    <p style={{margin:'3px 10px'}}><b>Name: </b>{data.name}</p>
                    <p style={{margin:'3px 10px'}}><b>Location: </b>{data.location}</p>
                    <p style={{margin:'3px 10px'}}><b>Consultant: </b>{data.consultant}</p>
                </div>
            </div>
            <div style={{ position: 'relative', margin:'10px', boxShadow:'0px 0px 50px -25px black', width: window.innerWidth < 791 ? '95%' : '560px', height: '300px', backgroundColor: 'white', padding: '20px' }}
                 onClick={togglePopup}>
                <div className="top-projects-card" style={{ width: 'calc(100% - 40px)', height: 'calc(100% - 40px)', overflow: 'hidden' }}>
                    <img src={img} style={{ width: '100%', height: "100%" }} alt="Card" />
                    <p>{data.name}</p>
                </div>
            </div>
        </>
    );
}