import React, {useEffect, useState} from 'react';
import TopProjectsCard from './topProjectsCard';
import img1 from '../../../AI-Images/img5.jpg';
import img2 from '../../../AI-Images/img6.png';
import img3 from '../../../AI-Images/img7.jpg';
import img4 from '../../../AI-Images/img8.jpg';
import img5 from '../../../AI-Images/img10.jpg';
import img6 from '../../../AI-Images/img11.jpg';
import { Link } from 'react-router-dom';
import { database } from '../../../firebase/firebase';
import { Backdrop, CircularProgress } from '@mui/material';

export default function TopProjects(){
  const [open, setOpen] = useState(false)
  const [data, setData] = useState([])
  useEffect(()=>{
    setOpen(true)
    database.ref('projects').orderByChild('topproject').equalTo("Yes").once('value', snapshot=>{
      if(snapshot.val()){
        setData(Object.values(snapshot.val()).reverse())
        setOpen(false)
      }
    })
  },[])
  return(
    <div className='top-projects-section' style={{width:'100%', backgroundColor:'transparent'}}>
      {/* <Backdrop sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })} open={open}>
        <CircularProgress color='inherit' />
      </Backdrop> */}
      <div className='our-top-projects-div'>
        <h2 className='our-top-projects' style={{textAlign:'center', width:'fit-content',cursor:'default', userSelect:'none', color:'#F26522', marginBottom:'30px'}}>Our Top Projects</h2>
      </div>
      <div style={{width:'100%', padding:'20px 0px 20px 0px', display:'flex', flexWrap:'wrap', flexDirection:'row', justifyContent:'center' }}>
        {!data ? null : data.map((d)=>{return(
          <TopProjectsCard img={d.imageUrl} data={d} />
        )})}
        
      </div>
      <div className='browse-projects-div'>
        <div>
          <Link to="projects">Browse All Projects</Link>
        </div>
      </div>
    </div>
  )
}