import React, { useEffect, useState } from "react";
import './generals.css'
import { NavLink, Route, Routes } from "react-router-dom";
import { database, storage } from "../firebase/firebase";
import ReactPlayer from 'react-player';

export default function Generals({ setOpen, setGetProgress }) {
    return (
        <div className="admin-generals">
            <div className="admin-general-navigation">
                <ul>
                    <li><NavLink to='' end>Prequalification</NavLink></li>
                    <li><NavLink to='logo' end>Logo</NavLink></li>
                    <li><NavLink to='home' end>Home GIF</NavLink></li>
                    <li><NavLink to='home-text'>Home Text</NavLink></li>
                    <li><NavLink to='visitors-count'>Visitors Count</NavLink></li>
                    <li><NavLink to='services-text'>Services Text</NavLink></li>
                    <li><NavLink to='services-items'>Services Items</NavLink></li>
                    <li><NavLink to='count-section'>Count Section</NavLink></li>
                    <li><NavLink to='clients-section'>Clients Section</NavLink></li>
                    <li><NavLink to='about-image'>About Image</NavLink></li>
                    <li><NavLink to='about-text'>About Text</NavLink></li>
                    <li><NavLink to='gm-message'>GM Message</NavLink></li>
                    <li><NavLink to='about-bottom'>About Bottom</NavLink></li>
                    <li><NavLink to='projects-image'>Projects Image</NavLink></li>
                    <li><NavLink to='news-image'>News Image</NavLink></li>
                    <li><NavLink to='contact-details'>Contact Details</NavLink></li>
                </ul>
            </div>
            <div className="generals-body">
                <Routes>
                    <Route path="/" exact element={<Prequalification setOpen={setOpen} setGetProgress={setGetProgress} />} />
                    <Route path="/logo" exact element={<HomeLogo setOpen={setOpen} />} />
                    <Route path="home" exact element={<HomeGIF setOpen={setOpen} setGetProgress={setGetProgress} />} />
                    <Route path="home-text" element={<HomeText setOpen={setOpen} />} />
                    <Route path="visitors-count" element={<VisitorsCount setOpen={setOpen} />} />
                    <Route path="services-text" element={<ServicesText setOpen={setOpen} />} />
                    <Route path="services-items" element={<ServicesItems setOpen={setOpen} />} />
                    <Route path="count-section" element={<Count setOpen={setOpen} />} />
                    <Route path="clients-section" element={<HomeClients setOpen={setOpen} />} />
                    <Route path="about-image" element={<AboutImage setOpen={setOpen} />} />
                    <Route path="about-text" element={<AboutText setOpen={setOpen} />} />
                    <Route path="gm-message" element={<GMMessage setOpen={setOpen} />} />
                    <Route path="about-bottom" element={<AboutBottom setOpen={setOpen} />} />
                    <Route path="projects-image" element={<ProjectsImage setOpen={setOpen} />} />
                    <Route path="news-image" element={<NewsImage setOpen={setOpen} />} />
                    <Route path="contact-details" element={<ContactDetails setOpen={setOpen} />} />
                </Routes>
            </div>
        </div>
    )
}

function generateRandomString(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
}

function Prequalification({ setOpen, setGetProgress }) {
    const [imgU, setImgU] = useState(null)
    const [firebaseImage, setFirebaseImage] = useState(null)
    const [previewImage, setPreviewImage] = useState(null)
    const [vidHeight, setVidHeight] = useState(null)
    const [key, setKey] = useState(null)
    const [isDisabled, setIsDisabled] = useState(false)

    useEffect(() => {
        setOpen(true)
        database.ref('generals/prequalification').once('value').then((snapshot) => {
            if (snapshot.val()) {
                const gotKey = Object.keys(snapshot.val())[0]
                setKey(gotKey)
                setFirebaseImage(snapshot.val()[gotKey].imageUrl)
                setPreviewImage(snapshot.val()[gotKey].imageUrl)
                setVidHeight(snapshot.val()[gotKey].videoHeight)
                setOpen(false)
            } else {
                setOpen(false)
            }
        })
    }, [])



    const handleAdd = () => {
        setOpen(true);
        const fileRef = storage.ref(`generals/${generateRandomString(10)}${imgU.name}`);
        const uploadTask = fileRef.put(imgU);

        uploadTask.on('state_changed',
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setGetProgress(parseFloat(progress).toFixed(2))
            },
            (error) => {
                alert(`Error during upload: ${error.message}`);
                setOpen(false);
                setGetProgress(null)
            },
            () => {
                uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                    database.ref(`generals/prequalification`).push({
                        imageUrl: downloadURL,
                        videoHeight: vidHeight
                    }).then(() => {
                        alert('Operation added successfully.');
                        setIsDisabled(false);
                        setImgU(null);
                        setOpen(false);
                        setGetProgress(null)
                    }).catch((err) => {
                        alert(`Error saving data: ${err.message}`);
                        setOpen(false);
                        setGetProgress(null)
                    });
                });
            }
        );
    }

    const handleEdit = () => {
        setOpen(true)
        if (imgU) {
            storage.refFromURL(firebaseImage).delete().then(() => {
                const tref = storage.ref(`generals/${generateRandomString(10)}${imgU.name}`);
                const uploadTask = tref.put(imgU);

                uploadTask.on('state_changed',
                    (snapshot) => {
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        setGetProgress(parseFloat(progress).toFixed(2))
                    },
                    (error) => {
                        alert(`Error during upload: ${error.message}`);
                        setOpen(false);
                        setGetProgress(null)
                    },
                    () => {
                        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                            database.ref(`generals/prequalification/${key}`).update({
                                imageUrl: downloadURL,
                                videoHeight: vidHeight
                            }).then(() => {
                                alert('Operation updated successfully.');
                                setIsDisabled(false);
                                setImgU(null);
                                setOpen(false);
                                setGetProgress(null)
                            }).catch((err) => {
                                alert(`Error saving data: ${err.message}`);
                                setOpen(false);
                                setGetProgress(null)
                            });
                        });
                    }
                );
            }).catch((err) => {
                alert(`Error ${err}`)
                setOpen(false)
            })
        } else {
            database.ref(`generals/prequalification/${key}`).update({
                videoHeight: vidHeight
            }).then(() => {
                alert(`Operation updated successfully.`)
                setOpen(false)
                setIsDisabled(false)
            }).catch((err) => {
                alert(`Error: ${err}`)
                setOpen(false)
            })
        }
    }
    return (
        <>
            {firebaseImage ? <div className="admin-topimgs" style={{
                paddingBottom: '50px'
            }}>
                
                <input onChange={(e) => {
                    setPreviewImage(URL.createObjectURL(e.target.files[0]))
                    setImgU(e.target.files[0])
                    setIsDisabled(true)
                }} type="file" />
                <button onClick={handleEdit} disabled={isDisabled ? false : true}>Submit Changes</button>
            </div> : <div className="admin-topimgs">
                
                <input onChange={(e) => {
                    setPreviewImage(URL.createObjectURL(e.target.files[0]))
                    setImgU(e.target.files[0])
                    setIsDisabled(true)
                }} type="file" />
                <button onClick={handleAdd} disabled={isDisabled ? false : true}>Submit Changes</button>
            </div>}
        </>
    )
}

function HomeLogo({ setOpen, setGetProgress }) {
    const [imgU, setImgU] = useState(null)
    const [firebaseImage, setFirebaseImage] = useState(null)
    const [previewImage, setPreviewImage] = useState(null)
    const [vidHeight, setVidHeight] = useState(650)
    const [key, setKey] = useState(null)
    const [isDisabled, setIsDisabled] = useState(false)

    useEffect(() => {
        setOpen(true)
        database.ref('generals/homelogo').once('value').then((snapshot) => {
            if (snapshot.val()) {
                const gotKey = Object.keys(snapshot.val())[0]
                setKey(gotKey)
                setFirebaseImage(snapshot.val()[gotKey].imageUrl)
                setPreviewImage(snapshot.val()[gotKey].imageUrl)
                setOpen(false)
            } else {
                setOpen(false)
            }
        })
    }, [])



    const handleAdd = () => {
        setOpen(true);
        const fileRef = storage.ref(`generals/${generateRandomString(10)}${imgU.name}`);
        const uploadTask = fileRef.put(imgU);

        uploadTask.on('state_changed',
            (snapshot) => {
                //
            },
            (error) => {
                alert(`Error during upload: ${error.message}`);
                setOpen(false);
            },
            () => {
                uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                    database.ref(`generals/homelogo`).push({
                        imageUrl: downloadURL,
                        videoHeight: vidHeight
                    }).then(() => {
                        alert('Operation added successfully.');
                        setIsDisabled(false);
                        setImgU(null);
                        setOpen(false);
                    }).catch((err) => {
                        alert(`Error saving data: ${err.message}`);
                        setOpen(false);
                    });
                });
            }
        );
    }

    const handleEdit = () => {
        setOpen(true)
        if (imgU) {
            storage.refFromURL(firebaseImage).delete().then(() => {
                const tref = storage.ref(`generals/${generateRandomString(10)}${imgU.name}`);
                const uploadTask = tref.put(imgU);

                uploadTask.on('state_changed',
                    (snapshot) => {
                        //
                    },
                    (error) => {
                        alert(`Error during upload: ${error.message}`);
                        setOpen(false);
                    },
                    () => {
                        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                            database.ref(`generals/homelogo/${key}`).update({
                                imageUrl: downloadURL,
                                videoHeight: vidHeight
                            }).then(() => {
                                alert('Operation updated successfully.');
                                setIsDisabled(false);
                                setImgU(null);
                                setOpen(false);
                            }).catch((err) => {
                                alert(`Error saving data: ${err.message}`);
                                setOpen(false);
                            });
                        });
                    }
                );
            }).catch((err) => {
                alert(`Error ${err}`)
                setOpen(false)
            })
        } else {
            database.ref(`generals/homelogo/${key}`).update({
                videoHeight: vidHeight
            }).then(() => {
                alert(`Operation updated successfully.`)
                setOpen(false)
                setIsDisabled(false)
            }).catch((err) => {
                alert(`Error: ${err}`)
                setOpen(false)
            })
        }
    }
    return (
        <>
            {firebaseImage ? <div className="admin-topimgs" style={{
                paddingBottom: '50px'
            }}>
                <img
                    src={firebaseImage}
                    className='react-player gif'
                    height={'500px'}
                />
                <input onChange={(e) => {
                    setPreviewImage(URL.createObjectURL(e.target.files[0]))
                    setImgU(e.target.files[0])
                    setIsDisabled(true)
                }} type="file" accept="image/*" />
                <button onClick={handleEdit} disabled={isDisabled ? false : true}>Submit Changes</button>
            </div> : <div className="admin-topimgs">
                <img
                    src={previewImage}
                    className='react-player'
                    height={'500px'}
                />
                <input onChange={(e) => {
                    setPreviewImage(URL.createObjectURL(e.target.files[0]))
                    setImgU(e.target.files[0])
                    setIsDisabled(true)
                }} type="file" accept="image/*" />
                <button onClick={handleAdd} disabled={isDisabled ? false : true}>Submit Changes</button>
            </div>}
        </>
    )
}

function HomeGIF({ setOpen, setGetProgress }) {
    const [imgU, setImgU] = useState(null)
    const [firebaseImage, setFirebaseImage] = useState(null)
    const [previewImage, setPreviewImage] = useState(null)
    const [vidHeight, setVidHeight] = useState(null)
    const [key, setKey] = useState(null)
    const [isDisabled, setIsDisabled] = useState(false)

    useEffect(() => {
        setOpen(true)
        database.ref('generals/homeimage').once('value').then((snapshot) => {
            if (snapshot.val()) {
                const gotKey = Object.keys(snapshot.val())[0]
                setKey(gotKey)
                setFirebaseImage(snapshot.val()[gotKey].imageUrl)
                setPreviewImage(snapshot.val()[gotKey].imageUrl)
                setVidHeight(snapshot.val()[gotKey].videoHeight)
                setOpen(false)
            } else {
                setOpen(false)
            }
        })
    }, [])



    const handleAdd = () => {
        setOpen(true);
        const fileRef = storage.ref(`generals/${generateRandomString(10)}${imgU.name}`);
        const uploadTask = fileRef.put(imgU);

        uploadTask.on('state_changed',
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setGetProgress(parseFloat(progress).toFixed(2))
            },
            (error) => {
                alert(`Error during upload: ${error.message}`);
                setOpen(false);
                setGetProgress(null)
            },
            () => {
                uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                    database.ref(`generals/homeimage`).push({
                        imageUrl: downloadURL,
                        videoHeight: vidHeight
                    }).then(() => {
                        alert('Operation added successfully.');
                        setIsDisabled(false);
                        setImgU(null);
                        setOpen(false);
                        setGetProgress(null)
                    }).catch((err) => {
                        alert(`Error saving data: ${err.message}`);
                        setOpen(false);
                        setGetProgress(null)
                    });
                });
            }
        );
    }

    const handleEdit = () => {
        setOpen(true)
        if (imgU) {
            storage.refFromURL(firebaseImage).delete().then(() => {
                const tref = storage.ref(`generals/${generateRandomString(10)}${imgU.name}`);
                const uploadTask = tref.put(imgU);

                uploadTask.on('state_changed',
                    (snapshot) => {
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        setGetProgress(parseFloat(progress).toFixed(2))
                    },
                    (error) => {
                        alert(`Error during upload: ${error.message}`);
                        setOpen(false);
                        setGetProgress(null)
                    },
                    () => {
                        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                            database.ref(`generals/homeimage/${key}`).update({
                                imageUrl: downloadURL,
                                videoHeight: vidHeight
                            }).then(() => {
                                alert('Operation updated successfully.');
                                setIsDisabled(false);
                                setImgU(null);
                                setOpen(false);
                                setGetProgress(null)
                            }).catch((err) => {
                                alert(`Error saving data: ${err.message}`);
                                setOpen(false);
                                setGetProgress(null)
                            });
                        });
                    }
                );
            }).catch((err) => {
                alert(`Error ${err}`)
                setOpen(false)
            })
        } else {
            database.ref(`generals/homeimage/${key}`).update({
                videoHeight: vidHeight
            }).then(() => {
                alert(`Operation updated successfully.`)
                setOpen(false)
                setIsDisabled(false)
            }).catch((err) => {
                alert(`Error: ${err}`)
                setOpen(false)
            })
        }
    }
    return (
        <>
            {firebaseImage ? <div className="admin-topimgs" style={{
                paddingBottom: '50px'
            }}>
                <ReactPlayer
                    url={firebaseImage}
                    className='react-player gif'
                    playing={true}
                    playsinline
                    muted={true}
                    loop={true}
                    height={'500px'}
                />
                <input onChange={(e) => {
                    setPreviewImage(URL.createObjectURL(e.target.files[0]))
                    setImgU(e.target.files[0])
                    setIsDisabled(true)
                }} type="file" accept="video/*" />
                <input type="number" defaultValue={vidHeight} onChange={(e) => {
                    setIsDisabled(true)
                    setVidHeight(e.target.value)
                }} placeholder="Enter the custom video height" />
                <button onClick={handleEdit} disabled={isDisabled ? false : true}>Submit Changes</button>
            </div> : <div className="admin-topimgs">
                <ReactPlayer
                    url={previewImage}
                    className='react-player'
                    playing={true}
                    playsinline
                    muted={true}
                    loop={true}
                    height={'500px'}
                />
                <input onChange={(e) => {
                    setPreviewImage(URL.createObjectURL(e.target.files[0]))
                    setImgU(e.target.files[0])
                    setIsDisabled(true)
                }} type="file" accept="video/*" />
                <input type="number" defaultValue={vidHeight} onChange={(e) => {
                    setIsDisabled(true)
                    setVidHeight(e.target.value)
                }} placeholder="Enter the custom video height" />
                <button onClick={handleAdd} disabled={isDisabled ? false : true}>Submit Changes</button>
            </div>}
        </>
    )
}

function HomeText({ setOpen }) {
    const [head, setHead] = useState(null);
    const [firstP, setFirstP] = useState(null);
    const [secondP, setSecondP] = useState(null);
    const [key, setKey] = useState(null);
    const [isDisabled, setIsDisabled] = useState(false)

    useEffect(() => {
        setOpen(true)
        database.ref('generals/hometext').once('value').then((snapshot) => {
            if (snapshot.val()) {
                const gotKey = Object.keys(snapshot.val())[0]
                setHead(snapshot.val()[gotKey].headertxt)
                setFirstP(snapshot.val()[gotKey].firstp)
                setSecondP(snapshot.val()[gotKey].secondp)
                setKey(gotKey)
                setOpen(false)
            } else {
                setOpen(false)
            }
        }).catch((err) => {
            alert(`Error: ${err}`)
            setOpen(false)
        })
    }, [])

    const handleAdd = () => {
        setOpen(true)
        if (head.trim() && firstP.trim() && secondP.trim()) {
            database.ref('generals/hometext').push({
                headertxt: head,
                firstp: firstP,
                secondp: secondP
            }).then(() => {
                alert('Data added successfully.')
                setIsDisabled(true)
                setOpen(false)
            }).catch((err) => {
                alert(`Error: ${err}`)
            })
        } else {
            alert('You should fill all fields.')
            setOpen(false)
        }
    }

    const handleEdit = () => {
        setOpen(true)
        if (head.trim() && firstP.trim() && secondP.trim()) {
            database.ref(`generals/hometext/${key}`).update({
                headertxt: head,
                firstp: firstP,
                secondp: secondP
            }).then(() => {
                alert('Data updated successfully.')
                setOpen(false)
            }).catch((err) => {
                alert(`Error: ${err}`)
            })
        } else {
            alert('You should fill all fields.')
            setOpen(false)
        }
    }

    return (
        <>
            {key ? <div className="admin-hometext">
                <textarea rows={6} defaultValue={head} placeholder="Fill the top text" onChange={(e) => setHead(e.target.value)} ></textarea>
                <textarea rows={6} defaultValue={firstP} placeholder="Fill the bottom left text" onChange={(e) => setFirstP(e.target.value)} ></textarea>
                <textarea rows={6} defaultValue={secondP} placeholder="Fill the bottom right text" onChange={(e) => setSecondP(e.target.value)} ></textarea>
                <button onClick={handleEdit} disabled={isDisabled}>Submit Changes</button>
            </div> : <div className="admin-hometext">
                <textarea rows={6} placeholder="Fill the top text" onChange={(e) => setHead(e.target.value)} disabled={isDisabled}></textarea>
                <textarea rows={6} placeholder="Fill the bottom left text" onChange={(e) => setFirstP(e.target.value)} disabled={isDisabled}></textarea>
                <textarea rows={6} placeholder="Fill the bottom right text" onChange={(e) => setSecondP(e.target.value)} disabled={isDisabled}></textarea>
                <button onClick={handleAdd} disabled={isDisabled}>Add Text</button>
            </div>}
        </>
    )
}

function VisitorsCount({ setOpen }) {
    const [number, setNumber] = useState(null)
    useEffect(() => {
        setOpen(true)
        database.ref('generals/visitorscount').once('value').then((snapshot) => {
            if (snapshot.val()) {
                setNumber(snapshot.val()['count'].number)
                setOpen(false)
            }
        }).catch((err) => {
            alert(`Error: ${err}`)
        })
    }, [])
    const handleSet = () => {
        if (number) {
            setOpen(true)
            database.ref('generals/visitorscount/count').update({
                number
            }).then(() => {
                alert('Visitors count updated successfully.')
                setOpen(false)
            }).catch((err) => {
                alert(`Error: ${err}`)
                setOpen(false)
            })
        } else {
            setOpen(true)
            database.ref('generals/visitorscount/count').push({
                number
            }).then(() => {
                alert('Visitors count updated successfully.')
                setOpen(false)
            }).catch((err) => {
                alert(`Error: ${err}`)
                setOpen(false)
            })
        }
    }
    return (
        <div className="admin-visitors-count">
            <input type="number" defaultValue={number} onChange={(e) => { setNumber(e.target.value) }} />
            <button onClick={handleSet}>Set</button>
        </div>
    )
}

function ServicesText({ setOpen }) {
    const [head, setHead] = useState(null);
    const [key, setKey] = useState(null);
    const [isDisabled, setIsDisabled] = useState(false)

    useEffect(() => {
        setOpen(true)
        database.ref('generals/servicestext').once('value').then((snapshot) => {
            if (snapshot.val()) {
                const gotKey = Object.keys(snapshot.val())[0]
                setHead(snapshot.val()[gotKey].headertxt)
                setKey(gotKey)
                setOpen(false)
            } else {
                setOpen(false)
            }
        }).catch((err) => {
            alert(`Error: ${err}`)
            setOpen(false)
        })
    }, [])

    const handleAdd = () => {
        setOpen(true)
        if (head.trim()) {
            database.ref('generals/servicestext').push({
                headertxt: head
            }).then(() => {
                alert('Data added successfully.')
                setIsDisabled(true)
                setOpen(false)
            }).catch((err) => {
                alert(`Error: ${err}`)
            })
        } else {
            alert('You should fill all fields.')
            setOpen(false)
        }
    }

    const handleEdit = () => {
        setOpen(true)
        if (head.trim()) {
            database.ref(`generals/servicestext/${key}`).update({
                headertxt: head
            }).then(() => {
                alert('Data updated successfully.')
                setOpen(false)
            }).catch((err) => {
                alert(`Error: ${err}`)
            })
        } else {
            alert('You should fill all fields.')
            setOpen(false)
        }
    }

    return (
        <>
            {key ? <div className="admin-hometext">
                <textarea rows={6} defaultValue={head} placeholder="Fill the services text" onChange={(e) => setHead(e.target.value)} ></textarea>
                <button onClick={handleEdit} disabled={isDisabled}>Submit Changes</button>
            </div> : <div className="admin-hometext">
                <textarea rows={6} placeholder="Fill the services text" onChange={(e) => setHead(e.target.value)} disabled={isDisabled}></textarea>
                <button onClick={handleAdd} disabled={isDisabled}>Add Text</button>
            </div>}
        </>
    )
}

function ServicesItems({ setOpen }) {
    const [item1text, setItem1Text] = useState(null)
    const [item1img, setItem1Img] = useState(null)
    const [item2text, setItem2Text] = useState(null)
    const [item2img, setItem2Img] = useState(null)
    const [item3text, setItem3Text] = useState(null)
    const [item3img, setItem3Img] = useState(null)
    const [item4text, setItem4Text] = useState(null)
    const [item4img, setItem4Img] = useState(null)
    const [item5text, setItem5Text] = useState(null)
    const [item5img, setItem5Img] = useState(null)
    const [item6text, setItem6Text] = useState(null)
    const [item6img, setItem6Img] = useState(null)
    const [item7text, setItem7Text] = useState(null)
    const [item7img, setItem7Img] = useState(null)
    const [item8text, setItem8Text] = useState(null)
    const [item8img, setItem8Img] = useState(null)
    const [key1, setKey1] = useState(null)
    const [key2, setKey2] = useState(null)
    const [key3, setKey3] = useState(null)
    const [key4, setKey4] = useState(null)
    const [key5, setKey5] = useState(null)
    const [key6, setKey6] = useState(null)
    const [key7, setKey7] = useState(null)
    const [key8, setKey8] = useState(null)
    const [imgPrev1, setImgPrev1] = useState(null)
    const [imgPrev2, setImgPrev2] = useState(null)
    const [imgPrev3, setImgPrev3] = useState(null)
    const [imgPrev4, setImgPrev4] = useState(null)
    const [imgPrev5, setImgPrev5] = useState(null)
    const [imgPrev6, setImgPrev6] = useState(null)
    const [imgPrev7, setImgPrev7] = useState(null)
    const [imgPrev8, setImgPrev8] = useState(null)
    const [img1, setImg1] = useState(null)
    const [img2, setImg2] = useState(null)
    const [img3, setImg3] = useState(null)
    const [img4, setImg4] = useState(null)
    const [img5, setImg5] = useState(null)
    const [img6, setImg6] = useState(null)
    const [img7, setImg7] = useState(null)
    const [img8, setImg8] = useState(null)

    useEffect(() => {
        setOpen(true)
        database.ref('generals/servicesItems').once('value').then((snapshot) => {
            if (snapshot.val()) {
                if (Object.keys(snapshot.val())[0]) {
                    const gotKey1 = Object.keys(snapshot.val())[0];
                    setKey1(gotKey1)
                    setItem1Img(snapshot.val()[gotKey1].itemimg)
                    setItem1Text(snapshot.val()[gotKey1].itemtext)
                    setOpen(false)
                }
                if (Object.keys(snapshot.val())[1]) {
                    const gotKey2 = Object.keys(snapshot.val())[1];
                    setKey2(gotKey2)
                    setItem2Img(snapshot.val()[gotKey2].itemimg)
                    setItem2Text(snapshot.val()[gotKey2].itemtext)
                    setOpen(false)
                }
                if (Object.keys(snapshot.val())[2]) {
                    const gotKey3 = Object.keys(snapshot.val())[2];
                    setKey3(gotKey3)
                    setItem3Img(snapshot.val()[gotKey3].itemimg)
                    setItem3Text(snapshot.val()[gotKey3].itemtext)
                    setOpen(false)
                }
                if (Object.keys(snapshot.val())[3]) {
                    const gotKey4 = Object.keys(snapshot.val())[3];
                    setKey4(gotKey4)
                    setItem4Img(snapshot.val()[gotKey4].itemimg)
                    setItem4Text(snapshot.val()[gotKey4].itemtext)
                    setOpen(false)
                }
                if (Object.keys(snapshot.val())[4]) {
                    const gotKey5 = Object.keys(snapshot.val())[4];
                    setKey5(gotKey5)
                    setItem5Img(snapshot.val()[gotKey5].itemimg)
                    setItem5Text(snapshot.val()[gotKey5].itemtext)
                    setOpen(false)
                }
                if (Object.keys(snapshot.val())[5]) {
                    const gotKey6 = Object.keys(snapshot.val())[5];
                    setKey6(gotKey6)
                    setItem6Img(snapshot.val()[gotKey6].itemimg)
                    setItem6Text(snapshot.val()[gotKey6].itemtext)
                    setOpen(false)
                }
                if (Object.keys(snapshot.val())[6]) {
                    const gotKey7 = Object.keys(snapshot.val())[6];
                    setKey7(gotKey7)
                    setItem7Img(snapshot.val()[gotKey7].itemimg)
                    setItem7Text(snapshot.val()[gotKey7].itemtext)
                    setOpen(false)
                }
                if (Object.keys(snapshot.val())[7]) {
                    const gotKey8 = Object.keys(snapshot.val())[7];
                    setKey8(gotKey8)
                    setItem8Img(snapshot.val()[gotKey8].itemimg)
                    setItem8Text(snapshot.val()[gotKey8].itemtext)
                    setOpen(false)
                }
            } else {
                setOpen(false)
            }
        }).catch((err) => {
            alert(`Error: ${err}`)
            setOpen(false)
        })
    }, [])

    const handleEdit = (key, imgurl, img, text, setItemImg) => {
        if (text !== null) {
            if (text.trim() !== '') {
                setOpen(true)
                if (key && img && imgurl !== '' && imgurl !== null) {
                    storage.refFromURL(imgurl).delete().then(() => {
                        const nImgRef = storage.ref(`generals/${img.name}`)
                        nImgRef.put(img).then(() => {
                            nImgRef.getDownloadURL().then((url) => {
                                database.ref(`generals/servicesItems/${key}`).update({
                                    itemimg: url,
                                    itemtext: text
                                }).then(() => {
                                    alert('The item updated successfully.')
                                    setOpen(false)
                                    setItemImg(null)
                                }).catch((err) => {
                                    alert(`Error: ${err}`)
                                    setOpen(false)
                                })
                            }).catch((err) => {
                                alert(`Error: ${err}`)
                                setOpen(false)
                            })
                        }).catch((err) => {
                            alert(`Error: ${err}`)
                            setOpen(false)
                        })
                    }).catch((err) => {
                        alert(`Error: ${err}`)
                        setOpen(false)
                    })
                } else if (key && img && (imgurl === '' || imgurl === null)) {
                    const nImgRef = storage.ref(`generals/${img.name}`)
                    nImgRef.put(img).then(() => {
                        nImgRef.getDownloadURL().then((url) => {
                            database.ref(`generals/servicesItems/${key}`).update({
                                itemimg: url,
                                itemtext: text
                            }).then(() => {
                                alert('The item updated successfully.')
                                setItemImg(null)
                                setOpen(false)
                            }).catch((err) => {
                                alert(`Error: ${err}`)
                                setOpen(false)
                            })
                        }).catch((err) => {
                            alert(`Error: ${err}`)
                            setOpen(false)
                        })
                    }).catch((err) => {
                        alert(`Error: ${err}`)
                        setOpen(false)
                    })
                } else if (key && !img) {
                    database.ref(`generals/servicesItems/${key}`).update({
                        itemtext: text
                    }).then(() => {
                        alert('The item updated successfully.')
                        setOpen(false)
                    }).catch((err) => {
                        alert(`Error: ${err}`)
                        setOpen(false)
                    })
                } else if (!key && !img) {
                    database.ref(`generals/servicesItems`).push({
                        itemimg: "",
                        itemtext: text
                    }).then(() => {
                        alert('The item updated successfully.')
                        setOpen(false)
                    }).catch((err) => {
                        alert(`Error: ${err}`)
                        setOpen(false)
                    })
                } else if ((!key && img)) {
                    const nImgRef = storage.ref(`generals/${img.name}`)
                    nImgRef.put(img).then(() => {
                        nImgRef.getDownloadURL().then((url) => {
                            database.ref(`generals/servicesItems`).push({
                                itemimg: url,
                                itemtext: text
                            }).then(() => {
                                alert('The item updated successfully.')
                                setItemImg(null)
                                setOpen(false)
                            }).catch((err) => {
                                alert(`Error: ${err}`)
                                setOpen(false)
                            })
                        }).catch((err) => {
                            alert(`Error: ${err}`)
                            setOpen(false)
                        })
                    }).catch((err) => {
                        alert(`Error: ${err}`)
                        setOpen(false)
                    })
                }
            } else {
                alert('You should enter the text.')
                setOpen(false)
            }
        } else {
            alert('You should enter the text.')
            setOpen(false)
        }
    }

    return (
        <div className="admin-services-items">
            <div>
                <img src={imgPrev1 ? imgPrev1 : item1img} alt="" />
                <input type="file" onChange={(e) => {
                    setImg1(e.target.files[0])
                    setImgPrev1(URL.createObjectURL(e.target.files[0]))
                }} accept="image/*" />
                <input type="text" onChange={(e) => setItem1Text(e.target.value)} defaultValue={item1text} />
                <button onClick={() => { handleEdit(key1, item1img, img1, item1text, setItem1Img) }}>Edit Item</button>
            </div>
            <div>
                <img src={imgPrev2 ? imgPrev2 : item2img} alt="" />
                <input type="file" onChange={(e) => {
                    setImg2(e.target.files[0])
                    setImgPrev2(URL.createObjectURL(e.target.files[0]))
                }} accept="image/*" />
                <input type="text" onChange={(e) => setItem2Text(e.target.value)} defaultValue={item2text} />
                <button onClick={() => { handleEdit(key2, item2img, img2, item2text, setItem2Img) }}>Edit Item</button>
            </div>
            <div>
                <img src={imgPrev3 ? imgPrev3 : item3img} alt="" />
                <input type="file" onChange={(e) => {
                    setImg3(e.target.files[0])
                    setImgPrev3(URL.createObjectURL(e.target.files[0]))
                }} accept="image/*" />
                <input type="text" onChange={(e) => setItem3Text(e.target.value)} defaultValue={item3text} />
                <button onClick={() => { handleEdit(key3, item3img, img3, item3text, setItem3Img) }}>Edit Item</button>
            </div>
            <div>
                <img src={imgPrev4 ? imgPrev4 : item4img} alt="" />
                <input type="file" onChange={(e) => {
                    setImg4(e.target.files[0])
                    setImgPrev4(URL.createObjectURL(e.target.files[0]))
                }} accept="image/*" />
                <input type="text" onChange={(e) => setItem4Text(e.target.value)} defaultValue={item4text} />
                <button onClick={() => { handleEdit(key4, item4img, img4, item4text, setItem4Img) }}>Edit Item</button>
            </div>
            <div>
                <img src={imgPrev5 ? imgPrev5 : item5img} alt="" />
                <input type="file" onChange={(e) => {
                    setImg5(e.target.files[0])
                    setImgPrev5(URL.createObjectURL(e.target.files[0]))
                }} accept="image/*" />
                <input type="text" onChange={(e) => setItem5Text(e.target.value)} defaultValue={item5text} />
                <button onClick={() => { handleEdit(key5, item5img, img5, item5text, setItem5Img) }}>Edit Item</button>
            </div>
            <div>
                <img src={imgPrev6 ? imgPrev6 : item6img} alt="" />
                <input type="file" onChange={(e) => {
                    setImg6(e.target.files[0])
                    setImgPrev6(URL.createObjectURL(e.target.files[0]))
                }} accept="image/*" />
                <input type="text" onChange={(e) => setItem6Text(e.target.value)} defaultValue={item6text} />
                <button onClick={() => { handleEdit(key6, item6img, img6, item6text, setItem6Img) }}>Edit Item</button>
            </div>
            <div>
                <img src={imgPrev7 ? imgPrev7 : item7img} alt="" />
                <input type="file" onChange={(e) => {
                    setImg7(e.target.files[0])
                    setImgPrev7(URL.createObjectURL(e.target.files[0]))
                }} accept="image/*" />
                <input type="text" onChange={(e) => setItem7Text(e.target.value)} defaultValue={item7text} />
                <button onClick={() => { handleEdit(key7, item7img, img7, item7text, setItem7Img) }}>Edit Item</button>
            </div>
            <div>
                <img src={imgPrev8 ? imgPrev8 : item8img} alt="" />
                <input type="file" onChange={(e) => {
                    setImg8(e.target.files[0])
                    setImgPrev8(URL.createObjectURL(e.target.files[0]))
                }} accept="image/*" />
                <input type="text" onChange={(e) => setItem8Text(e.target.value)} defaultValue={item8text} />
                <button onClick={() => { handleEdit(key8, item8img, img8, item8text, setItem8Img) }}>Edit Item</button>
            </div>
        </div>
    )
}

function Count({ setOpen }) {
    const [name1, setName1] = useState(null)
    const [name2, setName2] = useState(null)
    const [name3, setName3] = useState(null)
    const [name4, setName4] = useState(null)
    const [number1, setNumber1] = useState(null)
    const [number2, setNumber2] = useState(null)
    const [number3, setNumber3] = useState(null)
    const [number4, setNumber4] = useState(null)
    const [key, setKey] = useState(null)
    const [isDisabled, setIsDisabled] = useState(false)

    useEffect(() => {
        database.ref('generals/counts').once('value').then((snapshot) => {
            if (snapshot.val()) {
                const gotKey = Object.keys(snapshot.val())[0];
                setKey(gotKey)
                setName1(snapshot.val()[gotKey].name1)
                setNumber1(snapshot.val()[gotKey].number1)
                setName2(snapshot.val()[gotKey].name2)
                setNumber2(snapshot.val()[gotKey].number2)
                setName3(snapshot.val()[gotKey].name3)
                setNumber3(snapshot.val()[gotKey].number3)
                setName4(snapshot.val()[gotKey].name4)
                setNumber4(snapshot.val()[gotKey].number4)
            }
        }).catch((err) => {
            alert(`Error: ${err}`)
        })
    }, [])

    const handleAdd = () => {
        setOpen(true)
        if (name1.trim() && name2.trim() && name3.trim() && name4.trim() && number1 && number2 && number3 && number4) {
            database.ref('generals/counts').push({
                name1,
                number1,
                name2,
                number2,
                name3,
                number3,
                name4,
                number4
            }).then(() => {
                alert('Counts added successfully.')
                setOpen(false)
                setIsDisabled(true)
            })
        } else {
            alert('Please fill all fields.')
            setOpen(false)
        }
    }

    const handleEdit = () => {
        setOpen(true)
        if (name1.trim() && name2.trim() && name3.trim() && name4.trim() && number1 && number2 && number3 && number4) {
            database.ref(`generals/counts/${key}`).update({
                name1,
                number1,
                name2,
                number2,
                name3,
                number3,
                name4,
                number4
            }).then(() => {
                alert('Counts updated successfully.')
                setOpen(false)
                setIsDisabled(true)
            })
        } else {
            alert('Please fill all fields.')
            setOpen(false)
        }
    }

    return (
        <>
            {key ? <div className="admin-count">
                <input type="text" defaultValue={name1} onChange={(e) => setName1(e.target.value)} />
                <input type="number" defaultValue={number1} onChange={(e) => setNumber1(e.target.value)} />
                <input type="text" defaultValue={name2} onChange={(e) => setName2(e.target.value)} />
                <input type="number" defaultValue={number2} onChange={(e) => setNumber2(e.target.value)} />
                <input type="text" defaultValue={name3} onChange={(e) => setName3(e.target.value)} />
                <input type="number" defaultValue={number3} onChange={(e) => setNumber3(e.target.value)} />
                <input type="text" defaultValue={name4} onChange={(e) => setName4(e.target.value)} />
                <input type="number" defaultValue={number4} onChange={(e) => setNumber4(e.target.value)} />
                <button onClick={handleEdit}>Submit Changes</button>
            </div> : <div className="admin-count">
                <input type="text" placeholder="Add text" disabled={isDisabled} onChange={(e) => setName1(e.target.value)} />
                <input type="number" placeholder="Add number" disabled={isDisabled} onChange={(e) => setNumber1(e.target.value)} />
                <input type="text" placeholder="Add text" disabled={isDisabled} onChange={(e) => setName2(e.target.value)} />
                <input type="number" placeholder="Add number" disabled={isDisabled} onChange={(e) => setNumber2(e.target.value)} />
                <input type="text" placeholder="Add text" disabled={isDisabled} onChange={(e) => setName3(e.target.value)} />
                <input type="number" placeholder="Add number" disabled={isDisabled} onChange={(e) => setNumber3(e.target.value)} />
                <input type="text" placeholder="Add text" disabled={isDisabled} onChange={(e) => setName4(e.target.value)} />
                <input type="number" placeholder="Add number" disabled={isDisabled} onChange={(e) => setNumber4(e.target.value)} />
                <button onClick={handleAdd} disabled={isDisabled}>Add Counts</button>
            </div>}
        </>
    )
}

function HomeClients({ setOpen }) {
    const [addItem, setAddItem] = useState(true)
    const [editItem, setEditItem] = useState(false)
    return (
        <div className="admin-clients-home-scetion">
            <ul>
                <li style={{
                    color: addItem ? 'orange' : 'white'
                }} onClick={() => {
                    setAddItem(true)
                    setEditItem(false)
                }}>Add Item</li>
                <li style={{
                    color: editItem ? 'orange' : 'white'
                }} onClick={() => {
                    setAddItem(false)
                    setEditItem(true)
                }}>Edit Items</li>
            </ul>
            <div>
                {addItem ? <AddClientsItem setOpen={setOpen} /> : null}
                {editItem ? <EditClientsItem setOpen={setOpen} /> : null}
            </div>
        </div>
    )
}

function AboutImage({ setOpen }) {
    const [imgU, setImgU] = useState(null)
    const [firebaseImage, setFirebaseImage] = useState(null)
    const [previewImage, setPreviewImage] = useState(null)
    const [key, setKey] = useState(null)
    const [isDisabled, setIsDisabled] = useState(false)

    useEffect(() => {
        setOpen(true)
        database.ref('generals/aboutimage').once('value').then((snapshot) => {
            if (snapshot.val()) {
                const gotKey = Object.keys(snapshot.val())[0]
                setKey(gotKey)
                setFirebaseImage(snapshot.val()[gotKey].imageUrl)
                setPreviewImage(snapshot.val()[gotKey].imageUrl)
                setOpen(false)
            } else {
                setOpen(false)
            }
        })
    }, [])

    const handleAdd = () => {
        setOpen(true)
        const tref = storage.ref(`generals/${generateRandomString(10)}${imgU.name}`);
        tref.put(imgU).then(() => {
            tref.getDownloadURL().then((url) => {
                database.ref(`generals/aboutimage`).push({
                    imageUrl: url
                }).then(() => {
                    alert(`Operation updated successfully.`)
                    setIsDisabled(true)
                    setImgU(null)
                    setOpen(false)
                }).catch((err) => {
                    alert(`Error: ${err}`)
                    setOpen(false)
                })
            }).catch((err) => {
                alert(`Error: ${err}`)
                setOpen(false)
            })
        }).catch((err) => {
            alert(`Error: ${err}`)
            setOpen(false)
        })
    }

    const handleEdit = () => {
        setOpen(true)
        storage.refFromURL(firebaseImage).delete().then(() => {
            const tref = storage.ref(`generals/${generateRandomString(10)}${imgU.name}`);
            tref.put(imgU).then(() => {
                tref.getDownloadURL().then((url) => {
                    database.ref(`generals/aboutimage/${key}`).update({
                        imageUrl: url
                    }).then(() => {
                        alert(`Operation updated successfully.`)
                        setOpen(false)
                    }).catch((err) => {
                        alert(`Error: ${err}`)
                        setOpen(false)
                    })
                }).catch((err) => {
                    alert(`Error: ${err}`)
                    setOpen(false)
                })
            }).catch((err) => {
                alert(`Error: ${err}`)
                setOpen(false)
            })
        }).catch((err) => {
            alert(`Error ${err}`)
            setOpen(false)
        })
    }
    return (
        <>
            {firebaseImage ? <div className="admin-topimgs">
                <img src={previewImage} alt="" style={{
                    visibility: previewImage ? 'visible' : 'hidden'
                }} />
                <input onChange={(e) => {
                    setPreviewImage(URL.createObjectURL(e.target.files[0]))
                    setImgU(e.target.files[0])
                }} type="file" accept="image/*" />
                <button onClick={handleEdit} disabled={imgU ? false : true}>Submit Changes</button>
            </div> : <div className="admin-topimgs">
                <img src={previewImage} alt="" style={{
                    visibility: previewImage ? 'visible' : 'hidden'
                }} />
                <input onChange={(e) => {
                    setPreviewImage(URL.createObjectURL(e.target.files[0]))
                    setImgU(e.target.files[0])
                }} type="file" accept="image/*" disabled={isDisabled} />
                <button onClick={handleAdd} disabled={imgU ? false : true}>Add Image</button>
            </div>}
        </>
    )
}

function AboutText({ setOpen }) {
    const [head, setHead] = useState(null);
    const [key, setKey] = useState(null);
    const [isDisabled, setIsDisabled] = useState(false)

    useEffect(() => {
        setOpen(true)
        database.ref('generals/abouttext').once('value').then((snapshot) => {
            if (snapshot.val()) {
                const gotKey = Object.keys(snapshot.val())[0]
                setHead(snapshot.val()[gotKey].headertxt)
                setKey(gotKey)
                setOpen(false)
            } else {
                setOpen(false)
            }
        }).catch((err) => {
            alert(`Error: ${err}`)
            setOpen(false)
        })
    }, [])

    const handleAdd = () => {
        setOpen(true)
        if (head.trim()) {
            database.ref('generals/abouttext').push({
                headertxt: head
            }).then(() => {
                alert('Data added successfully.')
                setIsDisabled(true)
                setOpen(false)
            }).catch((err) => {
                alert(`Error: ${err}`)
            })
        } else {
            alert('You should fill all fields.')
            setOpen(false)
        }
    }

    const handleEdit = () => {
        setOpen(true)
        if (head.trim()) {
            database.ref(`generals/abouttext/${key}`).update({
                headertxt: head
            }).then(() => {
                alert('Data updated successfully.')
                setOpen(false)
            }).catch((err) => {
                alert(`Error: ${err}`)
            })
        } else {
            alert('You should fill all fields.')
            setOpen(false)
        }
    }

    return (
        <>
            {key ? <div className="admin-hometext">
                <textarea rows={6} defaultValue={head} placeholder="Fill the about text" onChange={(e) => setHead(e.target.value)} ></textarea>
                <button onClick={handleEdit} disabled={isDisabled}>Submit Changes</button>
            </div> : <div className="admin-hometext">
                <textarea rows={6} placeholder="Fill the about text" onChange={(e) => setHead(e.target.value)} disabled={isDisabled}></textarea>
                <button onClick={handleAdd} disabled={isDisabled}>Add Text</button>
            </div>}
        </>
    )
}

function GMMessage({ setOpen }) {
    const [head, setHead] = useState(null);
    const [firstP, setFirstP] = useState(null);
    const [secondP, setSecondP] = useState(null);
    const [key, setKey] = useState(null);
    const [isDisabled, setIsDisabled] = useState(false)

    useEffect(() => {
        setOpen(true)
        database.ref('generals/gmmessage').once('value').then((snapshot) => {
            if (snapshot.val()) {
                const gotKey = Object.keys(snapshot.val())[0]
                setHead(snapshot.val()[gotKey].headertxt)
                setFirstP(snapshot.val()[gotKey].firstp)
                setSecondP(snapshot.val()[gotKey].secondp)
                setKey(gotKey)
                setOpen(false)
            } else {
                setOpen(false)
            }
        }).catch((err) => {
            alert(`Error: ${err}`)
            setOpen(false)
        })
    }, [])

    const handleAdd = () => {
        setOpen(true)
        if (head.trim() && firstP.trim() && secondP.trim()) {
            database.ref('generals/gmmessage').push({
                headertxt: head,
                firstp: firstP,
                secondp: secondP
            }).then(() => {
                alert('Data added successfully.')
                setIsDisabled(true)
                setOpen(false)
            }).catch((err) => {
                alert(`Error: ${err}`)
            })
        } else {
            alert('You should fill all fields.')
            setOpen(false)
        }
    }

    const handleEdit = () => {
        setOpen(true)
        if (head.trim() && firstP.trim() && secondP.trim()) {
            database.ref(`generals/gmmessage/${key}`).update({
                headertxt: head,
                firstp: firstP,
                secondp: secondP
            }).then(() => {
                alert('Data updated successfully.')
                setOpen(false)
            }).catch((err) => {
                alert(`Error: ${err}`)
            })
        } else {
            alert('You should fill all fields.')
            setOpen(false)
        }
    }

    return (
        <>
            {key ? <div className="admin-hometext">
                <textarea rows={6} defaultValue={head} placeholder="Fill the top text" onChange={(e) => setHead(e.target.value)} ></textarea>
                <textarea rows={6} defaultValue={firstP} placeholder="Fill the middle text" onChange={(e) => setFirstP(e.target.value)} ></textarea>
                <textarea rows={6} defaultValue={secondP} placeholder="Fill the bottom text" onChange={(e) => setSecondP(e.target.value)} ></textarea>
                <button onClick={handleEdit} disabled={isDisabled}>Submit Changes</button>
            </div> : <div className="admin-hometext">
                <textarea rows={6} placeholder="Fill the top text" onChange={(e) => setHead(e.target.value)} disabled={isDisabled}></textarea>
                <textarea rows={6} placeholder="Fill the middle text" onChange={(e) => setFirstP(e.target.value)} disabled={isDisabled}></textarea>
                <textarea rows={6} placeholder="Fill the bottom text" onChange={(e) => setSecondP(e.target.value)} disabled={isDisabled}></textarea>
                <button onClick={handleAdd} disabled={isDisabled}>Add Text</button>
            </div>}
        </>
    )
}

function AboutBottom({ setOpen }) {
    const [whyegybuild, setWhyegybuild] = useState(null);
    const [mission, setMission] = useState(null);
    const [vision, setVision] = useState(null);
    const [key, setKey] = useState(null);
    const [isDisabled, setIsDisabled] = useState(false)

    useEffect(() => {
        setOpen(true)
        database.ref('generals/aboutbottom').once('value').then((snapshot) => {
            if (snapshot.val()) {
                const gotKey = Object.keys(snapshot.val())[0]
                setWhyegybuild(snapshot.val()[gotKey].whyegybuild)
                setMission(snapshot.val()[gotKey].mission)
                setVision(snapshot.val()[gotKey].vision)
                setKey(gotKey)
                setOpen(false)
            } else {
                setOpen(false)
            }
        }).catch((err) => {
            alert(`Error: ${err}`)
            setOpen(false)
        })
    }, [])

    const handleAdd = () => {
        setOpen(true)
        if (whyegybuild.trim() && mission.trim() && vision.trim()) {
            database.ref('generals/aboutbottom').push({
                whyegybuild,
                mission,
                vision
            }).then(() => {
                alert('Data added successfully.')
                setIsDisabled(true)
                setOpen(false)
            }).catch((err) => {
                alert(`Error: ${err}`)
            })
        } else {
            alert('You should fill all fields.')
            setOpen(false)
        }
    }

    const handleEdit = () => {
        setOpen(true)
        if (whyegybuild.trim() && mission.trim() && vision.trim()) {
            database.ref(`generals/aboutbottom/${key}`).update({
                whyegybuild,
                mission,
                vision
            }).then(() => {
                alert('Data updated successfully.')
                setOpen(false)
            }).catch((err) => {
                alert(`Error: ${err}`)
            })
        } else {
            alert('You should fill all fields.')
            setOpen(false)
        }
    }

    return (
        <>
            {key ? <div className="admin-hometext">
                <textarea rows={6} defaultValue={whyegybuild} placeholder="Enter why egybuild" onChange={(e) => setWhyegybuild(e.target.value)} ></textarea>
                <textarea rows={6} defaultValue={mission} placeholder="Enter mission" onChange={(e) => setMission(e.target.value)} ></textarea>
                <textarea rows={6} defaultValue={vision} placeholder="Enter vision" onChange={(e) => setVision(e.target.value)} ></textarea>
                <button onClick={handleEdit} disabled={isDisabled}>Submit Changes</button>
            </div> : <div className="admin-hometext">
                <textarea rows={6} placeholder="Enter why egybuild" onChange={(e) => setWhyegybuild(e.target.value)} disabled={isDisabled}></textarea>
                <textarea rows={6} placeholder="Enter mission" onChange={(e) => setMission(e.target.value)} disabled={isDisabled}></textarea>
                <textarea rows={6} placeholder="Enter vision" onChange={(e) => setVision(e.target.value)} disabled={isDisabled}></textarea>
                <button onClick={handleAdd} disabled={isDisabled}>Add Text</button>
            </div>}
        </>
    )
}

function ProjectsImage({ setOpen }) {
    const [imgU, setImgU] = useState(null)
    const [firebaseImage, setFirebaseImage] = useState(null)
    const [previewImage, setPreviewImage] = useState(null)
    const [key, setKey] = useState(null)
    const [isDisabled, setIsDisabled] = useState(false)

    useEffect(() => {
        setOpen(true)
        database.ref('generals/projectsimage').once('value').then((snapshot) => {
            if (snapshot.val()) {
                const gotKey = Object.keys(snapshot.val())[0]
                setKey(gotKey)
                setFirebaseImage(snapshot.val()[gotKey].imageUrl)
                setPreviewImage(snapshot.val()[gotKey].imageUrl)
                setOpen(false)
            }
            setOpen(false)
        })
    }, [])

    const handleAdd = () => {
        setOpen(true)
        const tref = storage.ref(`generals/${generateRandomString(10)}${imgU.name}`);
        tref.put(imgU).then(() => {
            tref.getDownloadURL().then((url) => {
                database.ref(`generals/projectsimage`).push({
                    imageUrl: url
                }).then(() => {
                    alert(`Operation updated successfully.`)
                    setIsDisabled(true)
                    setImgU(null)
                    setOpen(false)
                }).catch((err) => {
                    alert(`Error: ${err}`)
                    setOpen(false)
                })
            }).catch((err) => {
                alert(`Error: ${err}`)
                setOpen(false)
            })
        }).catch((err) => {
            alert(`Error: ${err}`)
            setOpen(false)
        })
    }

    const handleEdit = () => {
        setOpen(true)
        storage.refFromURL(firebaseImage).delete().then(() => {
            const tref = storage.ref(`generals/${generateRandomString(10)}${imgU.name}`);
            tref.put(imgU).then(() => {
                tref.getDownloadURL().then((url) => {
                    database.ref(`generals/projectsimage/${key}`).update({
                        imageUrl: url
                    }).then(() => {
                        alert(`Operation updated successfully.`)
                        setOpen(false)
                    }).catch((err) => {
                        alert(`Error: ${err}`)
                        setOpen(false)
                    })
                }).catch((err) => {
                    alert(`Error: ${err}`)
                    setOpen(false)
                })
            }).catch((err) => {
                alert(`Error: ${err}`)
                setOpen(false)
            })
        }).catch((err) => {
            alert(`Error ${err}`)
            setOpen(false)
        })
    }
    return (
        <>
            {firebaseImage ? <div className="admin-topimgs">
                <img src={previewImage} alt="" style={{
                    visibility: previewImage ? 'visible' : 'hidden'
                }} />
                <input onChange={(e) => {
                    setPreviewImage(URL.createObjectURL(e.target.files[0]))
                    setImgU(e.target.files[0])
                }} type="file" accept="image/*" />
                <button onClick={handleEdit} disabled={imgU ? false : true}>Submit Changes</button>
            </div> : <div className="admin-topimgs">
                <img src={previewImage} alt="" style={{
                    visibility: previewImage ? 'visible' : 'hidden'
                }} />
                <input onChange={(e) => {
                    setPreviewImage(URL.createObjectURL(e.target.files[0]))
                    setImgU(e.target.files[0])
                }} type="file" accept="image/*" disabled={isDisabled} />
                <button onClick={handleAdd} disabled={imgU ? false : true}>Add Image</button>
            </div>}
        </>
    )
}

function NewsImage({ setOpen }) {
    const [imgU, setImgU] = useState(null)
    const [firebaseImage, setFirebaseImage] = useState(null)
    const [previewImage, setPreviewImage] = useState(null)
    const [key, setKey] = useState(null)
    const [isDisabled, setIsDisabled] = useState(false)

    useEffect(() => {
        setOpen(true)
        database.ref('generals/newsimage').once('value').then((snapshot) => {
            if (snapshot.val()) {
                const gotKey = Object.keys(snapshot.val())[0]
                setKey(gotKey)
                setFirebaseImage(snapshot.val()[gotKey].imageUrl)
                setPreviewImage(snapshot.val()[gotKey].imageUrl)
                setOpen(false)
            } else {
                setOpen(false)
            }
        })
    }, [])

    const handleAdd = () => {
        setOpen(true)
        const tref = storage.ref(`generals/${generateRandomString(10)}${imgU.name}`);
        tref.put(imgU).then(() => {
            tref.getDownloadURL().then((url) => {
                database.ref(`generals/newsimage`).push({
                    imageUrl: url
                }).then(() => {
                    alert(`Operation updated successfully.`)
                    setIsDisabled(true)
                    setImgU(null)
                    setOpen(false)
                }).catch((err) => {
                    alert(`Error: ${err}`)
                    setOpen(false)
                })
            }).catch((err) => {
                alert(`Error: ${err}`)
                setOpen(false)
            })
        }).catch((err) => {
            alert(`Error: ${err}`)
            setOpen(false)
        })
    }

    const handleEdit = () => {
        setOpen(true)
        storage.refFromURL(firebaseImage).delete().then(() => {
            const tref = storage.ref(`generals/${generateRandomString(10)}${imgU.name}`);
            tref.put(imgU).then(() => {
                tref.getDownloadURL().then((url) => {
                    database.ref(`generals/newsimage/${key}`).update({
                        imageUrl: url
                    }).then(() => {
                        alert(`Operation updated successfully.`)
                        setOpen(false)
                    }).catch((err) => {
                        alert(`Error: ${err}`)
                        setOpen(false)
                    })
                }).catch((err) => {
                    alert(`Error: ${err}`)
                    setOpen(false)
                })
            }).catch((err) => {
                alert(`Error: ${err}`)
                setOpen(false)
            })
        }).catch((err) => {
            alert(`Error ${err}`)
            setOpen(false)
        })
    }
    return (
        <>
            {firebaseImage ? <div className="admin-topimgs">
                <img src={previewImage} alt="" style={{
                    visibility: previewImage ? 'visible' : 'hidden'
                }} />
                <input onChange={(e) => {
                    setPreviewImage(URL.createObjectURL(e.target.files[0]))
                    setImgU(e.target.files[0])
                }} type="file" accept="image/*" />
                <button onClick={handleEdit} disabled={imgU ? false : true}>Submit Changes</button>
            </div> : <div className="admin-topimgs">
                <img src={previewImage} alt="" style={{
                    visibility: previewImage ? 'visible' : 'hidden'
                }} />
                <input onChange={(e) => {
                    setPreviewImage(URL.createObjectURL(e.target.files[0]))
                    setImgU(e.target.files[0])
                }} type="file" accept="image/*" disabled={isDisabled} />
                <button onClick={handleAdd} disabled={imgU ? false : true}>Add Image</button>
            </div>}
        </>
    )
}

function ContactDetails({ setOpen }) {
    const [tel, setTel] = useState(null);
    const [fax, setFax] = useState(null);
    const [pobox, setPobox] = useState(null);
    const [email, setEmail] = useState(null);
    const [address, setAddress] = useState(null);
    const [key, setKey] = useState(null);
    const [isDisabled, setIsDisabled] = useState(false)

    useEffect(() => {
        setOpen(true)
        database.ref('generals/contactDetails').once('value').then((snapshot) => {
            if (snapshot.val()) {
                const gotKey = Object.keys(snapshot.val())[0]
                setTel(snapshot.val()[gotKey].tel)
                setFax(snapshot.val()[gotKey].fax)
                setPobox(snapshot.val()[gotKey].pobox)
                setEmail(snapshot.val()[gotKey].email)
                setAddress(snapshot.val()[gotKey].address)
                setKey(gotKey)
                setOpen(false)
            } else {
                setOpen(false)
            }
        }).catch((err) => {
            alert(`Error: ${err}`)
            setOpen(false)
        })
    }, [])

    const handleAdd = () => {
        setOpen(true)
        if (tel && fax && pobox && email && address) {
            if (tel.trim() && fax.trim() && pobox.trim() && email.trim() && address.trim()) {
                database.ref('generals/contactDetails').push({
                    tel,
                    fax,
                    pobox,
                    email,
                    address
                }).then(() => {
                    alert('Data added successfully.')
                    setIsDisabled(true)
                    setOpen(false)
                }).catch((err) => {
                    alert(`Error: ${err}`)
                })
            } else {
                alert('You should fill all fields.')
                setOpen(false)
            }
        } else {
            alert('You should fill all fields.')
            setOpen(false)
        }
    }

    const handleEdit = () => {
        setOpen(true)
        if (tel.trim() && fax.trim() && pobox.trim() && email.trim() && address.trim()) {
            database.ref(`generals/contactDetails/${key}`).update({
                tel,
                fax,
                pobox,
                email,
                address
            }).then(() => {
                alert('Data updated successfully.')
                setOpen(false)
            }).catch((err) => {
                alert(`Error: ${err}`)
            })
        } else {
            alert('You should fill all fields.')
            setOpen(false)
        }
    }

    return (
        <>
            {key ? <div className="admin-hometext">
                <textarea rows={3} defaultValue={tel} placeholder="Fill Landline" onChange={(e) => setTel(e.target.value)} ></textarea>
                <textarea rows={3} defaultValue={fax} placeholder="Fill Fax" onChange={(e) => setFax(e.target.value)} ></textarea>
                <textarea rows={3} defaultValue={pobox} placeholder="Fill P.O.Box" onChange={(e) => setPobox(e.target.value)} ></textarea>
                <textarea rows={3} defaultValue={email} placeholder="Fill Email" onChange={(e) => setEmail(e.target.value)} ></textarea>
                <textarea rows={3} defaultValue={address} placeholder="Fill Address" onChange={(e) => setAddress(e.target.value)} ></textarea>
                <button onClick={handleEdit} disabled={isDisabled}>Submit Changes</button>
            </div> : <div className="admin-hometext">
                <textarea rows={3} placeholder="Fill Landline" onChange={(e) => setTel(e.target.value)} disabled={isDisabled}></textarea>
                <textarea rows={3} placeholder="Fill Fax" onChange={(e) => setFax(e.target.value)} disabled={isDisabled}></textarea>
                <textarea rows={3} placeholder="Fill P.O.Box" onChange={(e) => setPobox(e.target.value)} disabled={isDisabled}></textarea>
                <textarea rows={3} placeholder="Fill Address" onChange={(e) => setEmail(e.target.value)} disabled={isDisabled}></textarea>
                <textarea rows={3} placeholder="Fill Address" onChange={(e) => setAddress(e.target.value)} disabled={isDisabled}></textarea>
                <button onClick={handleAdd} disabled={isDisabled}>Add Text</button>
            </div>}
        </>
    )
}

/////////////////////////////////////////////////////////////////

function AddClientsItem({ setOpen }) {
    const [imagePrev, setImagePrev] = useState(null)
    const [imageUpload, setImageUpload] = useState(null)

    const handleAdd = (e) => {
        e.preventDefault()
        const form = e.target.form;
        if (!imageUpload) {
            alert('Please choose an image.')
        } else {
            setOpen(true)
            const fileRef = storage.ref(`generals/${imageUpload.name}`);
            fileRef.put(imageUpload).then(() => {
                fileRef.getDownloadURL().then((url) => {
                    const dataU = {
                        imageUrl: url
                    }
                    database.ref('generals/clientssection').push(dataU).then(() => {
                        alert('Item added successfully.')
                        setOpen(false)
                        form.reset()
                        setImagePrev(null)
                    }).catch((err) => {
                        alert('Faild to add the item.')
                        setOpen(false)
                    })
                }).catch((err) => {
                    alert('Faild to upload image.')
                    setOpen(false)
                })
            })
        }
    }

    return (
        <form style={{
            width: '600px',
            margin: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            flexWrap: 'wrap',
            gap: '20px'
        }}>
            <img src={imagePrev} alt="Preview" style={{
                visibility: !imagePrev ? 'hidden' : 'visible',
                width: '250px',
                height: '150px',
                display: 'block',
                margin: 'auto',
                borderRadius: '10px'
            }} />
            <input type="file" onChange={(e) => {
                if (e.target.files[0]) {
                    const imageUrl = URL.createObjectURL(e.target.files[0]);
                    setImagePrev(imageUrl);
                    setImageUpload(e.target.files[0])
                }
            }} accept="image/*" style={{
                fontSize: '16pt',
                padding: '5px 15px',
                borderRadius: '10px',
                outline: 'none',
                border: '1px solid darkblue',
                cursor: 'pointer'
            }} />

            <button style={{
                fontSize: '16pt',
                padding: '5px 15px',
                borderRadius: '10px',
                outline: 'none',
                border: '1px solid darkblue',
                cursor: 'pointer',
                transition: '.2s',
                display: 'block',
                width: '150px',
                margin: 'auto',
                backgroundColor: 'white'
            }} onClick={handleAdd}>Add</button>
        </form>
    )
}

function EditClientsItem({ setOpen }) {

    const [items, setItems] = useState([])
    const [newImg, setNewImg] = useState({})
    const [newImgV, setNewImgV] = useState({})


    const startGet = () => {
        setOpen(true)
        database.ref('generals/clientssection').once('value', snapshot => {
            if (snapshot.val()) {
                Object.keys(snapshot.val()).map((ov) => {
                    setItems(prev => [...prev, {
                        key: ov,
                        imageUrl: snapshot.val()[ov].imageUrl
                    }])
                })
                setOpen(false)
            } else {
                setOpen(false)
            }
        })
    }

    useEffect(() => {
        startGet()
    }, [])

    const handleEdit = (e, key, imageUrl) => {
        e.preventDefault()
        if (newImg[key]) {
            setOpen(true)
            const oimgRef = storage.refFromURL(imageUrl)
            oimgRef.delete().then(() => {
                const nimgRef = storage.ref(`generals/${newImg[key].name}`)
                nimgRef.put(newImg[key]).then(() => {
                    nimgRef.getDownloadURL().then((url) => {
                        database.ref(`generals/clientssection/${key}`).set({
                            imageUrl: url
                        }).then(() => {
                            alert('The Item Updated.')
                            setNewImg({})
                            setNewImgV({})
                            setItems([])
                            startGet()
                            setOpen(false)
                        }).catch((err) => {
                            alert(`Error: ${err}`)
                            setOpen(false)
                        })
                    }).catch((err) => {
                        alert(`Error: ${err}`)
                    })
                }).catch((err) => {
                    alert(`Error: ${err}`)
                })
            }).catch((err) => {
                alert(`Error: ${err}`)
            })
        } else {
            alert('No changes to update')
        }
    }

    const handleDelete = (e, key, imgUrl) => {
        e.preventDefault()
        const uResp = window.confirm('Are you sure you want to delete the item?')
        if (uResp) {
            setOpen(true)
            storage.refFromURL(imgUrl).delete().then(() => {
                database.ref(`generals/clientssection/${key}`).remove().then(() => {
                    alert('The Item deleted.')
                    setItems([])
                    startGet()
                    setOpen(false)
                }).catch((err) => {
                    alert(`Error: ${err}`)
                    setOpen(false)
                })
            }).catch((err) => {
                alert(`Error: ${err}`)
                setOpen(false)
            })
        }
    }

    return (
        <div style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            gap: '20px',
            justifyContent: 'space-evenly'
        }}>
            {!items ? null :
                items.map((i) => {
                    return (
                        <form key={i.key} style={{
                            display: 'flex',
                            flexDirection: 'column',
                            flexWrap: 'wrap',
                            width: '220px',
                            gap: '5px',
                            alignItems: 'center',
                            backgroundColor: 'skyblue',
                            padding: '10px',
                            borderRadius: '10px'
                        }}>
                            <img src={newImgV[i.key] ? newImgV[i.key] : i.imageUrl} style={{
                                width: '200px',
                                height: '120px'
                            }} alt="" />
                            <input type="file" onChange={(e) => {
                                if (e.target.files[0]) {
                                    const imageUrl = URL.createObjectURL(e.target.files[0]);
                                    setNewImgV((prev) => ({ ...prev, [i.key]: imageUrl }));
                                    setNewImg((prev) => ({ ...prev, [i.key]: e.target.files[0] }))
                                }
                            }} accept="image/*" style={{
                                width: '200px',
                                outline: 'none',
                                padding: '5px 10px',
                                border: '1px solid darkblue',
                                borderRadius: '5px',
                                cursor: 'pointer',
                            }} />
                            <input onClick={(e) => handleEdit(e, i.key, i.imageUrl)} type="submit" value="Submit Changes" style={{
                                outline: 'none',
                                border: '1px solid darkblue',
                                backgroundColor: 'white',
                                padding: '5px 10px',
                                borderRadius: '5px',
                                cursor: 'pointer'
                            }} />
                            <input onClick={(e) => handleDelete(e, i.key, i.imageUrl)} style={{
                                outline: 'none',
                                border: '1px solid darkblue',
                                backgroundColor: 'darkred',
                                color: 'white',
                                padding: '5px 10px',
                                borderRadius: '5px',
                                cursor: 'pointer',
                                fontWeight: 'bold'
                            }} type="submit" value="Delete Item" />
                        </form>
                    )
                })
            }
        </div>
    )
}