import React, { useRef, useState } from "react";
import { NavLink, Route, Routes } from "react-router-dom";
import { database, storage } from "../firebase/firebase";
import CancelIcon from '@mui/icons-material/Cancel';
import { Backdrop } from "@mui/material";
import firebase from "firebase/compat/app";

export default function Certificates({ setOpen }) {
    return (
        <>
            <div className="admin-projects">
                <ul>
                    <li><NavLink to='' end>Add Certificate</NavLink></li>
                    <li><NavLink to='edit'>Edit Certificate</NavLink></li>
                </ul>
                <div className="admin-projects-body">
                    <Routes>
                        <Route path="edit" element={<EditProject setOpen={setOpen} />} />
                        <Route path="" element={<AddProject setOpen={setOpen} />} />
                    </Routes>
                </div>
            </div>
        </>
    )
}

function generateRandomString(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
}

function AddProject({ setOpen }) {
    const [imgSrc, setImgSrc] = useState(null);
    const [uploadImg, setUploadImg] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setImgSrc(imageUrl);
            setUploadImg(file);
        }
    };


    const handleAdd = (e) => {
        e.preventDefault();
        const form = e.target.form;
        const title = form.elements['title'].value;
        const remarks = form.elements['remarks'].value;

        if (!title || !remarks) {
            setErrorMessage('Please fill in all required fields.');
            setTimeout(() => { setErrorMessage('') }, 2000)
            return;
        }

        setOpen(true)
        database.ref('projects').orderByChild('titlelower').equalTo(title.toLowerCase()).once('value', snapshot => {
            if (snapshot.exists()) {
                alert('This project exists before. Kindly check the project name again')
                setOpen(false)
            } else {
                if (uploadImg) {
                    const fileRef = storage.ref(`images/${generateRandomString(10)}${uploadImg.name}`);
                    fileRef.put(uploadImg).then(() => {
                        fileRef.getDownloadURL().then((url) => {
                            const projectData = {
                                title: title,
                                titlelower: title.toLowerCase(),
                                imageUrl: url,
                                remarks: remarks
                            };

                            database.ref('certificates').push(projectData).then(() => {
                                alert('The Certificates has been added successfully')
                                setImgSrc(null);
                                form.reset();
                                setOpen(false);
                            }).catch((error) => {
                                console.error("Error adding project:", error);
                            });
                        })
                    })
                } else {
                    setErrorMessage('You missed to attach image!')
                    setOpen(false);
                }
            }
        });
    }

    return (
        <div className="add-project" onSubmit={(e) => handleAdd(e)}>
            {errorMessage !== '' ? <p className="errorMessage">{errorMessage} <CancelIcon sx={{ cursor: 'pointer' }} onClick={() => setErrorMessage('')} /></p> : null}
            {imgSrc ? <img src={imgSrc} alt="Chosen Image" /> : null}
            <form>
                <input type="file" onChange={handleFileChange} accept="image/*" style={{ cursor: 'pointer' }} className="project-image" required />
                <input type="text" name="title" placeholder="Title" required className="project-code" />
                <textarea rows={10} name="remarks" placeholder="Remarks"></textarea>
                <input type="submit" onClick={(e) => handleAdd(e)} value="Add Certificate" className="add-button" />
            </form>
        </div>
    );
}

function EditProject({ setOpen }) {
    const [imgSrc, setImgSrc] = useState(null);
    const [selectOpenBD, setSelectOpenBD] = useState(false);
    const [uploadImg, setUploadImg] = useState(null);
    const [projectImgUrl, setProjectImgUrl] = useState(null);
    const [data, setData] = useState(null);
    const [dataInfo, setDataInfo] = useState(null);
    const [projectDetails, setProjectDetails] = useState({
        title: '',
        titlelower: '',
        remarks: '',
    });
    const [projectKey, setProjectKey] = useState('');
    const [isResult, setIsResult] = useState(false);
    const searchRef = useRef();

    const handleDelete = (e) => {
        e.preventDefault();
        const form = e.target.form;
        const userResponse = window.confirm("Are you sure you want to delete this Certificate?");
        if (userResponse) {
            setOpen(true)
            database.ref(`certificates/${projectKey}`).remove()
                .then(() => {
                    const oldImageRef = storage.refFromURL(projectImgUrl);
                    oldImageRef.delete().then(() => {
                        alert("Certificate deleted successfully.");
                        setOpen(false)
                        setIsResult(false);
                        form.reset();
                        searchRef.current.value = '';
                    }).catch((error) => {
                        alert("Failed to delete Certificate.");
                        setOpen(false)
                    });
                })
                .catch((error) => {
                    alert("Failed to delete Certificate.");
                    setOpen(false)
                });
        }

    }

    const handleSearch = (e) => {
        e.preventDefault();
        setIsResult(false)
        setOpen(true)
        const projectName = searchRef.current.value.toLowerCase();
        const searchQuery = projectName.trim();
        if (projectName) {
            database.ref('certificates').orderByChild('titlelower').startAt(searchQuery).endAt(searchQuery + "\uf8ff").once('value', snapshot => {
                if (snapshot.exists()) {
                    if (Object.keys(snapshot.val()).length > 1) {
                        setData(Object.keys(snapshot.val()))
                        setDataInfo(snapshot.val())
                        setOpen(false)
                        setSelectOpenBD(true)
                    } else {
                        const data = snapshot.val();
                        const key = Object.keys(data)[0];
                        const project = data[key];
                        setProjectDetails({
                            title: project.title,
                            titlelower: project.titlelower,
                            remarks: project.remarks || ''
                        });
                        setProjectKey(key);
                        setImgSrc(project.imageUrl);
                        setProjectImgUrl(project.imageUrl)
                        setIsResult(true);
                        setOpen(false)
                    }
                } else {
                    setIsResult(false);
                    alert('No certificate found with that name.');
                    setOpen(false)
                }
            });
        } else {
            setOpen(false)
            alert('Search Field is empty')
        }
    }

    const handleSelect = (p) => {
        const project = dataInfo[p];
        setProjectDetails({
            title: project.title,
            titlelower: project.titlelower,
            remarks: project.remarks || ''
        });
        setProjectKey(p);
        setImgSrc(project.imageUrl);
        setProjectImgUrl(project.imageUrl)
        setIsResult(true);
        setSelectOpenBD(false)
    }

    const handleEdit = (e) => {
        e.preventDefault();
        setOpen(true)
        if (uploadImg && projectImgUrl !== '') {
            const oldImageRef = storage.refFromURL(projectImgUrl);
            oldImageRef.delete().then(() => {
                const fileRef = storage.ref(`images/${generateRandomString(10)}${uploadImg.name}`);
                fileRef.put(uploadImg).then(() => {
                    fileRef.getDownloadURL().then((url) => {
                        database.ref(`certificates/${projectKey}`).set({
                            title: projectDetails.title,
                            titlelower: projectDetails.title.toLowerCase(),
                            remarks: projectDetails.remarks,
                            imageUrl: url
                        }).then(() => {
                            alert('Certificate updated successfully');
                            setOpen(false)
                        }).catch(error => {
                            console.error('Error updating Certificate:', error);
                            setOpen(false)
                        });
                    });
                });
            }).catch(error => {
                console.error('Error deleting old image:', error);
                console.error('Error deleting old image:', error);
                const fileRef = storage.ref(`images/${generateRandomString(10)}${uploadImg.name}`);
                fileRef.put(uploadImg).then(() => {
                    fileRef.getDownloadURL().then((url) => {
                        database.ref(`certificates/${projectKey}`).set({
                            title: projectDetails.title,
                            titlelower: projectDetails.title.toLowerCase(),
                            remarks: projectDetails.remarks,
                            imageUrl: url
                        }).then(() => {
                            alert('Certificate updated successfully');
                            setOpen(false)
                        }).catch(error => {
                            console.error('Error updating Certificate:', error);
                            setOpen(false)
                        });
                    });
                })
            });
        } else if (uploadImg) {
            const fileRef = storage.ref(`images/${generateRandomString(10)}${uploadImg.name}`);
            fileRef.put(uploadImg).then(() => {
                fileRef.getDownloadURL().then((url) => {
                    database.ref(`certificates/${projectKey}`).set({
                        title: projectDetails.title,
                        titlelower: projectDetails.title.toLowerCase(),
                        remarks: projectDetails.remarks,
                        imageUrl: url
                    }).then(() => {
                        alert('Certificate updated successfully');
                        setOpen(false)
                    }).catch(error => {
                        console.error('Error updating Certificate:', error);
                        setOpen(false)
                    });
                });
            })
        } else {
            database.ref(`certificates/${projectKey}`).set({
                title: projectDetails.title,
                titlelower: projectDetails.title.toLowerCase(),
                remarks: projectDetails.remarks,
                imageUrl: imgSrc
            }).then(() => {
                alert('Certificate updated successfully');
                setOpen(false)
            }).catch(error => {
                console.error('Error updating certificate:', error);
                setOpen(false)
            });
        }
    }

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setImgSrc(imageUrl);
            setUploadImg(file)
        }

    };

    const handleInputChange = (e) => {
        const { name, titlelower, value } = e.target;
        setProjectDetails(prevDetails => ({ ...prevDetails, [name]: value, [titlelower]: value.toLowerCase()}));
    };

    return (
        <div className="edit-project" onClick={() => { setData(null) }}>
            {data ?
                <Backdrop sx={{ zIndex: 100 }} open={selectOpenBD}>
                    <div className="select-box" style={{
                        backgroundColor: '#FFFFFF',
                        maxWidth: '700px',
                        minWidth: '500px',
                        maxHeight: '400px',
                        minHeight: '200px',
                        position: 'fixed',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        boxShadow: '0px 0px 20px -10px black',
                        borderRadius: '10px',
                        padding: '5px 20px',
                        overflow: 'auto',
                    }}>
                        {data.map((d) => {
                            return (
                                <div className="select-div" style={{
                                    fontSize: '15pt',
                                    padding: '10px 20px',
                                    margin: '10px 0px 10px 10px',
                                    cursor: 'pointer',
                                    userSelect: 'none',
                                    background: 'rgba(0,0,0,.1)',
                                    borderRadius: '5px',
                                    transition: '.2s'
                                }} key={d} onClick={() => {
                                    handleSelect(d)
                                    setData(null)
                                    setDataInfo(null)
                                    setSelectOpenBD(false)
                                }}>{`${dataInfo[d].code} || ${dataInfo[d].name}`}</div>
                            )
                        })}
                    </div>
                </Backdrop>
                : null
            }
            <form onSubmit={handleSearch}>
                <input type="text" ref={searchRef} placeholder="Search Certificate by Name" />
                <input type="submit" value='Find' onClick={handleSearch} />
            </form>
            {isResult ? (
                <div className="add-project">
                    <img src={imgSrc || '/placeholder.png'} alt="Project" />
                    <form onSubmit={handleEdit}>
                        <input type="file" onChange={handleFileChange} accept="image/*" />
                        <input type="text" name="title" defaultValue={projectDetails.title} onChange={handleInputChange} placeholder="Title" />
                        <textarea rows={10} name="remarks" defaultValue={projectDetails.remarks} onChange={handleInputChange} placeholder="Remarks"></textarea>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            justifyContent: 'space-between',
                            width: '100% !important'
                        }}>
                            <input className="submit-edit" type="submit" value='Submit Changes' onClick={handleEdit} />
                            <input className="delete-project" type="submit" value='Delete Certificate' onClick={handleDelete} />
                        </div>
                    </form>
                </div>
            ) : null}
        </div>
    );
}
