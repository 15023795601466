import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import RecipeReviewCard from './inner-components/NewsCard';
import './projects.css';
import { database } from '../../firebase/firebase';

export default function News() {
  React.useEffect(()=>{
    window.scrollTo({top: 0})
  },[])

  const [newsImage, setNewsImage] = useState(null)

  useEffect(() => {
    database.ref('generals/newsimage').once('value', snapshot => {
      if (snapshot.val()) {
        setNewsImage(Object.values(snapshot.val())[0].imageUrl)
      }
    })
  }, [])

  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const projectsRef = database.ref('projects');
    projectsRef.on('value', snapshot => {
      const projectsData = snapshot.val();
      const projectsList = projectsData ? Object.keys(projectsData).map(key => ({
        ...projectsData[key],
        id: key
      })) : [];
      setProjects(projectsList.reverse());
    });
    return () => projectsRef.off();
  }, []);

  return (
    <Box
      sx={{
        pt: { xs: 8, sm: 8 },
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        gap: '10px',
        height: 'auto',
      }}
    >
      <div className='news-header' style={{zIndex:1, backgroundSize: window.innerWidth < 768 ? 'auto 100%' : '100% auto', backgroundImage: `url(${newsImage})`}}></div>

      <div className='projects-body-main nbg'>

        <h2 style={{
          width: '100%',
          textAlign: 'center',
          fontSize: window.innerWidth < 768 ? '18pt' : '30pt',
          marginBottom: '20px',
          color: '#F26522',
          zIndex: 1,
          backgroundColor: '#ffffff'
        }}>News</h2>
        
        <div className='projects-sub-body' style={{marginTop: '0px', top:'10px'}}>
          <div className='projects-sub-body-view psbv' style={{paddingBottom: '30px', top:'10px'}}>
            {projects ?
              projects.map((pro) => {
                const data = {
                  code: pro.code,
                  name: pro.name,
                  location: pro.location,
                  client: pro.client,
                  consultant: pro.consultant,
                  remarks: pro.remarks
                }
                if (pro.news === 'Yes') {
                  return (
                    <RecipeReviewCard impImg={pro.imageUrl} data={data} />
                  )
                }
              }) : null
            }
          </div>
          
        </div>
      </div>

    </Box>
  );
}
