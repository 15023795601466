import React, { useRef, useState, useEffect } from "react";
import { NavLink, Route, Routes } from "react-router-dom";
import { database, storage } from "../firebase/firebase";
import CancelIcon from '@mui/icons-material/Cancel';
import { Backdrop } from "@mui/material";
import firebase from "firebase/compat/app";

export default function Projects({ setOpen }) {
    return (
        <>
            <div className="admin-projects">
                <ul>
                    <li><NavLink to='' end>Add Project</NavLink></li>
                    <li><NavLink to='edit'>Edit Project</NavLink></li>
                </ul>
                <div className="admin-projects-body">
                    <Routes>
                        <Route path="edit" element={<EditProject setOpen={setOpen} />} />
                        <Route path="" element={<AddProject setOpen={setOpen} />} />
                    </Routes>
                </div>
            </div>
        </>
    )
}

function generateRandomString(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
}

function AddProject({ setOpen }) {
    const [imgSrc, setImgSrc] = useState(null);
    const [uploadImg, setUploadImg] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [news, setNews] = useState(null);
    const [topProject, setTopProject] = useState(null);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setImgSrc(imageUrl);
            setUploadImg(file);
        }
    };


    const handleAdd = (e) => {
        e.preventDefault();
        const form = e.target.form;
        const projectCode = form.elements['project-code'].value;
        const projectName = form.elements['project-title'].value;
        const projectLocation = form.elements['project-location'].value;
        // const client = form.elements['project-client'].value;
        const consultant = form.elements['project-consultant'].value;
        const projectType = form.elements['project-type'].value;
        const projectStatus = form.elements['project-status'].value;
        const remarks = form.elements['remarks'].value;

        if (!topProject, !news, !projectCode || !projectName || !projectLocation || !consultant || !projectType || !projectStatus) {
            setErrorMessage('Please fill in all required fields.');
            setTimeout(() => { setErrorMessage('') }, 2000)
            return;
        }
        setOpen(true)
        database.ref('projects').orderByChild('topproject').equalTo("Yes").once('value').then((snapshot) => {
            if (topProject === 'Yes') {
                if ((!snapshot.val() || Object.keys(snapshot.val()).length < 6)) {
                    database.ref('projects').orderByChild('codelower').equalTo(projectCode.toLowerCase()).once('value', snapshot => {
                        if (snapshot.exists()) {
                            alert('This project exists before. Kindly check the project code again')
                            setOpen(false)
                        } else {
                            if (uploadImg) {
                                const fileRef = storage.ref(`images/${generateRandomString(10)}${uploadImg.name}`);
                                fileRef.put(uploadImg).then(() => {
                                    fileRef.getDownloadURL().then((url) => {
                                        const projectData = {
                                            code: projectCode,
                                            codelower: projectCode.toLowerCase(),
                                            name: projectName,
                                            lowername: projectName.toLowerCase(),
                                            location: projectLocation,
                                            // client: client,
                                            consultant: consultant,
                                            type: projectType,
                                            status: projectStatus,
                                            imageUrl: url,
                                            remarks: remarks,
                                            news: news,
                                            topproject: topProject,
                                            createdAt: firebase.database.ServerValue.TIMESTAMP
                                        };

                                        database.ref('projects').push(projectData).then(() => {
                                            alert('The project has been added successfully')
                                            setImgSrc(null);
                                            form.reset();
                                            setOpen(false);
                                        }).catch((error) => {
                                            console.error("Error adding project:", error);
                                        });
                                    })
                                })
                            } else {
                                setErrorMessage('You missed to attach image!')
                                setOpen(false);
                            }
                        }
                    });
                } else {
                    alert(`You can't add more than 6 projects as top project`)
                    setOpen(false)
                }
            } else {
                database.ref('projects').orderByChild('codelower').equalTo(projectCode.toLowerCase()).once('value', snapshot => {
                    if (snapshot.exists()) {
                        alert('This project exists before. Kindly check the project code again')
                        setOpen(false)
                    } else {
                        if (uploadImg) {
                            const fileRef = storage.ref(`images/${generateRandomString(10)}${uploadImg.name}`);
                            fileRef.put(uploadImg).then(() => {
                                fileRef.getDownloadURL().then((url) => {
                                    const projectData = {
                                        code: projectCode,
                                        codelower: projectCode.toLowerCase(),
                                        name: projectName,
                                        lowername: projectName.toLowerCase(),
                                        location: projectLocation,
                                        // client: client,
                                        consultant: consultant,
                                        type: projectType,
                                        status: projectStatus,
                                        imageUrl: url,
                                        remarks: remarks,
                                        news: news,
                                        topproject: topProject,
                                        createdAt: firebase.database.ServerValue.TIMESTAMP
                                    };

                                    database.ref('projects').push(projectData).then(() => {
                                        alert('The project has been added successfully')
                                        setImgSrc(null);
                                        form.reset();
                                        setOpen(false);
                                    }).catch((error) => {
                                        console.error("Error adding project:", error);
                                    });
                                })
                            })
                        } else {
                            setErrorMessage('You missed to attach image!')
                            setOpen(false);
                        }
                    }
                });

            }
        }).catch((err) => {
            alert(`Error: ${err}`)
            setOpen(false)
        })

    };

    return (
        <div className="add-project" onSubmit={(e) => handleAdd(e)}>
            {errorMessage !== '' ? <p className="errorMessage">{errorMessage} <CancelIcon sx={{ cursor: 'pointer' }} onClick={() => setErrorMessage('')} /></p> : null}
            {imgSrc ? <img src={imgSrc} alt="Chosen Image" /> : null}
            <form>
                <input type="file" onChange={handleFileChange} accept="image/*" style={{ cursor: 'pointer' }} className="project-image" required />
                <input type="text" name="project-code" placeholder="Project Code" required className="project-code" />
                <input type="text" name="project-title" placeholder="Project Name" required className="project-title" />
                <input type="text" name="project-location" placeholder="Project Location" required className="project-location" />
                {/* <input type="text" name="project-client" placeholder="Client" required className="project-client" /> */}
                <input type="text" name="project-consultant" placeholder="Consultant" className="project-consultant" />
                <select name="project-type" className="project-type" required>
                    <option value="project-type" disabled>Project Type</option>
                    <option value="building">Building</option>
                    <option value="villa">Villa</option>
                </select>
                <select name="project-status" className="project-status" required>
                    <option value="project-status" disabled>Projects Status</option>
                    <option value="completed">Completed</option>
                    <option value="ongoing">Ongoing</option>
                    <option value="upcoming">Upcoming</option>
                </select>
                <div style={{
                    margin: '5px 10px 10px 5px',
                    fontSize: '16pt',
                    display: 'flex',
                    gap: '30px'
                }}>
                    <label>Include in news:</label>
                    <label style={{ cursor: 'pointer' }}><input type="radio" name="news" value="Yes" checked={news === 'Yes'} onChange={() => setNews('Yes')} style={{ cursor: 'pointer' }} /> Yes</label>
                    <label style={{ cursor: 'pointer' }}><input type="radio" name="news" value="No" checked={news === 'No'} onChange={() => setNews('No')} style={{ cursor: 'pointer' }} /> No</label>
                </div>
                <div style={{
                    margin: '5px 10px 10px 5px',
                    fontSize: '16pt',
                    display: 'flex',
                    gap: '30px'
                }}>
                    <label>Include in top projects:</label>
                    <label style={{ cursor: 'pointer' }}><input type="radio" name="topproject" value="Yes" checked={topProject === 'Yes'} onChange={() => setTopProject('Yes')} style={{ cursor: 'pointer' }} /> Yes</label>
                    <label style={{ cursor: 'pointer' }}><input type="radio" name="topproject" value="No" checked={topProject === 'No'} onChange={() => setTopProject('No')} style={{ cursor: 'pointer' }} /> No</label>
                </div>
                <textarea name="remarks" placeholder="Remarks"></textarea>
                <input type="submit" onClick={(e) => handleAdd(e)} value="Add Project" className="add-button" />
            </form>
        </div>
    );
}

function EditProject({ setOpen }) {
    const [imgSrc, setImgSrc] = useState(null);
    const [selectOpenBD, setSelectOpenBD] = useState(false);
    const [uploadImg, setUploadImg] = useState(null);
    const [projectImgUrl, setProjectImgUrl] = useState(null);
    const [data, setData] = useState(null);
    const [dataInfo, setDataInfo] = useState(null);
    const [projectDetails, setProjectDetails] = useState({
        projectCode: '',
        projectCodeLower: '',
        projectName: '',
        projectLowerName: '',
        projectLocation: '',
        // client: '',
        consultant: '',
        projectType: '',
        projectStatus: '',
        remarks: '',
        news: '',
        topproject: ''
    });
    const [projectKey, setProjectKey] = useState('');
    const [isResult, setIsResult] = useState(false);
    const searchRef = useRef();

    const handleDelete = (e) => {
        e.preventDefault();
        const form = e.target.form;
        const userResponse = window.confirm("Are you sure you want to delete this project?");
        if (userResponse) {
            setOpen(true)
            database.ref(`projects/${projectKey}`).remove()
                .then(() => {
                    const oldImageRef = storage.refFromURL(projectImgUrl);
                    oldImageRef.delete().then(() => {
                        alert("Project deleted successfully.");
                        setOpen(false)
                        setIsResult(false);
                        form.reset();
                        searchRef.current.value = '';
                    }).catch((error) => {
                        alert("Failed to delete project.");
                        setOpen(false)
                    });
                })
                .catch((error) => {
                    alert("Failed to delete project.");
                    setOpen(false)
                });
        }

    }

    const handleSearch = (e) => {
        e.preventDefault();
        setIsResult(false)
        setOpen(true)
        const projectName = searchRef.current.value.toLowerCase();
        const searchQuery = projectName.trim();
        if (projectName) {
            database.ref('projects').orderByChild('lowername').startAt(searchQuery).endAt(searchQuery + "\uf8ff").once('value', snapshot => {
                if (snapshot.exists()) {
                    if (Object.keys(snapshot.val()).length > 1) {
                        setData(Object.keys(snapshot.val()))
                        setDataInfo(snapshot.val())
                        setOpen(false)
                        setSelectOpenBD(true)
                    } else {
                        const data = snapshot.val();
                        const key = Object.keys(data)[0];
                        const project = data[key];
                        setProjectDetails({
                            projectName: project.name,
                            projectCode: project.code,
                            projectCodeLower: project.codelower,
                            projectLowerName: project.lowername,
                            projectLocation: project.location,
                            // client: project.client,
                            consultant: project.consultant,
                            projectType: project.type,
                            projectStatus: project.status,
                            remarks: project.remarks || '',
                            news: project.news,
                            topproject: project.topproject
                        });
                        setProjectKey(key);
                        setImgSrc(project.imageUrl);
                        setProjectImgUrl(project.imageUrl)
                        setIsResult(true);
                        setOpen(false)
                    }
                } else {
                    database.ref('projects').orderByChild('codelower').startAt(searchQuery).endAt(searchQuery + "\uf8ff").once('value', snapshot => {
                        if (snapshot.exists()) {
                            if (Object.keys(snapshot.val()).length > 1) {
                                setData(Object.keys(snapshot.val()))
                                setDataInfo(snapshot.val())
                                setOpen(false)
                                setSelectOpenBD(true)
                            } else {
                                const data = snapshot.val();
                                const key = Object.keys(data)[0];
                                const project = data[key];
                                setProjectDetails({
                                    projectName: project.name,
                                    projectCode: project.code,
                                    projectCodeLower: project.codelower,
                                    projectLowerName: project.lowername,
                                    projectLocation: project.location,
                                    // client: project.client,
                                    consultant: project.consultant,
                                    projectType: project.type,
                                    projectStatus: project.status,
                                    remarks: project.remarks || '',
                                    news: project.news,
                                    topproject: project.topproject
                                });
                                setProjectKey(key);
                                setImgSrc(project.imageUrl);
                                setProjectImgUrl(project.imageUrl)
                                setIsResult(true);
                                setOpen(false)
                            }
                        } else {
                            setIsResult(false);
                            alert('No project found with that name.');
                            setOpen(false)
                        }
                    });
                }
            });
        } else {
            setOpen(false)
            alert('Search Field is empty')
        }
    }

    const handleSelect = (p) => {
        // const data = snapshot.val();
        // const key = Object.keys(data)[p];
        const project = dataInfo[p];
        setProjectDetails({
            projectName: project.name,
            projectCode: project.code,
            projectCodeLower: project.codelower,
            projectLowerName: project.lowername,
            projectLocation: project.location,
            // client: project.client,
            consultant: project.consultant,
            projectType: project.type,
            projectStatus: project.status,
            remarks: project.remarks || '',
            news: project.news,
            topproject: project.topproject
        });
        setProjectKey(p);
        setImgSrc(project.imageUrl);
        setProjectImgUrl(project.imageUrl)
        setIsResult(true);
        setSelectOpenBD(false)
    }

    const handleEdit = (e) => {
        e.preventDefault();
        setOpen(true)
        let rslt;
        database.ref(`projects`).once('value', (snapshot) => {
            if (snapshot.val()) {
                rslt = snapshot.val()[projectKey].topproject
            }
        })

        database.ref('projects').orderByChild('topproject').equalTo("Yes").once('value').then((snapshot) => {
            if (projectDetails.topproject === 'Yes' && rslt === 'No') {
                if (Object.keys(snapshot.val()).length < 6) {
                    if (uploadImg && projectImgUrl !== '') {
                        const oldImageRef = storage.refFromURL(projectImgUrl);
                        oldImageRef.delete().then(() => {
                            const fileRef = storage.ref(`images/${generateRandomString(10)}${uploadImg.name}`);
                            fileRef.put(uploadImg).then(() => {
                                fileRef.getDownloadURL().then((url) => {
                                    database.ref(`projects/${projectKey}`).set({
                                        code: projectDetails.projectCode,
                                        codelower: projectDetails.projectCode.toLowerCase(),
                                        name: projectDetails.projectName,
                                        lowername: projectDetails.projectName.toLowerCase(),
                                        location: projectDetails.projectLocation,
                                        // client: projectDetails.client,
                                        consultant: projectDetails.consultant,
                                        type: projectDetails.projectType,
                                        status: projectDetails.projectStatus,
                                        remarks: projectDetails.remarks,
                                        imageUrl: url,
                                        news: projectDetails.news,
                                        topproject: projectDetails.topproject
                                    }).then(() => {
                                        alert('Project updated successfully');
                                        setOpen(false)
                                    }).catch(error => {
                                        console.error('Error updating project:', error);
                                        setOpen(false)
                                    });
                                });
                            });
                        }).catch(error => {
                            console.error('Error deleting old image:', error);
                            console.error('Error deleting old image:', error);
                            const fileRef = storage.ref(`images/${generateRandomString(10)}${uploadImg.name}`);
                            fileRef.put(uploadImg).then(() => {
                                fileRef.getDownloadURL().then((url) => {
                                    database.ref(`projects/${projectKey}`).set({
                                        code: projectDetails.projectCode,
                                        codelower: projectDetails.projectCodeLower,
                                        name: projectDetails.projectName,
                                        lowername: projectDetails.projectLowerName,
                                        location: projectDetails.projectLocation,
                                        // client: projectDetails.client,
                                        consultant: projectDetails.consultant,
                                        type: projectDetails.projectType,
                                        status: projectDetails.projectStatus,
                                        remarks: projectDetails.remarks,
                                        imageUrl: url,
                                        news: projectDetails.news,
                                        topproject: projectDetails.topproject
                                    }).then(() => {
                                        alert('Project updated successfully');
                                        setOpen(false)
                                    }).catch(error => {
                                        console.error('Error updating project:', error);
                                        setOpen(false)
                                    });
                                });
                            })
                        });
                    } else if (uploadImg) {
                        const fileRef = storage.ref(`images/${generateRandomString(10)}${uploadImg.name}`);
                        fileRef.put(uploadImg).then(() => {
                            fileRef.getDownloadURL().then((url) => {
                                database.ref(`projects/${projectKey}`).set({
                                    code: projectDetails.projectCode,
                                    codelower: projectDetails.projectCode.toLowerCase(),
                                    name: projectDetails.projectName,
                                    lowername: projectDetails.projectName.toLowerCase(),
                                    location: projectDetails.projectLocation,
                                    // client: projectDetails.client,
                                    consultant: projectDetails.consultant,
                                    type: projectDetails.projectType,
                                    status: projectDetails.projectStatus,
                                    remarks: projectDetails.remarks,
                                    imageUrl: url,
                                    news: projectDetails.news,
                                    topproject: projectDetails.topproject
                                }).then(() => {
                                    alert('Project updated successfully');
                                    setOpen(false)
                                }).catch(error => {
                                    console.error('Error updating project:', error);
                                    setOpen(false)
                                });
                            });
                        })
                    } else {
                        database.ref(`projects/${projectKey}`).set({
                            code: projectDetails.projectCode,
                            codelower: projectDetails.projectCode.toLowerCase(),
                            name: projectDetails.projectName,
                            lowername: projectDetails.projectName.toLowerCase(),
                            location: projectDetails.projectLocation,
                            // client: projectDetails.client,
                            consultant: projectDetails.consultant,
                            type: projectDetails.projectType,
                            status: projectDetails.projectStatus,
                            remarks: projectDetails.remarks,
                            imageUrl: imgSrc,
                            news: projectDetails.news,
                            topproject: projectDetails.topproject
                        }).then(() => {
                            alert('Project updated successfully');
                            setOpen(false)
                        }).catch(error => {
                            console.error('Error updating project:', error);
                            setOpen(false)
                        });
                    }
                } else {
                    alert(`You can't add more than 6 projects as a top projects`)
                    setOpen(false)
                }
            } else {
                if (uploadImg && projectImgUrl !== '') {
                    const oldImageRef = storage.refFromURL(projectImgUrl);
                    oldImageRef.delete().then(() => {
                        const fileRef = storage.ref(`images/${generateRandomString(10)}${uploadImg.name}`);
                        fileRef.put(uploadImg).then(() => {
                            fileRef.getDownloadURL().then((url) => {
                                database.ref(`projects/${projectKey}`).set({
                                    code: projectDetails.projectCode,
                                    codelower: projectDetails.projectCode.toLowerCase(),
                                    name: projectDetails.projectName,
                                    lowername: projectDetails.projectName.toLowerCase(),
                                    location: projectDetails.projectLocation,
                                    // client: projectDetails.client,
                                    consultant: projectDetails.consultant,
                                    type: projectDetails.projectType,
                                    status: projectDetails.projectStatus,
                                    remarks: projectDetails.remarks,
                                    imageUrl: url,
                                    news: projectDetails.news,
                                    topproject: projectDetails.topproject
                                }).then(() => {
                                    alert('Project updated successfully');
                                    setOpen(false)
                                }).catch(error => {
                                    console.error('Error updating project:', error);
                                    setOpen(false)
                                });
                            });
                        });
                    }).catch(error => {
                        console.error('Error deleting old image:', error);
                        console.error('Error deleting old image:', error);
                        const fileRef = storage.ref(`images/${generateRandomString(10)}${uploadImg.name}`);
                        fileRef.put(uploadImg).then(() => {
                            fileRef.getDownloadURL().then((url) => {
                                database.ref(`projects/${projectKey}`).set({
                                    code: projectDetails.projectCode,
                                    codelower: projectDetails.projectCodeLower,
                                    name: projectDetails.projectName,
                                    lowername: projectDetails.projectLowerName,
                                    location: projectDetails.projectLocation,
                                    // client: projectDetails.client,
                                    consultant: projectDetails.consultant,
                                    type: projectDetails.projectType,
                                    status: projectDetails.projectStatus,
                                    remarks: projectDetails.remarks,
                                    imageUrl: url,
                                    news: projectDetails.news,
                                    topproject: projectDetails.topproject
                                }).then(() => {
                                    alert('Project updated successfully');
                                    setOpen(false)
                                }).catch(error => {
                                    console.error('Error updating project:', error);
                                    setOpen(false)
                                });
                            });
                        })
                    });
                } else if (uploadImg) {
                    const fileRef = storage.ref(`images/${generateRandomString(10)}${uploadImg.name}`);
                    fileRef.put(uploadImg).then(() => {
                        fileRef.getDownloadURL().then((url) => {
                            database.ref(`projects/${projectKey}`).set({
                                code: projectDetails.projectCode,
                                codelower: projectDetails.projectCode.toLowerCase(),
                                name: projectDetails.projectName,
                                lowername: projectDetails.projectName.toLowerCase(),
                                location: projectDetails.projectLocation,
                                // client: projectDetails.client,
                                consultant: projectDetails.consultant,
                                type: projectDetails.projectType,
                                status: projectDetails.projectStatus,
                                remarks: projectDetails.remarks,
                                imageUrl: url,
                                news: projectDetails.news,
                                topproject: projectDetails.topproject
                            }).then(() => {
                                alert('Project updated successfully');
                                setOpen(false)
                            }).catch(error => {
                                console.error('Error updating project:', error);
                                setOpen(false)
                            });
                        });
                    })
                } else {
                    database.ref(`projects/${projectKey}`).set({
                        code: projectDetails.projectCode,
                        codelower: projectDetails.projectCode.toLowerCase(),
                        name: projectDetails.projectName,
                        lowername: projectDetails.projectName.toLowerCase(),
                        location: projectDetails.projectLocation,
                        // client: projectDetails.client,
                        consultant: projectDetails.consultant,
                        type: projectDetails.projectType,
                        status: projectDetails.projectStatus,
                        remarks: projectDetails.remarks,
                        imageUrl: imgSrc,
                        news: projectDetails.news,
                        topproject: projectDetails.topproject
                    }).then(() => {
                        alert('Project updated successfully');
                        setOpen(false)
                    }).catch(error => {
                        console.error('Error updating project:', error);
                        setOpen(false)
                    });
                }
            }
        }).catch((err) => {
            alert(`Error: ${err}`)
            setOpen(false)
        })

    }

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setImgSrc(imageUrl);
            setUploadImg(file)
        }

    };

    const handleInputChange = (e) => {
        const { name, code, codelower, lowername, imageUrl, value } = e.target;
        setProjectDetails(prevDetails => ({ ...prevDetails, [name]: value, [code]: value, [codelower]: value, [lowername]: value.toLowerCase(), [imageUrl]: value }));
    };

    return (
        <div className="edit-project" onClick={() => { setData(null) }}>
            {data ?
                <Backdrop sx={{ zIndex: 100 }} open={selectOpenBD}>
                    <div className="select-box" style={{
                        backgroundColor: '#FFFFFF',
                        maxWidth: '700px',
                        minWidth: '500px',
                        maxHeight: '400px',
                        minHeight: '200px',
                        position: 'fixed',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        boxShadow: '0px 0px 20px -10px black',
                        borderRadius: '10px',
                        padding: '5px 20px',
                        overflow: 'auto',
                    }}>
                        {data.map((d) => {
                            return (
                                <div className="select-div" style={{
                                    fontSize: '15pt',
                                    padding: '10px 20px',
                                    margin: '10px 0px 10px 10px',
                                    cursor: 'pointer',
                                    userSelect: 'none',
                                    background: 'rgba(0,0,0,.1)',
                                    borderRadius: '5px',
                                    transition: '.2s'
                                }} key={d} onClick={() => {
                                    handleSelect(d)
                                    setData(null)
                                    setDataInfo(null)
                                    setSelectOpenBD(false)
                                }}>{`${dataInfo[d].code} || ${dataInfo[d].name}`}</div>
                            )
                        })}
                    </div>
                </Backdrop>
                : null
            }
            <form onSubmit={handleSearch}>
                <input type="text" ref={searchRef} placeholder="Search Project by Name" />
                <input type="submit" value='Find' onClick={handleSearch} />
            </form>
            {isResult ? (
                <div className="add-project">
                    <img src={imgSrc || '/placeholder.png'} alt="Project" />
                    <form onSubmit={handleEdit}>
                        <input type="file" onChange={handleFileChange} accept="image/*" />
                        <input type="text" name="projectCode" value={projectDetails.projectCode} onChange={handleInputChange} placeholder="Project Code" />
                        <input type="text" name="projectName" value={projectDetails.projectName} onChange={handleInputChange} placeholder="Project Name" />
                        <input type="text" name="projectLocation" value={projectDetails.projectLocation} onChange={handleInputChange} placeholder="Project Location" required />
                        {/* <input type="text" name="client" value={projectDetails.client} onChange={handleInputChange} placeholder="Client" required /> */}
                        <input type="text" name="consultant" value={projectDetails.consultant} onChange={handleInputChange} placeholder="Consultant" />
                        <select name="projectType" value={projectDetails.projectType} onChange={handleInputChange} required>
                            <option value="">Select Project Type</option>
                            <option value="building">Building</option>
                            <option value="villa">Villa</option>
                            {/* <option value="mosque">Mosque</option> */}
                        </select>
                        <select name="projectStatus" value={projectDetails.projectStatus} onChange={handleInputChange} required>
                            <option value="">Select Project Status</option>
                            <option value="completed">Completed</option>
                            <option value="ongoing">Ongoing</option>
                            <option value="upcoming">Upcoming</option>
                        </select>
                        <div style={{
                            margin: '5px 10px 10px 5px',
                            fontSize: '16pt',
                            display: 'flex',
                            gap: '30px'
                        }}>
                            Include in news:
                            <label style={{ cursor: 'pointer' }}><input type="radio" name="news" value="Yes" checked={projectDetails.news === 'Yes'} onChange={e => setProjectDetails({ ...projectDetails, news: e.target.value })} style={{ cursor: 'pointer' }} /> Yes</label>
                            <label style={{ cursor: 'pointer' }}><input type="radio" name="news" value="No" checked={projectDetails.news === 'No'} onChange={e => setProjectDetails({ ...projectDetails, news: e.target.value })} style={{ cursor: 'pointer' }} /> No</label>
                        </div>
                        <div style={{
                            margin: '5px 10px 10px 5px',
                            fontSize: '16pt',
                            display: 'flex',
                            gap: '30px'
                        }}>
                            Include in to projects:
                            <label style={{ cursor: 'pointer' }}><input type="radio" name="topproject" value="Yes" checked={projectDetails.topproject === 'Yes'} onChange={e => setProjectDetails({ ...projectDetails, topproject: e.target.value })} style={{ cursor: 'pointer' }} /> Yes</label>
                            <label style={{ cursor: 'pointer' }}><input type="radio" name="topproject" value="No" checked={projectDetails.topproject === 'No'} onChange={e => setProjectDetails({ ...projectDetails, topproject: e.target.value })} style={{ cursor: 'pointer' }} /> No</label>
                        </div>
                        <textarea name="remarks" value={projectDetails.remarks} onChange={handleInputChange} placeholder="Remarks"></textarea>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            justifyContent: 'space-between',
                            width: '100% !important'
                        }}>
                            <input className="submit-edit" type="submit" value='Submit Changes' onClick={handleEdit} />
                            <input className="delete-project" type="submit" value='Delete Project' onClick={handleDelete} />
                        </div>
                    </form>
                </div>
            ) : null}
        </div>
    );
}
