import React, { useState, useEffect } from "react";
import './callrequest.css';
import { database } from "../firebase/firebase";
import { IconButton } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';

export default function CallRequests({ setOpen }) {
    const dbRef = database.ref('generals/contactmessages');
    const [data, setData] = useState([]);

    useEffect(() => {
        // Request notification permission when component mounts
        const requestNotificationPermission = () => {
            if (Notification.permission !== 'granted') {
                Notification.requestPermission().then(permission => {
                    if (permission === 'granted') {
                        console.log('Notification permission granted.');
                    } else {
                        console.log('Notification permission denied.');
                    }
                });
            }
        };

        requestNotificationPermission();

        // Handle new data and send notifications for new entries
        const handleData = snapshot => {
            if (snapshot.val() !== null) {
                const newData = Object.keys(snapshot.val()).map(key => ({
                    key: key,
                    name: snapshot.val()[key].personName,
                    email: snapshot.val()[key].personEmail,
                    phone: snapshot.val()[key].personPhone,
                    message: snapshot.val()[key].personMessage,
                }));

                // Check if the incoming data is new and send notification
                if (data.length === 0 || (newData.length > data.length)) {
                    newData.forEach((item, index) => {
                        if (!data.some(d => d.key === item.key)) {
                            sendNotification(item);
                        }
                    });
                }

                setData(newData);
            } else {
                setData([]); // Clear data if the snapshot is null
            }
        };

        dbRef.on('value', handleData);

        // Clean up the listener when the component unmounts
        return () => dbRef.off('value', handleData);
    }, [dbRef, data]);

    // Function to send notifications
    const sendNotification = (message) => {
        if (Notification.permission === 'granted') {
            new Notification('New Message Received', {
                body: `From ${message.name}: ${message.message}`,
                icon: '/path/to/icon.png'  // Optional: add an icon
            });
        }
    };

    const handleDelete = key => {
        const conf = window.confirm('Are you sure you want to delete the message?')
        if(conf){
            database.ref(`generals/contactmessages/${key}`).remove();
        }
    };

    const emptyMessages = ()=>{
        const conf = window.confirm('Are you sure you want to delete all messages?')
        if(conf){
            database.ref(`generals/contactmessages`).remove();
        }
    }

    return (
        <>
            <IconButton onClick={emptyMessages} sx={{
                borderRadius: 10,
                fontSize: '15pt',
                m: '10px auto 20px auto',
                display: 'block',
                p: '5px 20px',
                border: '1px solid #4035A9'
            }} disabled={data.length > 0 ? false : true}>Empty Messages</IconButton>
            <div className="call-requests">
            {!data ? null : data.reverse().map((d) => (
                <div className="call-request" key={d.key}>
                    <div>
                        <p className="per-name">{d.name}</p>
                        <p className="per-email">{d.email}</p>
                        <p className="per-phone">{d.phone}</p>
                        <p className="per-message">{d.message}</p>
                    </div>
                    <IconButton onClick={() => handleDelete(d.key)} sx={{ '&:hover': { backgroundImage: 'none' }, width: '50px', height: '50px', position: 'absolute', top: '10px', right: '5px' }} className="call-request-delete">
                        <DeleteIcon sx={{ color: 'red' }} />
                    </IconButton>
                </div>
            ))}
        </div>
        </>
    );
}
