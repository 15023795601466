import React, { useEffect, useState } from "react";
import { Container } from "@mui/material";
import mc from '../../../images/bids1.png';
import mep from '../../../images/design1.png';
import mai from '../../../images/maintenance1.png';
import com from '../../../images/comm1.png';
import res from '../../../images/indus1.png';
import fasttrack from '../../../images/fasttrack.svg';
import mosque from '../../../images/mosque.svg';
import university from '../../../images/university.svg';
import { database } from "../../../firebase/firebase";

export default function OurServices() {
    const [txt, setTxt] = useState(null)
    const [item1text, setItem1Text] = useState(null)
    const [item1img, setItem1Img] = useState(null)
    const [item2text, setItem2Text] = useState(null)
    const [item2img, setItem2Img] = useState(null)
    const [item3text, setItem3Text] = useState(null)
    const [item3img, setItem3Img] = useState(null)
    const [item4text, setItem4Text] = useState(null)
    const [item4img, setItem4Img] = useState(null)
    const [item5text, setItem5Text] = useState(null)
    const [item5img, setItem5Img] = useState(null)
    const [item6text, setItem6Text] = useState(null)
    const [item6img, setItem6Img] = useState(null)
    const [item7text, setItem7Text] = useState(null)
    const [item7img, setItem7Img] = useState(null)
    const [item8text, setItem8Text] = useState(null)
    const [item8img, setItem8Img] = useState(null)
    useEffect(() => {
        database.ref('generals/servicestext').once('value', snapshot => {
            if (snapshot.val()) {
                setTxt(Object.values(snapshot.val())[0].headertxt)
            }
        })
    }, [])
    useEffect(() => {
        database.ref('generals/servicesItems').once('value').then((snapshot) => {
            if (snapshot.val()) {
                if (Object.keys(snapshot.val())[0]) {
                    const gotKey1 = Object.keys(snapshot.val())[0];
                    setItem1Img(snapshot.val()[gotKey1].itemimg)
                    setItem1Text(snapshot.val()[gotKey1].itemtext)
                }
                if (Object.keys(snapshot.val())[1]) {
                    const gotKey2 = Object.keys(snapshot.val())[1];
                    setItem2Img(snapshot.val()[gotKey2].itemimg)
                    setItem2Text(snapshot.val()[gotKey2].itemtext)
                }
                if (Object.keys(snapshot.val())[2]) {
                    const gotKey3 = Object.keys(snapshot.val())[2];
                    setItem3Img(snapshot.val()[gotKey3].itemimg)
                    setItem3Text(snapshot.val()[gotKey3].itemtext)
                }
                if (Object.keys(snapshot.val())[3]) {
                    const gotKey4 = Object.keys(snapshot.val())[3];
                    setItem4Img(snapshot.val()[gotKey4].itemimg)
                    setItem4Text(snapshot.val()[gotKey4].itemtext)
                }
                if (Object.keys(snapshot.val())[4]) {
                    const gotKey5 = Object.keys(snapshot.val())[4];
                    setItem5Img(snapshot.val()[gotKey5].itemimg)
                    setItem5Text(snapshot.val()[gotKey5].itemtext)
                }
                if (Object.keys(snapshot.val())[5]) {
                    const gotKey6 = Object.keys(snapshot.val())[5];
                    setItem6Img(snapshot.val()[gotKey6].itemimg)
                    setItem6Text(snapshot.val()[gotKey6].itemtext)
                }
                if (Object.keys(snapshot.val())[6]) {
                    const gotKey7 = Object.keys(snapshot.val())[6];
                    setItem7Img(snapshot.val()[gotKey7].itemimg)
                    setItem7Text(snapshot.val()[gotKey7].itemtext)
                }
                if (Object.keys(snapshot.val())[7]) {
                    const gotKey8 = Object.keys(snapshot.val())[7];
                    setItem8Img(snapshot.val()[gotKey8].itemimg)
                    setItem8Text(snapshot.val()[gotKey8].itemtext)
                }
            }
        }).catch((err) => {
            alert(`Error: ${err}`)
        })
    }, [])
    return (
        <Container className="services-container">
            <div className="services-section">
                <h2>Our Services</h2>
                <p>{txt ? txt : 'No matter what your requirements are and what your desired approach is, we have the talent, expertise, and resources to make sure your project is a success. Get in touch with us to learn more about how we can help you with the following:'}</p>
                <div className="services-grid">
                    <div className="div2">
                        <div className="service-item">
                            <div className="si-1">
                                <span></span>
                                <div className="si-2">
                                    <div className="si-3">
                                        <img src={item1img ? item1img : mc} alt="" />
                                        <p>{item1text ? item1text : 'MAIN CONTRACTOR'}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="service-item">
                            <div className="si-1">
                                <span></span>
                                <div className="si-2">
                                    <div className="si-3">
                                        <img src={item2img ? item2img : mep} alt="" />
                                        <p>{item2text ? item2text : 'MEP'}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="div1">
                    <div className="service-item">
                            <div className="si-1">
                                <span></span>
                                <div className="si-2">
                                    <div className="si-3">
                                        <img src={item3img ? item3img : university} alt="" />
                                        <p>{item3text ? item3text : 'Education Institutions'}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="service-item">
                            <div className="si-1">
                                <span></span>
                                <div className="si-2">
                                    <div className="si-3">
                                        <img src={item4img ? item4img : com} alt="" />
                                        <p>{item4text ? item4text : 'COMMERCIAL'}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="service-item">
                            <div className="si-1">
                                <span></span>
                                <div className="si-2">
                                    <div className="si-3">
                                        <img src={item5img ? item5img : res} alt="" />
                                        <p>{item5text ? item5text : 'RESIDENTIAL'}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div className="div2">
                        <div className="service-item">
                            <div className="si-1">
                                <span></span>
                                <div className="si-2">
                                    <div className="si-3">
                                        <img src={item6img ? item6img : fasttrack} alt="" />
                                        <p>{item6text ? item6text : 'Fast Track Projects'}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="service-item">
                            <div className="si-1">
                                <span></span>
                                <div className="si-2">
                                    <div className="si-3">
                                        <img src={item7img ? item7img : mosque} alt="" />
                                        <p>{item7text ? item7text : 'Religious Institutions'}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </Container>
    );
}