import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LandingPage from './landing-page/LandingPage';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Admin from './Admin';
import { database } from './firebase/firebase';
import { CircularProgress } from '@mui/material';

export default function App() {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    database.ref('generals/visitorscount').once('value').then((snapshot) => {
      if (snapshot.val()) {
        database.ref('generals/visitorscount/count').update({
          number: parseInt(snapshot.val()['count'].number) + 1
        })
      }
    }).catch((err) => {
      alert(`Error: ${err}`)
    })
  }, [])


  setTimeout(() => {
    setLoaded(true)
  }, 2000)


  return (
    <>
      <div style={{
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        display: loaded ? 'none' : 'block',
        backgroundColor: 'rgba(0, 0, 0, .5)'
      }}>
        <CircularProgress style={{ color: 'white', width: '100px', height: '100px', top: '45%', left: '45%', position: 'absolute', transform: 'translate(-50%, -50%)' }} />
      </div>
      <BrowserRouter>
        <div className="App" style={{
          visibility: loaded ? 'visible' : 'hidden'
        }}>
          <Routes>
            <Route path='/*' element={<LandingPage />} />
            <Route path='/admin/*' element={<Admin />} />
          </Routes>
        </div>
      </BrowserRouter>
    </>
  );
}