import React, { useState, useEffect, useRef } from "react";
import { auth } from "../firebase/firebase";

export default function Login({setOpen, setIsLogged}) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [isEnabled, setIsEnabled] = useState(true);

    const focusRef = useRef()

    useEffect(()=>{focusRef.current.focus();},[])

    useEffect(() => {
        if (email !== '' && password !== '') {
            setIsEnabled(false)
        } else {
            setIsEnabled(true)
        }
    }, [email, password])

    const handleLogin = async (e) => {
        e.preventDefault();
        setOpen(true)
        try {
            await auth.signInWithEmailAndPassword(`${email}@devozilla.com`, password);
            setOpen(false)
            setIsLogged(true)
            localStorage.isLogged = true
        } catch (error) {
            setMessage('Username or Password is not valid!')
            setOpen(false)
            setIsLogged(false)
            localStorage.isLogged = false
        }
    };
    return (
        <>
            {message ? <h3 className="login-alert">{message.toString()}</h3> : null}
            <div className="admin-login">
                <div className="login-logo"></div>
                <form>
                    <input ref={focusRef} value={email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder="Username" className="login-email" required />
                    <input value={password} onChange={(e) => setPassword(e.target.value)} type="password" placeholder="Password" className="login-password" required />
                    <input type="submit" disabled={isEnabled} onClick={handleLogin} className="login-submit" value='Login' />
                </form>
            </div>
        </>
    )
}