import * as React from 'react';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, NavLink } from 'react-router-dom';
import bg from '../../../logo.png'
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import CollectionsIcon from '@mui/icons-material/Collections';
import profilePDF from '../../../Brochure - EGY - V11.pdf'
import ArticleIcon from '@mui/icons-material/Article';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { database } from '../../../firebase/firebase';

const logoStyle = {
  width: '160px',
  height: 'auto',
  marginBottom: '8px',
  padding: '10px',
  cursor: 'pointer',
  userSelect: 'none',
  WebkitUserSelect: 'none',
  WebkitTapHighlightColor: 'transparent',
  outline: 'none'
};

export default function AppAppBar() {
  const [open, setOpen] = React.useState(false);
  const [homeGIF, setHomeGIF] = React.useState(null);
  const [profilePDFUploaded, setProfilePDFUploaded] = React.useState(null);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  React.useEffect(() => {
    database.ref('generals/homelogo').once('value', snapshot => {
      if (snapshot.val()) {
        setHomeGIF(Object.values(snapshot.val())[0].imageUrl)
      }
    })
  }, [])

  React.useEffect(() => {
    database.ref('generals/prequalification').once('value', snapshot => {
      if (snapshot.val()) {
        setProfilePDFUploaded(Object.values(snapshot.val())[0].imageUrl)
      }
    })
  }, [])

  return (
    <div>
      <AppBar
        className='header-main-app'
        position="fixed"
        sx={{
          boxShadow: 0,
          bgcolor: 'transparent',
          backgroundImage: 'none',
          mt: 0,
        }}
      >
        <Container sx={{ minWidth: '100%', maxWidth: '100%' }}>
          <Toolbar
            variant="regular"
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexShrink: 0,
              borderRadius: '0px',
              bgcolor:
                theme.palette.mode === 'light'
                  ? 'rgba(255, 255, 255, 0.4)'
                  : 'rgba(0, 0, 0, 0.4)',
              backdropFilter: 'blur(24px)',
              maxHeight: 70,
              border: '1px solid',
              borderColor: 'divider',
              boxShadow:
                theme.palette.mode === 'light'
                  ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                  : '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)',
            })}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                alignItems: 'center',
                ml: '-18px',
                px: 0,
              }}
            >
              <NavLink to='/' style={{ paddingTop: '5px' }}>
                <img
                  src={homeGIF}
                  style={logoStyle}
                  alt="Egybuild"
                />
              </NavLink>
              <Box sx={{ display: { xs: 'none', md: 'flex' }, ml: 2 }} >
                <MenuItem className='header-nav-p-parent'
                  sx={{ py: '6px', px: '12px', ml: 1 }}
                  component={NavLink} to='/'
                >
                  <Typography variant="body2" className="header-nav-p" sx={{ fontSize: '12pt', color: '#0054A6', ml: 1, mr: 1 }}>
                    Home
                  </Typography>
                </MenuItem>
                <MenuItem className='header-nav-p-parent'
                  sx={{ py: '6px', px: '12px', ml: 1 }}
                  component={NavLink} to='about'
                >
                  <Typography variant="body2" className="header-nav-p" sx={{ fontSize: '12pt', color: '#0054A6', ml: 1, mr: 1 }}>
                    About
                  </Typography>
                </MenuItem>
                <MenuItem className='header-nav-p-parent'
                  sx={{ py: '6px', px: '12px', ml: 1 }}
                  component={NavLink} to='projects'
                >
                  <Typography variant="body2" className="header-nav-p" sx={{ fontSize: '12pt', color: '#0054A6', ml: 1, mr: 1 }}>
                    Projects
                  </Typography>
                </MenuItem>
                {/* <MenuItem className='header-nav-p-parent'
                  sx={{ py: '6px', px: '12px', ml:1 }}
                  component={NavLink} to='gallery'
                >
                  <Typography variant="body2" className="header-nav-p" sx={{fontSize:'12pt', color:'#0054A6', ml:1, mr:1}}>
                    Gallery
                  </Typography>
                </MenuItem> */}
                <MenuItem className='header-nav-p-parent'
                  sx={{ py: '6px', px: '12px', ml: 1 }}
                  component={NavLink} to='news'
                >
                  <Typography variant="body2" className="header-nav-p" sx={{ fontSize: '12pt', color: '#0054A6', ml: 1, mr: 1 }}>
                    News
                  </Typography>
                </MenuItem>
                <MenuItem className='header-nav-p-parent'
                  sx={{ py: '6px', px: '12px', ml: 1 }}
                  component={NavLink} to='clients'
                >
                  <Typography variant="body2" className="header-nav-p" sx={{ fontSize: '12pt', color: '#0054A6', ml: 1, mr: 1 }}>
                    Clients
                  </Typography>
                </MenuItem>
                <MenuItem className='header-nav-p-parent'
                  sx={{ py: '6px', px: '12px', ml: 1 }}
                  component={NavLink} to='contact'
                >
                  <Typography variant="body2" className="header-nav-p" sx={{ fontSize: '12pt', color: '#0054A6', ml: 1, mr: 1 }}>
                    Contact
                  </Typography>
                </MenuItem>
              </Box>
            </Box>
            <Button onClick={() => { window.open(profilePDF, '_blank'); }} sx={{ color: '#0054A6', '&>svg': { mr: 1 }, display: window.innerWidth < 768 ? 'none' : 'flex', transition: '0s', '&:hover': { color: '#F26522 !important', background: 'none' }, '&:hover>svg>.svg-path': { fill: '#F26522' } }}>
              <svg width="30px" height="30px" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path className='svg-path' d="M2 12.1333C2 8.58633 2 6.81283 2.69029 5.45806C3.29749 4.26637 4.26637 3.29749 5.45806 2.69029C6.81283 2 8.58633 2 12.1333 2H19.8667C23.4137 2 25.1872 2 26.5419 2.69029C27.7336 3.29749 28.7025 4.26637 29.3097 5.45806C30 6.81283 30 8.58633 30 12.1333V19.8667C30 23.4137 30 25.1872 29.3097 26.5419C28.7025 27.7336 27.7336 28.7025 26.5419 29.3097C25.1872 30 23.4137 30 19.8667 30H12.1333C8.58633 30 6.81283 30 5.45806 29.3097C4.26637 28.7025 3.29749 27.7336 2.69029 26.5419C2 25.1872 2 23.4137 2 19.8667V12.1333Z" fill="#0054A6" />
                <path d="M24.0401 17.8976C22.7327 16.464 19.1701 17.0912 18.3094 17.1808C17.0891 15.9264 16.2284 14.504 15.8798 13.9664C16.3156 12.6224 16.6642 11.1104 16.6642 9.6768C16.6642 8.3328 16.1413 7 14.7576 7C14.2347 7 13.7989 7.2688 13.5374 7.7168C12.9273 8.792 13.1887 10.9312 14.1475 13.16C13.6245 14.7728 12.753 17.1808 11.7179 19.0512C10.3234 19.5888 7.28369 21.0112 7.02221 22.624C6.93505 23.072 7.10937 23.6096 7.45801 23.8784C7.80665 24.2368 8.24244 24.3264 8.67824 24.3264C10.4977 24.3264 12.328 21.7392 13.6354 19.4096C14.6814 19.0512 16.3265 18.5136 17.9825 18.2448C19.8891 20.0368 21.6323 20.2944 22.5039 20.2944C23.7242 20.2944 24.16 19.7568 24.3234 19.3088C24.5522 18.8832 24.3887 18.256 24.0401 17.8976ZM22.8199 18.7936C22.7327 19.152 22.2969 19.5104 21.5125 19.3312C20.5537 19.0624 19.693 18.6144 18.9958 17.9872C19.6059 17.8976 21.0767 17.7184 22.1226 17.8976C22.4712 17.9872 22.907 18.256 22.8199 18.7936ZM14.3872 8.0752C14.4744 7.896 14.6487 7.8064 14.823 7.8064C15.2588 7.8064 15.3459 8.344 15.3459 8.792C15.2588 9.8672 15.0845 11.0208 14.7358 12.0064C14.0386 10.0464 14.1257 8.6128 14.3872 8.0752ZM14.3 18.1664C14.7358 17.36 15.2588 15.848 15.4331 15.3104C15.8689 16.1168 16.6533 17.0128 17.002 17.4496C17.0891 17.3712 15.5203 17.7184 14.3 18.1664ZM11.3475 20.2272C10.1382 22.1872 9.00509 23.4416 8.30781 23.4416C8.22065 23.4416 8.04634 23.4416 7.95918 23.352C7.87202 23.1728 7.78486 22.9936 7.87202 22.8144C7.95918 22.0976 9.35373 21.112 11.3475 20.2272Z" fill="white" />
              </svg>
              Prequalification
            </Button>
            <Button component={Link} to='https://www.youtube.com/@egybuildconstruction6082/videos' target='_blank' sx={{ color: '#0054A6', '&>svg': { mr: 1 }, display: window.innerWidth < 768 ? 'none' : 'flex', transition: '0s', '&:hover': { color: '#F26522 !important', background: 'none' }, '&:hover>svg .svg-path': { fill: '#F26522 !important' } }}>
              <svg width="30px" height="30px" viewBox="0 0 20.00 14.00" version="1.1" xmlns="http://www.w3.org/2000/svg" stroke="#0054A6"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="0.8400000000000001"></g><g id="SVGRepo_iconCarrier"> <g id="Page-1" stroke-width="0.0002" fill-rule="evenodd"> <g id="Dribbble-Light-Preview" transform="translate(-300.000000, -7442.000000)" > <g id="icons" transform="translate(56.000000, 160.000000)"> <path className='svg-path' fill="#0054A6" d="M251.988432,7291.58588 L251.988432,7285.97425 C253.980638,7286.91168 255.523602,7287.8172 257.348463,7288.79353 C255.843351,7289.62824 253.980638,7290.56468 251.988432,7291.58588 M263.090998,7283.18289 C262.747343,7282.73013 262.161634,7282.37809 261.538073,7282.26141 C259.705243,7281.91336 248.270974,7281.91237 246.439141,7282.26141 C245.939097,7282.35515 245.493839,7282.58153 245.111335,7282.93357 C243.49964,7284.42947 244.004664,7292.45151 244.393145,7293.75096 C244.556505,7294.31342 244.767679,7294.71931 245.033639,7294.98558 C245.376298,7295.33761 245.845463,7295.57995 246.384355,7295.68865 C247.893451,7296.0008 255.668037,7296.17532 261.506198,7295.73552 C262.044094,7295.64178 262.520231,7295.39147 262.895762,7295.02447 C264.385932,7293.53455 264.28433,7285.06174 263.090998,7283.18289"> </path> </g> </g> </g> </g></svg>
              Youtube
            </Button>
            <Box
              sx={{
                display: { xs: 'none', md: 'flex' },
                gap: 0.5,
                alignItems: 'center',
              }}
            >
            </Box>
            <Box sx={{ display: { sm: '', md: 'none' } }}>
              <Button
                variant="text"
                color="primary"
                aria-label="menu"
                onClick={toggleDrawer(true)}
                sx={{ minWidth: '30px', p: '4px' }}
              >
                <MenuIcon />
              </Button>
              <Drawer anchor='left' open={open} onClose={toggleDrawer(false)}>
                <Box
                  className="drawer-items"
                  sx={{
                    minWidth: '60dvw',
                    p: 2,
                    backgroundColor: 'background.paper',
                    flexGrow: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'end',
                      flexGrow: 1,
                    }}
                  >
                    <Box sx={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-around'
                    }}>

                    </Box>
                  </Box>
                  <MenuItem component={NavLink} to='/' sx={{ color: '#0054A6' }} onClick={toggleDrawer(false)}>
                    <HomeIcon sx={{ mb: '5px', mr: 3 }} />Home
                  </MenuItem>
                  <MenuItem component={NavLink} to='about' sx={{ color: '#0054A6' }} onClick={toggleDrawer(false)}>
                    <InfoIcon sx={{ mb: '5px', mr: 3 }} />About
                  </MenuItem>
                  <MenuItem component={NavLink} to='projects' sx={{ color: '#0054A6' }} onClick={toggleDrawer(false)}>
                    <LocationCityIcon sx={{ mb: '5px', mr: 3 }} /> Projects
                  </MenuItem>
                  {/* <MenuItem component={NavLink} to='gallery' sx={{color:'#0054A6'}} onClick={toggleDrawer(false)}>
                    <CollectionsIcon sx={{mb:'5px', mr:3}} />Gallery
                  </MenuItem> */}
                  <MenuItem component={NavLink} to='news' sx={{ color: '#0054A6' }} onClick={toggleDrawer(false)}>
                    <ArticleIcon sx={{ mb: '5px', mr: 3 }} />News
                  </MenuItem>
                  <MenuItem component={NavLink} to='clients' sx={{ color: '#0054A6' }} onClick={toggleDrawer(false)}>
                    < PeopleAltIcon sx={{ mb: '5px', mr: 3 }} />Clients
                  </MenuItem>
                  <MenuItem component={NavLink} to='contact' sx={{ color: '#0054A6' }} onClick={toggleDrawer(false)}>
                    <SupportAgentIcon sx={{ mb: '5px', mr: 3 }} />Contact
                  </MenuItem>
                  <MenuItem sx={{ color: '#0054A6', transition: '0s', '&:hover': { color: '#F26522', background: 'none' }, '&:hover>svg>.svg-path': { fill: '#F26522' }, '&>svg': { mr: 3 } }} onClick={() => {
                    toggleDrawer(false);
                    window.open(profilePDFUploaded, '_blank');
                  }}>
                    <svg width="25px" height="25px" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path className='svg-path' d="M2 12.1333C2 8.58633 2 6.81283 2.69029 5.45806C3.29749 4.26637 4.26637 3.29749 5.45806 2.69029C6.81283 2 8.58633 2 12.1333 2H19.8667C23.4137 2 25.1872 2 26.5419 2.69029C27.7336 3.29749 28.7025 4.26637 29.3097 5.45806C30 6.81283 30 8.58633 30 12.1333V19.8667C30 23.4137 30 25.1872 29.3097 26.5419C28.7025 27.7336 27.7336 28.7025 26.5419 29.3097C25.1872 30 23.4137 30 19.8667 30H12.1333C8.58633 30 6.81283 30 5.45806 29.3097C4.26637 28.7025 3.29749 27.7336 2.69029 26.5419C2 25.1872 2 23.4137 2 19.8667V12.1333Z" fill="#0054A6" />
                      <path d="M24.0401 17.8976C22.7327 16.464 19.1701 17.0912 18.3094 17.1808C17.0891 15.9264 16.2284 14.504 15.8798 13.9664C16.3156 12.6224 16.6642 11.1104 16.6642 9.6768C16.6642 8.3328 16.1413 7 14.7576 7C14.2347 7 13.7989 7.2688 13.5374 7.7168C12.9273 8.792 13.1887 10.9312 14.1475 13.16C13.6245 14.7728 12.753 17.1808 11.7179 19.0512C10.3234 19.5888 7.28369 21.0112 7.02221 22.624C6.93505 23.072 7.10937 23.6096 7.45801 23.8784C7.80665 24.2368 8.24244 24.3264 8.67824 24.3264C10.4977 24.3264 12.328 21.7392 13.6354 19.4096C14.6814 19.0512 16.3265 18.5136 17.9825 18.2448C19.8891 20.0368 21.6323 20.2944 22.5039 20.2944C23.7242 20.2944 24.16 19.7568 24.3234 19.3088C24.5522 18.8832 24.3887 18.256 24.0401 17.8976ZM22.8199 18.7936C22.7327 19.152 22.2969 19.5104 21.5125 19.3312C20.5537 19.0624 19.693 18.6144 18.9958 17.9872C19.6059 17.8976 21.0767 17.7184 22.1226 17.8976C22.4712 17.9872 22.907 18.256 22.8199 18.7936ZM14.3872 8.0752C14.4744 7.896 14.6487 7.8064 14.823 7.8064C15.2588 7.8064 15.3459 8.344 15.3459 8.792C15.2588 9.8672 15.0845 11.0208 14.7358 12.0064C14.0386 10.0464 14.1257 8.6128 14.3872 8.0752ZM14.3 18.1664C14.7358 17.36 15.2588 15.848 15.4331 15.3104C15.8689 16.1168 16.6533 17.0128 17.002 17.4496C17.0891 17.3712 15.5203 17.7184 14.3 18.1664ZM11.3475 20.2272C10.1382 22.1872 9.00509 23.4416 8.30781 23.4416C8.22065 23.4416 8.04634 23.4416 7.95918 23.352C7.87202 23.1728 7.78486 22.9936 7.87202 22.8144C7.95918 22.0976 9.35373 21.112 11.3475 20.2272Z" fill="white" />
                    </svg>
                    prequalification
                  </MenuItem>
                  <MenuItem sx={{ color: '#0054A6', transition: '0s', '&:hover': { color: '#F26522', background: 'none' }, '&:hover>svg>.svg-path': { fill: '#F26522' }, '&>svg': { mr: 3 } }} onClick={() => {
                    toggleDrawer(false);
                    window.open(profilePDF, '_blank');
                  }}>
                    <svg width="30px" height="30px" viewBox="0 0 20.00 14.00" version="1.1" xmlns="http://www.w3.org/2000/svg" stroke="#0054A6"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="0.8400000000000001"></g><g id="SVGRepo_iconCarrier"> <g id="Page-1" stroke-width="0.0002" fill-rule="evenodd"> <g id="Dribbble-Light-Preview" transform="translate(-300.000000, -7442.000000)" > <g id="icons" transform="translate(56.000000, 160.000000)"> <path className='svg-path' fill="#0054A6" d="M251.988432,7291.58588 L251.988432,7285.97425 C253.980638,7286.91168 255.523602,7287.8172 257.348463,7288.79353 C255.843351,7289.62824 253.980638,7290.56468 251.988432,7291.58588 M263.090998,7283.18289 C262.747343,7282.73013 262.161634,7282.37809 261.538073,7282.26141 C259.705243,7281.91336 248.270974,7281.91237 246.439141,7282.26141 C245.939097,7282.35515 245.493839,7282.58153 245.111335,7282.93357 C243.49964,7284.42947 244.004664,7292.45151 244.393145,7293.75096 C244.556505,7294.31342 244.767679,7294.71931 245.033639,7294.98558 C245.376298,7295.33761 245.845463,7295.57995 246.384355,7295.68865 C247.893451,7296.0008 255.668037,7296.17532 261.506198,7295.73552 C262.044094,7295.64178 262.520231,7295.39147 262.895762,7295.02447 C264.385932,7293.53455 264.28433,7285.06174 263.090998,7283.18289"> </path> </g> </g> </g> </g></svg>
                    Youtube
                  </MenuItem>
                </Box>
              </Drawer>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}

