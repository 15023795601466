import React, { useState, useEffect, useRef } from 'react';
import topImage from '../../images/about-image1.webp';
import aw from '../../images/aw.png';
import './about.css';
import { database } from '../../firebase/firebase';
import Cert from './cert';

export default function About() {
  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])

  const [headertext, setHeaderText] = useState(null)
  const [head, setHead] = useState(null);
  const [firstP, setFirstP] = useState(null);
  const [secondP, setSecondP] = useState(null);
  const [whyegybuild, setWhyegybuild] = useState(null);
  const [mission, setMission] = useState(null);
  const [vision, setVision] = useState(null);
  const [awardsData, setAwardsData] = useState([])
  const [aboutImage, setAboutImage] = useState(null)

  useEffect(() => {
    database.ref('generals/aboutimage').once('value', snapshot => {
      if (snapshot.val()) {
        setAboutImage(Object.values(snapshot.val())[0].imageUrl)
      }
    })
  }, [])

  useEffect(() => {
    database.ref('certificates').once('value', snapshot => {
      if (snapshot.val()) {
        setAwardsData(Object.values(snapshot.val()))
      }
    })
  }, [])

  useEffect(() => {
    database.ref('generals/abouttext').once('value', snapshot => {
      if (snapshot.val()) {
        setHeaderText(Object.values(snapshot.val())[0].headertxt)
      }
    })
  }, [])
  useEffect(() => {
    database.ref('generals/gmmessage').once('value').then((snapshot) => {
      if (snapshot.val()) {
        const gotKey = Object.keys(snapshot.val())[0]
        setHead(snapshot.val()[gotKey].headertxt)
        setFirstP(snapshot.val()[gotKey].firstp)
        setSecondP(snapshot.val()[gotKey].secondp)
      }
    }).catch((err) => {
      alert(`Error: ${err}`)
    })
  }, [])
  useEffect(() => {
    database.ref('generals/aboutbottom').once('value').then((snapshot) => {
      if (snapshot.val()) {
        const gotKey = Object.keys(snapshot.val())[0]
        setWhyegybuild(snapshot.val()[gotKey].whyegybuild.toString().split(/\.(\s|$)/).map((part) => part.trim()).filter(part => part))
        setMission(snapshot.val()[gotKey].mission.toString().split(/\.(\s|$)/).map((part) => part.trim()).filter(part => part))
        setVision(snapshot.val()[gotKey].vision.toString().split(/\.(\s|$)/).map((part) => part.trim()).filter(part => part))
      }
    })
  }, [])
  const [blText, setBLText] = useState(null)
  const [brText, setBRText] = useState(null)
  useEffect(() => {
    database.ref('generals/hometext').once('value', (snapshot) => {
      if (snapshot.val()) {
        setBLText(Object.values(snapshot.val())[0].firstp)
        setBRText(Object.values(snapshot.val())[0].secondp)
      }
    })
  }, [])

  return (
    <div style={{
      maxWidth: '100%',
      overflow: 'hidden',
      position: 'relative',
      top: '50px',
      marginBottom: '100px'
    }}>
      <div style={{
        width: `${window.innerWidth}px`,
        backgroundImage: `url(${aboutImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center 70%',
        backgroundSize: window.innerWidth < 768 ? 'auto 100%' : '100% auto',
        height: '250px',
      }}></div>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
        width: '90%',
        margin: '20px auto',
        padding: '30px 20px',
        maxWidth: '1250px',
        boxShadow: '0px 0px 20px -5px black',
        borderTopRightRadius: '50px',
        borderBottomLeftRadius: '50px',
        borderTopLeftRadius: '10px',
        borderBottomRightRadius: '10px',
        backgroundImage: 'linear-gradient(to bottom, #021321, orangered, #1256A4)',
        backgroundClip: 'text',
        color: 'transparent',
      }}>
        <p style={{ textAlign: 'justify', fontSize: '18pt', fontWeight: 'bold', userSelect: 'none' }}>{headertext ? headertext : "Established in 2007, Egybuild construction L.L.C became an integral arm in Dubai's growth story. Egybuild devoted its expertise to over   projects worth millions of Dirhams, continuously delivering the highest level of quality surpassing all our client's expectations."}</p>
        <p style={{ textAlign: 'justify', fontSize: '16pt', userSelect: 'none' }}>{blText ? blText : 'our main office is located in Al Rigga - Dubai -UAE, which has qualified engineers and management staff, whom working hard on the most worthwhile programs and management techniques to support our well trained and well experienced workforce along with more than 160 Construction sites all around'}</p>
        <p style={{ textAlign: 'justify', fontSize: '16pt', userSelect: 'none' }}>{brText ? brText : 'Dubai, U.A.E. our completed projects and satisfied clients are a testament to the quality of our output work that we deliver on planned integrated baselines. The fact that our clients are repetitive is evidence for the trust our clients have put on us.'}</p>
      </div>
      <div style={{
        position: 'relative',
        display: 'flex',
        flexWrap: 'wrap',
        padding: '10px',
        justifyContent: 'space-evenly',
        width: '90%',
        maxWidth: '1250px',
        margin: 'auto',
        boxShadow: '0px 0px 5px 2px #F18151 inset',
        borderRadius: '10px',
        border: '1px solid #F18151',
        overflow: 'hidden',
        backgroundColor: 'white',
      }}>
        <div style={{
          width: '100%',
          maxWidth: '600px',
        }}>
          <h2 style={{
            fontSize: window.innerWidth < 768 ? '14pt' : '20pt',
            backgroundColor: '#1256A4',
            width: 'max-content',
            padding: '5px 20px',
            color: '#F18151',
            borderTopLeftRadius: '15px',
            borderBottomRightRadius: '15px',
            marginBottom: '20px',
            userSelect: 'none'
          }}>General Manager Message</h2>
          <p style={{
            padding: '5px',
            marginBottom: '10px',
            textAlign: 'justify',
            userSelect: 'none'
          }}>
            {head ? head : "At Egybuild, we believe that an ethical approach is the core value of our organization that goes right to the heart of everything we stand for. Ethical values are evident in every interaction with our employees, customers, suppliers and members of the public. our business model is to integrate our core disciplines to deliver better project solutions across the chosen market areas."}
          </p>
          <p style={{
            padding: '5px',
            marginBottom: '10px',
            textAlign: 'justify',
            userSelect: 'none'
          }}>
            {firstP ? firstP : "We champion diverse talents, skills and backgrounds at Egybuild. Working together, we make a positive difference and continue to make sure that every project is the jealousy of the Country. Whether it is the construction of a high rise building to multiple residential villas, labour camps, Egybuild uses the latest ideas, skills and equipment to deliver the best solutions possible."}
          </p>
          <p style={{
            padding: '5px',
            marginBottom: '10px',
            textAlign: 'justify',
            userSelect: 'none'
          }}>
            {secondP ? secondP : "We believe in building long lasting business relationships with our clients and at Egybuild, each and every client is important. our relationships are built on trust and integrity, providing the best services and delivering nothing but excellence. i am proud of the dedicated and ambitious team of professionals at Egybuild and would like to thank our clients for putting their trust in us and would ensure them, that we at, shall continue to outperform targets' and deliver par excellence quality and solutions."}
          </p>
          <p style={{
            paddingLeft: '5px',
            marginBottom: '5px',
            userSelect: 'none'
          }}><b>ENG. AHMED M.WAGiH</b></p>
          <p style={{
            paddingLeft: '5px',
            userSelect: 'none'
          }}><b>FOUNDER & GENERAL MANAGER.</b></p>
        </div>
        <div style={{
          width: '100%',
          maxWidth: '600px',
          backgroundImage: `url(${aw})`,
          backgroundPosition: 'left middle',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          minHeight: '350px'
        }}></div>
      </div>
      <div className='mission-parent' style={{
        boxSizing: 'border-box',
        display: window.innerWidth < 768 ? 'block' : 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        padding: window.innerWidth < 768 ? '0px' : '20px',
        overflow: 'hidden'
      }}>
        <div className='about-mission'>
          <span className='dynamic-border'></span>
          <div className='inner-mission'>
            <h3>Why Egybuild</h3>
            <p>{whyegybuild ? whyegybuild.map((part, index) => (
              <React.Fragment key={index}>
                {part}.
                <hr style={{ display: 'block', marginBottom: '15px', border: 'none' }} />
              </React.Fragment>
            )) : `We have our own fl eet of Equipment and manpower, which gives  the ability to compete very aggressively in terms of our pricing structure.`}</p>
          </div>
        </div>
        <div className='about-mission'>
          <span className='dynamic-border'></span>
          <div className='inner-mission'>
            <h3>Our Mission</h3>
            <p>{mission ? mission.map((part, index) => (
              <React.Fragment key={index}>
                {part}.
                <hr style={{ display: 'block', marginBottom: '15px', border: 'none' }} />
              </React.Fragment>
            )) : `To provide our clients with the utmost service quality, on time and budget delivery. We shall achieve this by creating a partnership between our employees, managers, and shareholders focused on executing our projects on Time, under Budget, and within the Quality requirements. We will perform this in the most efficient and productive manner such as to generate a reasonable return for our shareholders and ourselves.`}</p>
          </div>
        </div>
        <div className='about-mission'>
          <span className='dynamic-border'></span>
          <div className='inner-mission'>
            <h3>Our Vision</h3>
            <p>{vision ? vision.map((part, index) => (
              <React.Fragment key={index}>
                {part}.
                <hr style={{ display: 'block', marginBottom: '15px', border: 'none' }} />
              </React.Fragment>
            )) : "To provide specialist construction services solutions in an environment where people feel safe, secure and valued."}</p>
          </div>
        </div>
      </div>
      <div>
        <div className='awards-certificates'>
          <h1>Awards and Certifications</h1>
          {!awardsData ? null : awardsData.map((ad) => {
            return (
              <Cert title={ad.title} remarks={ad.remarks} imageUrl={ad.imageUrl} />
              // <div className="ac-item">
              //   <h1>{ad.title}</h1>
              //   <p>{ad.remarks}</p>
              //   <img src={ad.imageUrl} alt="" />
              // </div>
            )
          })}
          {/*

          {headertext ? headertext : "Established in 2007, Egybuild construction L.L.C became an integral arm in Dubai's growth story. Egybuild devoted its expertise to over   projects worth millions of Dirhams, continuously delivering the highest level of quality surpassing all our client's expectations."}

          {blText ? blText : 'our main office is located in Al Rigga - Dubai -UAE, which has qualified engineers and management staff, whom working hard on the most worthwhile programs and management techniques to support our well trained and well experienced workforce along with more than 160 Construction sites all around'}

          {brText ? brText : 'Dubai, U.A.E. our completed projects and satisfied clients are a testament to the quality of our output work that we deliver on planned integrated baselines. The fact that our clients are repetitive is evidence for the trust our clients have put on us.'}

          <div className="ac-item">
            <h1>The title</h1>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Odio possimus laborum rerum minima sapiente quos cumque quas sed soluta, neque, accusamus illum vel recusandae repellat reprehenderit velit dicta. Cupiditate, error!</p>
            <img src={aw} alt="" />
          </div>
          <div className="ac-item">
            <h1>The title</h1>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Odio possimus laborum rerum minima sapiente quos cumque quas sed soluta, neque, accusamus illum vel recusandae repellat reprehenderit velit dicta. Cupiditate, error!</p>
            <img src={aw} alt="" />
          </div>
          <div className="ac-item">
            <h1>The title</h1>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Odio possimus laborum rerum minima sapiente quos cumque quas sed soluta, neque, accusamus illum vel recusandae repellat reprehenderit velit dicta. Cupiditate, error!</p>
            <img src={aw} alt="" />
          </div> */}
        </div>
      </div>
    </div>
  );
}

