import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import ImgMediaCard from './inner-components/card';
import './projects.css';
import { database } from '../../firebase/firebase';

export default function Projects() {

  React.useEffect(()=>{
    window.scrollTo({top: 0})
  },[])

  const [viewProjects, setViewProjects] = useState({type:null, status:'ongoing'});

  const [projects, setProjects] = useState([]);

  const [projectsImage, setProjectsImage] = useState(null)

  useEffect(() => {
    database.ref('generals/projectsimage').once('value', snapshot => {
      if (snapshot.val()) {
        setProjectsImage(Object.values(snapshot.val())[0].imageUrl)
      }
    })
  }, [])

  useEffect(() => {
    const projectsRef = database.ref('projects');
    projectsRef.on('value', snapshot => {
      const projectsData = snapshot.val();
      const projectsList = projectsData ? Object.keys(projectsData).map(key => ({
        ...projectsData[key],
        id: key
      })) : [];
      const customSort = (a, b) => {
        const parseValue = (str) => {
          const match = str.match(/^(\d+)([A-Za-z]*)$/); // Match number and optional letter part
          const number = parseInt(match[1], 10); // Numeric part
          const letter = match[2] || ''; // Letter part (if any)
          return { number, letter };
        };

        const aParsed = parseValue(a.code); // Assuming `id` is the field you want to sort by
        const bParsed = parseValue(b.code);

        // First compare by number part
        if (aParsed.number !== bParsed.number) {
          return aParsed.number - bParsed.number;
        }

        // If numbers are the same, compare by letter part
        return aParsed.letter.localeCompare(bParsed.letter);
      };

      // Sort the list of projects
      const sortedProjects = projectsList.sort(customSort);

      // Set the sorted projects into state
      setProjects(sortedProjects.reverse());
    });
    return () => projectsRef.off();
  }, []);

  return (
    <Box
      className='proBox'
      sx={{
        py: { xs: 8, sm: 8 },
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        gap: '10px',
        height: 'auto'
      }}
    >
      <div className='projects-header' style={{backgroundSize: window.innerWidth < 768 ? 'auto 100%' : '100% auto', backgroundImage: `url(${projectsImage})`}}></div>

      <div className='projects-body-main'>
        <div className='projects-body-header'>
          <ul>
            <li style={!viewProjects.type ? {backgroundColor:'#F26522', color:'#1d4387'} : null} onClick={()=>setViewProjects(prevProj => ({...prevProj, type:null}))}>All</li>
            <li style={viewProjects.type === 'building' ? {backgroundColor:'#F26522', color:'#1d4387'} : null} onClick={()=>setViewProjects(prevProj => ({...prevProj, type:'building'}))}>Building</li>
            <li style={viewProjects.type === 'villa' ? {backgroundColor:'#F26522', color:'#1d4387'} : null} onClick={()=>setViewProjects(prevProj => ({...prevProj, type:'villa'}))}>Villa</li>
            {/* <li style={viewProjects.type === 'mosque' ? {backgroundColor:'#F26522', color:'#1d4387'} : null} onClick={()=>setViewProjects(prevProj => ({...prevProj, type:'mosque'}))}>Mosque</li> */}
          </ul>
        </div>
        <div className='projects-sub-body'>
          <div className='projects-sub-body-view'>
            {projects && viewProjects ?
              projects.map((pro) => {
                const data = {
                  code: pro.code,
                  name: pro.name,
                  location: pro.location,
                  client: pro.client,
                  consultant: pro.consultant,
                }
                if (pro.type.toLowerCase() === viewProjects.type && pro.status.toLowerCase() === viewProjects.status && pro.news === 'No') {
                  return (
                    <ImgMediaCard impImg={pro.imageUrl} data={data} />
                  )
                }else if (!viewProjects.type && pro.status.toLowerCase() === viewProjects.status && pro.news === 'No') {
                  return (
                    <ImgMediaCard impImg={pro.imageUrl} data={data} />
                  )
                }else if (pro.type.toLowerCase() === viewProjects.type && !viewProjects.status && pro.news=== 'No') {
                  return (
                    <ImgMediaCard impImg={pro.imageUrl} data={data} />
                  )
                }else if (!viewProjects.type && !viewProjects.status && pro.news === 'No') {
                  return (
                    <ImgMediaCard impImg={pro.imageUrl} data={data} />
                  )
                }
              }) : null
            }
          </div>
          <div className='projects-sub-body-navigation'>
            <ul>
              <li style={viewProjects.status === 'ongoing' ? {backgroundColor:'#F26522', color:'#1d4387'} : null} onClick={()=>setViewProjects(prevProj => ({...prevProj, status:'ongoing'}))}>Ongoing Projects</li>
              <li style={viewProjects.status === 'completed' ? {backgroundColor:'#F26522', color:'#1d4387'} : null} onClick={()=>setViewProjects(prevProj => ({...prevProj, status:'completed'}))}>Completed Projects</li>
              <li style={viewProjects.status === 'upcoming' ? {backgroundColor:'#F26522', color:'#1d4387'} : null} onClick={()=>setViewProjects(prevProj => ({...prevProj, status:'upcoming'}))}>Upcoming Projects</li>
              <li style={!viewProjects.status ? {backgroundColor:'#F26522', color:'#1d4387'} : null} onClick={()=>setViewProjects(prevProj => ({...prevProj, status:null}))}>All Projects</li>
            </ul>
          </div>
        </div>
      </div>

    </Box>
  );
}
