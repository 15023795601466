import * as React from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CancelIcon from '@mui/icons-material/Cancel';

export default function RecipeReviewCard({ impImg, data }) {
  const [showPopup, setShowPopup] = React.useState(false);
  const togglePopup = () => setShowPopup(!showPopup);
  return (
    <>
      <div style={{ width: '100%', height: '100vh', position: 'fixed', background: 'rgba(0,0,0,.8)', left: '0px', top: '0px', display: (showPopup ? 'block' : 'none'), zIndex: 10000 }} onClick={() => { setShowPopup(false) }}></div>
      <div className={`top-projects-popup ${showPopup ? 'active' : ''}`}>
        <CancelIcon sx={{ width: '20px', color: 'red', marginLeft: 'calc(100% - 30px)', cursor: 'pointer' }} onClick={() => { setShowPopup(false) }} />
        <div style={{ width: '100%', height: '100%' }}>
          <img src={impImg} style={{ width: '100%', height: "calc(100% - 180px)" }} alt="Card" />
          <h4 style={{ margin: '3px 10px' }}><b>Project Details</b></h4>
          <p style={{ margin: '3px 10px' }}><b>Code: </b>{data.code}</p>
          <p style={{ margin: '3px 10px' }}><b>Name: </b>{data.name}</p>
          <p style={{ margin: '3px 10px' }}><b>Location: </b>{data.location}</p>
          <p style={{ margin: '3px 10px' }}><b>Consultant: </b>{data.consultant}</p>
        </div>
      </div>
      <Card sx={{ width: 1, height:550, position:'relative' }} className="projects-card" >
        <CardMedia
          className="projects-card-media"
          sx={{objectFit: 'cover'}}
          component="img"
          alt="Alt"
          height="450"
          image={impImg}
        />
        <div style={{padding: '5px 5px 5px 20px', width: '100%', backgroundColor:'white', height:'fit-content', position:'absolute', bottom:'10px'}}>
          <p style={{fontSize:'15pt !important'}}>
            {data.remarks ? data.remarks : data.name}
          </p>
        </div>
      </Card>
    </>
  );
}
