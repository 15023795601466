import React from 'react';
import Slider from 'react-slick';

export default function ClientSlider({ clients, dbdata, len }){
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: window.innerWidth < 768 ? 2 : parseInt(len) > 5 ? 5 : parseInt(len),
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1500,
        cssEase: "linear"
    };

    return (
        <div style={{maxWidth:'1200px', margin:'100px auto'}}>
            <h2 style={{textAlign:'center', fontSize:(window.innerWidth < 768 ? '20pt' : '35pt'), marginBottom:'50px'}}>Our Clients</h2>
            <Slider {...settings}>
                {dbdata ? dbdata.map((client, index) => (
                    <div key={index}>
                        <img src={client.imageUrl} alt="" style={{ width: "95%", margin:'auto', height: "100px", border:'1px solid #DFDFDF', borderRadius:'3px' }} />
                    </div>
                )) : clients.map((client, index) => (
                    <div key={index}>
                        <img src={client.logo} alt={client.name} style={{ width: "95%", height: "100px", border:'1px solid #DFDFDF', borderRadius:'3px' }} />
                    </div>
                ))}
            </Slider>
        </div>
    );
};
