import React, { useState, useEffect } from "react";
import { database } from "../../firebase/firebase";

export default function Clients() {

    const [sections, setSections] = useState([]);
    const [clientsItems, setClientsItems] = useState([]);
    const [csl, setCsl] = useState([]);

    useEffect(() => {
        const sectionsRef = database.ref('clientssections');
        sectionsRef.on('value', snapshot => {
            const sectionsData = snapshot.val();
            const sectionsList = sectionsData ? Object.keys(sectionsData).map(key => ({
                ...sectionsData[key],
                id: key
            })) : [];
            setSections(sectionsList);
        });
        return () => sectionsRef.off();
    }, []);



    useEffect(() => {
        const clientsRef = database.ref('clientsitems');
        clientsRef.on('value', snapshot => {
            const clientsData = snapshot.val();
            const clientsList = clientsData
                ? Object.keys(clientsData).map(key => ({
                    ...clientsData[key],
                    id: key
                }))
                : [];

            // Sort clients based on the image URL filenames
            const sortedClients = clientsList.sort((a, b) => {
                const urlA = a.imageUrl;
                const urlB = b.imageUrl;

                // Extract filename from URL
                const filenameA = urlA.split('clientsimages%2F')[1].split('?')[0];
                const filenameB = urlB.split('clientsimages%2F')[1].split('?')[0];

                // Extract the number part from the filename by splitting by the dot
                const nameA = filenameA.split('.')[0];
                const nameB = filenameB.split('.')[0];

                // Try to parse the number part
                const numA = parseInt(nameA, 10);
                const numB = parseInt(nameB, 10);

                const isNumericA = !isNaN(numA);
                const isNumericB = !isNaN(numB);

                // If both are numeric, sort numerically
                if (isNumericA && isNumericB) {
                    return numA - numB;
                } else if (isNumericA) {
                    // If only `A` is numeric, give it higher priority (so it appears first)
                    return -1;
                } else if (isNumericB) {
                    // If only `B` is numeric, give it higher priority (so it appears first)
                    return 1;
                } else {
                    // If both are non-numeric, sort alphabetically
                    return nameA.localeCompare(nameB);
                }
            });
            setClientsItems(sortedClients);
            sortedClients.map((cl) => {
                setCsl(cdata => [...cdata, cl.section])
            })
        });

        return () => clientsRef.off(); // Cleanup listener on component unmount
    }, []);

    return (
        <div style={{ minHeight: 'calc(100vh - 70px) !important', paddingTop: '100px', paddingBottom: '30px' }}>
            <div style={{ width: '90%', maxWidth: '1200px', margin: 'auto' }}>
                {!sections ? null :
                    sections.map((section) => {
                        if (csl.includes(section.name)) {
                            return (<div key={section.name} style={{
                                borderRadius: '10px',
                                boxShadow: '0px 0px 5px 2px #1256A4 inset',
                                marginBottom: '20px',
                                padding: '20px'
                            }}>
                                <h1 style={{
                                    backgroundImage: 'linear-gradient(to right, darkblue, orange, #F2622E)',
                                    width: 'fit-content',
                                    color: 'transparent',
                                    backgroundClip: 'text',
                                    marginBottom: '15px'
                                }}>{section.title}</h1>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row-reverse',
                                    flexWrap: 'wrap',
                                    justifyContent: 'left',
                                    gap: '20px',
                                    paddingLeft: '30px'
                                }}>
                                    {!clientsItems ? null :
                                        clientsItems.map((ci) => {
                                            if (ci.section === section.name) {
                                                return (
                                                    <img style={{
                                                        width: '200px',
                                                        height: '120px',
                                                        borderRadius: '10px',
                                                        boxShadow: '0px 0px 15px -5px gray',
                                                        backgroundColor: 'white'
                                                    }} src={ci.imageUrl} alt="ClientImg" />
                                                )
                                            }
                                        })
                                    }
                                </div>
                            </div>)
                        }
                    })
                }
            </div>
        </div>
    )
}