import React from "react";
import { NavLink } from "react-router-dom";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { IconButton } from "@mui/material";
import { auth } from "../firebase/firebase";

export default function AdminHeader({setIsLogged}){
    return(
        <div className="admin-header-container">
            <ul>
                <li><NavLink to=''>General</NavLink></li>
                <li><NavLink to='products' end>Projects</NavLink></li>
                <li><NavLink to='certificates'>Certificates</NavLink></li>
                <li><NavLink to='clients'>Clients</NavLink></li>
                <li><NavLink to='call-requests'>Call Requests</NavLink></li>
                <li><NavLink to='job-requests'>Job Requests</NavLink></li>
                {/* <li><NavLink to='upload-projects'>Upload Projects</NavLink></li> */}
            </ul>
            <IconButton onClick={()=>{
                auth.signOut()
                setIsLogged(false)
                localStorage.isLogged = false
            }} sx={{position:'absolute', right:'10px'}}>
                <ExitToAppIcon sx={{
                    color:'white'
                    }}
                />
            </IconButton>
        </div>
    )
}